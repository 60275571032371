export const listUF = [
    {
        label: () => 'AC',
        value: 'AC'
    },
    {
        label: () => 'AL',
        value: 'AL'
    },
    {
        label: () => 'AP',
        value: 'AP'
    },
    {
        label: () => 'AM',
        value: 'AM'
    },
    {
        label: () => 'BA',
        value: 'BA'
    },
    {
        label: () => 'CE',
        value: 'CE'
    },
    {
        label: () => 'DF',
        value: 'DF'
    },
    {
        label: () => 'ES',
        value: 'ES'
    },
    {
        label: () => 'GO',
        value: 'GO'
    },
    {
        label: () => 'MA',
        value: 'MA'
    },
    {
        label: () => 'MT',
        value: 'MT'
    },
    {
        label: () => 'MS',
        value: 'MS'
    },
    {
        label: () => 'MG',
        value: 'MG'
    },
    {
        label: () => 'PA',
        value: 'PA'
    },
    {
        label: () => 'PB',
        value: 'PB'
    },
    {
        label: () => 'PR',
        value: 'PR'
    },
    {
        label: () => 'PE',
        value: 'PE'
    },
    {
        label: () => 'PI',
        value: 'PI'
    },
    {
        label: () => 'RJ',
        value: 'RJ'
    },
    {
        label: () => 'RN',
        value: 'RN'
    },
    {
        label: () => 'RS',
        value: 'RS'
    },
    {
        label: () => 'RO',
        value: 'RO'
    },
    {
        label: () => 'RR',
        value: 'RR'
    },
    {
        label: () => 'SC',
        value: 'SC'
    },
    {
        label: () => 'SP',
        value: 'SP'
    },
    {
        label: () => 'SE',
        value: 'SE'
    },
    {
        label: () => 'TO',
        value: 'TO'
    }
]

export const listDDD = [
    { label: () => '11', value: '11' },
    { label: () => '12', value: '12' },
    { label: () => '13', value: '13' },
    { label: () => '14', value: '14' },
    { label: () => '15', value: '15' },
    { label: () => '16', value: '16' },
    { label: () => '17', value: '17' },
    { label: () => '18', value: '18' },
    { label: () => '19', value: '19' },
    { label: () => '21', value: '21' },
    { label: () => '22', value: '22' },
    { label: () => '24', value: '24' },
    { label: () => '27', value: '27' },
    { label: () => '28', value: '28' },
    { label: () => '31', value: '31' },
    { label: () => '32', value: '32' },
    { label: () => '33', value: '33' },
    { label: () => '34', value: '34' },
    { label: () => '35', value: '35' },
    { label: () => '37', value: '37' },
    { label: () => '38', value: '38' },
    { label: () => '41', value: '41' },
    { label: () => '42', value: '42' },
    { label: () => '43', value: '43' },
    { label: () => '44', value: '44' },
    { label: () => '45', value: '45' },
    { label: () => '46', value: '46' },
    { label: () => '47', value: '47' },
    { label: () => '48', value: '48' },
    { label: () => '49', value: '49' },
    { label: () => '51', value: '51' },
    { label: () => '53', value: '53' },
    { label: () => '54', value: '54' },
    { label: () => '55', value: '55' },
    { label: () => '61', value: '61' },
    { label: () => '62', value: '62' },
    { label: () => '63', value: '63' },
    { label: () => '64', value: '64' },
    { label: () => '65', value: '65' },
    { label: () => '66', value: '66' },
    { label: () => '67', value: '67' },
    { label: () => '68', value: '68' },
    { label: () => '69', value: '69' },
    { label: () => '71', value: '71' },
    { label: () => '73', value: '73' },
    { label: () => '74', value: '74' },
    { label: () => '75', value: '75' },
    { label: () => '77', value: '77' },
    { label: () => '79', value: '79' },
    { label: () => '81', value: '81' },
    { label: () => '82', value: '82' },
    { label: () => '83', value: '83' },
    { label: () => '84', value: '84' },
    { label: () => '85', value: '85' },
    { label: () => '86', value: '86' },
    { label: () => '87', value: '87' },
    { label: () => '88', value: '88' },
    { label: () => '89', value: '89' },
    { label: () => '91', value: '91' },
    { label: () => '92', value: '92' },
    { label: () => '93', value: '93' },
    { label: () => '94', value: '94' },
    { label: () => '95', value: '95' },
    { label: () => '96', value: '96' },
    { label: () => '97', value: '97' },
    { label: () => '98', value: '98' },
    { label: () => '99', value: '99' }
]

export const listAtiv = [
    { label: () => 'AGRICULTURA, PECUÁRIA E SERVIÇOS RELACIONADOS', value: '2' },
    { label: () => 'PRODUÇÃO FLORESTAL', value: '119' },
    { label: () => 'PESCA E AQÜICULTURA', value: '144' },
    { label: () => 'EXTRAÇÃO DE CARVÃO MINERAL', value: '174' },
    { label: () => 'EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL', value: '179' },
    { label: () => 'EXTRAÇÃO DE MINERAIS METÁLICOS', value: '185' },
    { label: () => 'EXTRAÇÃO DE MINERAIS NÃO-METÁLICOS', value: '211' },
    { label: () => 'ATIVIDADES DE APOIO À EXTRAÇÃO DE MINERAIS', value: '239' },
    { label: () => 'FABRICAÇÃO DE PRODUTOS ALIMENTÍCIOS', value: '249' },
    { label: () => 'FABRICAÇÃO DE BEBIDAS', value: '346' },
    { label: () => 'FABRICAÇÃO DE PRODUTOS DO FUMO', value: '365' },
    { label: () => 'FABRICAÇÃO DE PRODUTOS TÊXTEIS', value: '375' },
    {
        label: () => 'CONFECÇÃO DE ARTIGOS DO VESTUÁRIO E ACESSÓRIOS',
        value: '411'
    },
    {
        label: () =>
            'PREPARAÇÃO DE COUROS E FABRICAÇÃO DE ARTEFATOS DE COURO, ARTIGOS PARA VIAGEM E CALÇADOS',
        value: '431'
    },
    { label: () => 'FABRICAÇÃO DE PRODUTOS DE MADEIRA', value: '453' },
    {
        label: () => 'FABRICAÇÃO DE CELULOSE, PAPEL E PRODUTOS DE PAPEL',
        value: '468'
    },
    { label: () => 'IMPRESSÃO E REPRODUÇÃO DE GRAVAÇÕES', value: '494' },
    {
        label: () =>
            'FABRICAÇÃO DE COQUE, DE PRODUTOS DERIVADOS DO PETRÓLEO E DE BIOCOMBUSTÍVEIS',
        value: '515'
    },
    { label: () => 'FABRICAÇÃO DE PRODUTOS QUÍMICOS', value: '531' },
    {
        label: () => 'FABRICAÇÃO DE PRODUTOS FARMOQUÍMICOS E FARMACÊUTICOS',
        value: '594'
    },
    {
        label: () => 'FABRICAÇÃO DE PRODUTOS DE BORRACHA E DE MATERIAL PLÁSTICO',
        value: '607'
    },
    {
        label: () => 'FABRICAÇÃO DE PRODUTOS DE MINERAIS NÃO-METÁLICOS',
        value: '627'
    },
    { label: () => 'METALURGIA', value: '665' },
    {
        label: () =>
            'FABRICAÇÃO DE PRODUTOS DE METAL, EXCETO MÁQUINAS E EQUIPAMENTOS',
        value: '706'
    },
    {
        label: () =>
            'FABRICAÇÃO DE EQUIPAMENTOS DE INFORMÁTICA, PRODUTOS ELETRÔNICOS E ÓPTICOS',
        value: '752'
    },
    {
        label: () => 'FABRICAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS',
        value: '784'
    },
    { label: () => 'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS', value: '818' },
    {
        label: () => 'FABRICAÇÃO DE VEÍCULOS AUTOMOTORES, REBOQUES E CARROCERIAS',
        value: '883'
    },
    {
        label: () =>
            'FABRICAÇÃO DE OUTROS EQUIPAMENTOS DE TRANSPORTE, EXCETO VEÍCULOS AUTOMOTORES',
        value: '915'
    },
    { label: () => 'FABRICAÇÃO DE MÓVEIS', value: '943' },
    { label: () => 'FABRICAÇÃO DE PRODUTOS DIVERSOS', value: '953' },
    {
        label: () =>
            'MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS E EQUIPAMENTOS',
        value: '997'
    },
    { label: () => 'ELETRICIDADE, GÁS E OUTRAS UTILIDADES', value: '1050' },
    { label: () => 'CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA', value: '1066' },
    { label: () => 'ESGOTO E ATIVIDADES RELACIONADAS', value: '1070' },
    {
        label: () =>
            'COLETA, TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS; RECUPERAÇÃO DE MATERIAIS',
        value: '1076'
    },
    { label: () => 'CONSTRUÇÃO DE EDIFÍCIOS', value: '1097' },
    { label: () => 'OBRAS DE INFRA-ESTRUTURA', value: '1104' },
    { label: () => 'SERVIÇOS ESPECIALIZADOS PARA CONSTRUÇÃO', value: '1134' },
    {
        label: () => 'COMÉRCIO E REPARAÇÃO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS',
        value: '1175'
    },
    {
        label: () =>
            'COMÉRCIO POR ATACADO, EXCETO VEÍCULOS AUTOMOTORES E MOTOCICLETAS',
        value: '1217'
    },
    { label: () => 'COMÉRCIO VAREJISTA', value: '1398' },
    { label: () => 'TRANSPORTE TERRESTRE', value: '1521' },
    { label: () => 'TRANSPORTE AQUAVIÁRIO', value: '1560' },
    { label: () => 'TRANSPORTE AÉREO', value: '1586' },
    {
        label: () => 'ARMAZENAMENTO E ATIVIDADES AUXILIARES DOS TRANSPORTES',
        value: '1599'
    },
    { label: () => 'CORREIO E OUTRAS ATIVIDADES DE ENTREGA', value: '1636' },
    { label: () => 'ALOJAMENTO', value: '1646' },
    { label: () => 'ALIMENTAÇÃO', value: '1658' },
    { label: () => 'EDIÇÃO E EDIÇÃO INTEGRADA À IMPRESSÃO', value: '1673' },
    {
        label: () =>
            'ATIVIDADES CINEMATOGRÁFICAS, PRODUÇÃO DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO; GRAVAÇÃO DE SOM E EDIÇÃO DE MÚSICA',
        value: '1692'
    },
    { label: () => 'ATIVIDADES DE RÁDIO E DE TELEVISÃO', value: '1707' },
    { label: () => 'TELECOMUNICAÇÕES', value: '1717' },
    {
        label: () => 'ATIVIDADES DOS SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO',
        value: '1744'
    },
    {
        label: () => 'ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO',
        value: '1750'
    },
    { label: () => 'ATIVIDADES DE SERVIÇOS FINANCEIROS', value: '1762' },
    {
        label: () =>
            'SEGUROS, RESSEGUROS, PREVIDÊNCIA COMPLEMENTAR E PLANOS DE SAÚDE',
        value: '1830'
    },
    {
        label: () =>
            'ATIVIDADES AUXILIARES DOS SERVIÇOS FINANCEIROS, SEGUROS, PREVIDÊNCIA COMPLEMENTAR E PLANOS DE SAÚDE',
        value: '1851'
    },
    { label: () => 'ATIVIDADES IMOBILIÁRIAS', value: '1884' },
    {
        label: () => 'ATIVIDADES JURÍDICAS, DE CONTABILIDADE E DE AUDITORIA',
        value: '1897'
    },
    {
        label: () =>
            'ATIVIDADES DE SEDES DE EMPRESAS E DE CONSULTORIA EM GESTÃO EMPRESARIAL',
        value: '1909'
    },
    {
        label: () =>
            'SERVIÇOS DE ARQUITETURA E ENGENHARIA; TESTES E ANÁLISES TÉCNICAS',
        value: '1915'
    },
    { label: () => 'PESQUISA E DESENVOLVIMENTO CIENTÍFICO', value: '1930' },
    { label: () => 'PUBLICIDADE E PESQUISA DE MERCADO', value: '1937' },
    {
        label: () => 'OUTRAS ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS',
        value: '1952'
    },
    { label: () => 'ATIVIDADES VETERINÁRIAS', value: '1967' },
    { label: () => 'ALUGUEL DE BENS IMÓVEIS', value: '1977' },
    { label: () => 'ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS', value: '1982' },
    {
        label: () => 'REPARAÇÃO E MANUTENÇÃO DE OBJETOS PESSOAIS E DOMÉSTICOS',
        value: '1989'
    }
]

export const tiposLougradoro = [
    "ACESSO", "AEROPORTO", "ALAMEDA", "AREA", "AVENIDA", "CAMPO", "CALCADA", "CHACARA",
    "COLONIA", "CONDOMINIO", "CONJUNTO", "DISTRITO", "ESPLANADA", "ESTACAO", "ESTRADA",
    "FAVELA", "FAZENDA", "FEIRA", "JARDIM", "LADEIRA", "LAGO", "LAGOA", "LARGO",
    "LOTEAMENTO", "MORRO", "NUCLEO", "PARQUE", "PASSARELA", "PATIO", "PRACA", "QUADRA",
    "RECANTO", "RESIDENCIAL", "RIO", "RODOVIA", "RUA", "RUELA", "SETOR", "SITIO",
    "TRAVESSA", "TRECHO", "TREVO", "VALE", "VARGEM", "VEREDA", "VIA", "VIADUTO",
    "VIELA", "VILA", "OUTRO"
];