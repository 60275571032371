import { Box } from '@chakra-ui/react'
import { formatCNPJ } from 'helpers/formats'
import { UserModel } from 'interfaces/user'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
  readonly: boolean
}

const Invoicing = ({
  userData,
  changeUserData,
  readonly
}: Props): JSX.Element => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 3,
            fields: [
              {
                type: 'text',
                readOnly: readonly,
                label: 'CNPJ para Faturamento *',
                name: 'invoicing_doc',
                mask (data) {
                  return formatCNPJ(String(data))
                },
                maxLength: 18
              },
              {
                type: 'select',
                readOnly: readonly,
                label: 'Prazo de Vencimento (Em Dias Corridos) *',
                name: 'invoicing_days',
                options: [
                  {
                    label: () => '15',
                    value: '15'
                  },
                  {
                    label: () => '30',
                    value: '30'
                  },
                  {
                    label: () => '60',
                    value: '60'
                  }
                ]
              }
            ]
          }
        ]}
        value={userData}
        onChange={(v) => {
          v.alter = true
          changeUserData(v)
        }}
      />
    </Box>
  )
}

export default Invoicing
