import {
    Box,
    Input,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList
} from '@chakra-ui/react'
import React from 'react'
import { Col } from '../Col'
import { OptionalLink } from '../OptionalLink'

interface SimpleMenuProps extends MenuButtonProps {
  groups: Array<{
    title: string
    items: Array<SimpleMenuItem>
  }>
}

export interface SimpleMenuItem extends MenuItemProps {
  label: (props: { groupIndex: number }) => React.ReactNode
  to?: string
  isHidden?: boolean
}

export const SimpleMenu: React.NextFC<SimpleMenuProps> = ({
  groups,
  ...rest
}) => {
    const [filter, setFilter] = React.useState("");
  return (
    <>
      <Menu matchWidth>
        <MenuButton {...rest} />

        <MenuList
          zIndex={9999999999}
          border={1}
          borderStyle={'solid'}
          rounded={'md'}
          shadow={'xl'}
          borderColor={'gray.200'}
          _dark={{ borderColor: 'gray.600' }}
          maxHeight={'260px'}
          overflow={'auto'}
        >
            <Box px="4" py="2">
          <Input
            placeholder="Busca..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
          {groups.map((g, groupIndex) => (
            <MenuGroup key={`group${groupIndex}`} title={g.title}>
              {g.items.filter((i) => i?.label({ groupIndex })?.toString().toLowerCase().includes(filter.toLowerCase()))
                .filter((i) => !i.isHidden)
                .map(({ label, to, ...restItem }, itemIndex) => (
                  <OptionalLink key={`item${itemIndex}${groupIndex}`} to={to}>
                    <Col px={4}>
                      <MenuItem rounded={'sm'} {...restItem}>
                        {label({ groupIndex })}
                      </MenuItem>
                    </Col>
                  </OptionalLink>
                ))}
            </MenuGroup>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}
