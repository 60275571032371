import { FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface TextFieldAreaProps extends FieldProps<'textarea', string, string> {}

const TextFieldArea: React.FC<TextFieldAreaProps> = ({
  infos,
  value,
  onChange,
  mask,
  isReadonly
}) => {
  const isUndefined = value === undefined
  const isNull = value === null
  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return 'Não definido'
    }

    if (isUndefined || isNull) {
      return ''
    }

    return value
  }, [value, isUndefined, isNull, isReadonly])

  return (
    <FormControl
      minH={'100%'}
      display={'flex'}
      flexDir={'column'}
      fontFamily={'heading'}
    >
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
        <Textarea
          rounded={'sm'}
          value={`${fieldValue}`}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value
            onChange?.(value)
          }}
          readOnly={isReadonly}
          variant={'filled'}
          border={'1px solid #818c96'}
          backgroundColor={'white'}
          fontStyle={isUndefined ? 'italic' : 'normal'}
          color={isUndefined ? 'gray.500' : 'inherit'}
          _focus={
            isReadonly
              ? {}
              : {
                  bg: 'white'
                }
          }
        />
      </Row>
    </FormControl>
  )
}

export default TextFieldArea
