import { Box } from '@chakra-ui/react'
import UserPickEntityLegalNature from 'components/generic/logic/UserPickEntityLegalNature'
import { formatCelPhone, formatCEP, formatPhone } from 'helpers/formats'
import { listAtiv, listDDD, listUF } from 'helpers/listsTypes'
import { UserModel } from 'interfaces/user'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
  readonly: boolean
}

const DataClient = ({
  userData,
  changeUserData,
  readonly
}: Props): JSX.Element => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Empresa`,
                name: 'Empresa',
                size: 'xs'
              }
            ]
          },
          {
            columns: 2,
            fields: [
              {
                type: 'text',
                label: 'Razão Social *',
                name: 'user_name',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Nome Fantasia',
                name: 'fantasy_name',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 0,
            fields: [
              {
                type: 'text',
                label: 'Inscrição Municipal / CCM *',
                name: 'municipalregistration_ccm',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Inscrição Estadual *',
                name: 'stateregistration',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Site *',
                name: 'website',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Endereço`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 2,
            fields: [
              {
                type: 'text',
                label: 'CEP *',
                name: 'address_zipcode',
                mask (data) {
                  return formatCEP(String(data))
                },
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Endereço Comercial *',
                name: 'address_place',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 5,
            fields: [
              {
                type: 'text',
                label: 'Número *',
                name: 'address_number',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Complemento',
                name: 'address_complement',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Bairro *',
                name: 'address_district',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Cidade *',
                name: 'address_city',
                readOnly: readonly
              },
              {
                type: 'select',
                label: 'UF *',
                name: 'address_state',
                options: listUF,
                readOnly: readonly
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Contato de Referência`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 0,
            fields: [
              {
                type: 'select',
                options: listDDD,
                label: 'DDD *',
                name: 'ddd_phone',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Telefone *',
                name: 'contact_phone',
                readOnly: readonly,
                mask (data) {
                  return formatPhone(data)
                }
              },
              {
                type: 'select',
                options: listDDD,
                label: 'DDD',
                name: 'ddd_cellphone',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Celular',
                name: 'contact_cellphone',
                readOnly: readonly,
                mask (data) {
                  return formatCelPhone(data)
                }
              },
              {
                type: 'text',
                label: 'Nome de Contato *',
                name: 'contact_name',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'E-mail de Contato *',
                name: 'email',
                readOnly: readonly
              },
              {
                type: 'text',
                label: 'Cargo *',
                name: 'contact_role',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Financeiro da Empresa`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 4,
            fields: [
              {
                type: 'date',
                label: 'Data da Constituição *',
                name: 'opening_date',
                readOnly: readonly
              },
              {
                type: 'select',
                label: 'Forma da Constituição *',
                name: 'form_constitution',
                readOnly: readonly,
                options: [
                  {
                    label: () => 'LTDA',
                    value: 'LTDA'
                  },
                  {
                    label: () => 'S/A',
                    value: 'S/A'
                  },
                  {
                    label: () => 'EPP',
                    value: 'EPP'
                  },
                  {
                    label: () => 'ME',
                    value: 'ME'
                  },
                  {
                    label: () => 'CCM',
                    value: 'CCM'
                  },
                  {
                    label: () => 'OUTROS',
                    value: 'OUTROS'
                  }
                ]
              },
              {
                type: 'select',
                options: [
                  {
                    label: () => 'Nacional',
                    value: 1
                  },
                  {
                    label: () => 'Internacional',
                    value: 2
                  }
                ],
                label: 'Origem do Capital *',
                name: 'origin_capital',
                readOnly: readonly
              },
              {
                type: 'currency',
                label: 'Capital Social *',
                name: 'share_capital',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 3,
            fields: [
              {
                type: 'select',
                options: listAtiv,
                label: 'Atividade Principal',
                name: 'main_activity',
                readOnly: readonly
              },
              {
                type: 'currency',
                label: 'Patrimonio Líquido *',
                name: 'net_worth',
                readOnly: readonly
              },
              {
                type: 'currency',
                label: 'Faturamento Médio Mensal *',
                name: 'average_billing',
                readOnly: readonly
              }
            ]
          }
        ]}
        value={userData}
        onChange={(v) => {
          v.alter = true
          changeUserData(v)
        }}
      />
      <UserPickEntityLegalNature
        value={{
          entityId: userData.entity,
          legalNatureId: userData.legal_nature
        }}
        onChange={(v) => {
          changeUserData({
            ...userData,
            legal_nature: v.legalNatureId,
            entity: String(v.entityId),
            alter: true
          })
        }}
      />
    </Box>
  )
}

export default DataClient
