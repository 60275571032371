import {
  CloseButton,
  FormControl,
  FormLabel,
  HStack,
  Input
} from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { SimpleMenu } from 'v2/components/shared/SimpleMenu'
import { FieldProps } from '../..'

interface SelectFieldProps
  extends FieldProps<
    'select',
    string | number | undefined | null,
    string | number | undefined | null
  > {}

const SelectField: React.FC<SelectFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly
}) => {
  const isUndefined = value === undefined
  const isNull = value === null

  const nextOptions = useMemo(() => {
    if (!infos.options) return []

    return infos.options.map((opt) => ({
      ...opt,
      onClick: () => onChange?.(opt.value! as any)
    }))
  }, [infos.options, onChange])

  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return infos.label
    }

    if (isUndefined || isNull) {
      return infos.label
    }

    const nextValue = nextOptions
      .find((opt) => opt.value === value)
      ?.label({ groupIndex: 0 })

    if (!nextValue) {
      return `Error`
    }

    return nextValue
  }, [value, isUndefined, isNull, isReadonly, infos.label, nextOptions])

  return (
    <FormControl>
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0} fontFamily={'heading'}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      <Row
        pos={'relative'}
        alignItems={'center'}
        role='group'
        mt={'auto'}
        w={'100%'}
      >
        {isReadonly && (
          <Input
            as={Row}
            alignItems={'center'}
            variant={'filled'}
            border={'1px solid #818c96'}
            backgroundColor={'white'}
            rounded={'sm'}
            w={'100%'}
            pos={'relative'}
            zIndex={10}
            userSelect={'none'}
          >
            {fieldValue}
          </Input>
        )}
        {!isReadonly && (
          <>
            <SimpleMenu
              width={'100%'}
              groups={[
                {
                  title: infos.label,
                  items: nextOptions
                }
              ]}
            >
              <Input
                as={Row}
                alignItems={'center'}
                variant={'filled'}
                border={'1px solid #818c96'}
                backgroundColor={'white'}
                rounded={'sm'}
                w={'100%'}
                pos={'relative'}
                zIndex={10}
              >
                {fieldValue}
              </Input>
            </SimpleMenu>

            <HStack pos={'absolute'} right={1} zIndex={100}>
              {!isUndefined && !isNull && (
                <CloseButton
                  size={'sm'}
                  onClick={() => {
                    onChange?.(null)
                  }}
                />
              )}
            </HStack>
          </>
        )}
        {/* <Input
          rounded={"sm"}
          type={"number"}
          value={`${fieldValue}`}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value);
          }}
          readOnly={isReadonly}
          variant={"filled"}
          fontStyle={isUndefined ? "italic" : "normal"}
          color={isUndefined ? "gray.500" : "inherit"}
          _focus={
            isReadonly
              ? {}
              : {
                  bg: "white",
                }
          }
        />
        <ButtonCopy value={value || ""} /> */}
      </Row>
    </FormControl>
  )
}

export default SelectField
