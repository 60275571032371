import { Box } from '@chakra-ui/react'
import { UserModel } from 'interfaces/user'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
  readonly: boolean
}

const Contacts = ({
  userData,
  changeUserData,
  readonly
}: Props): JSX.Element => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 3,
            fields: [
              {
                type: 'select',
                readOnly: readonly,
                label: 'Tipo de Envio',
                name: 'file_return_type',
                options: [
                  {
                    label: () => 'Contrato de Cambio',
                    value: 1
                  },
                  {
                    label: () => 'Notas de Corretagem',
                    value: 2
                  },
                  {
                    label: () => 'Ambos',
                    value: 3
                  }
                ]
              },
              {
                type: 'select',
                readOnly: readonly,
                label: 'Forma de Assinatura',
                name: 'form_signature',
                options: [
                  {
                    label: () => 'Digital',
                    value: 1
                  },
                  {
                    label: () => 'Manual',
                    value: 2
                  }
                ]
              }
            ]
          },
          {
            columns: 0,
            fields: [
              {
                type: 'textarea',
                readOnly: readonly,
                label: 'E-mails',
                name: 'contact_emails',
                description:
                  'Separa com ponto e vírgula (;) os e-mails. ex: a@a.com;b@b.com'
              }
            ]
          }
        ]}
        value={userData}
        onChange={(v) => {
          v.alter = true
          if (v.contact_emails) {
            v.contact_emails = String(v.contact_emails)
              .replace(' ', ';')
              .replace(',', ';')
              .replace('\n', ';')
          }
          changeUserData(v)
        }}
      />
    </Box>
  )
}

export default Contacts
