import { actionApplication } from "./actionApplication";

export interface BankModel extends actionApplication {
  syscambio_bank?: number;
  id?: number;
  user_id?: number;
  bank_code?: string;
  bank_name?: string;
  bank_agency?: string;
  bank_city?: string;
  bank_state?: string;
  bank_account?: string;
  account_type?: "C" | "P";
  opening_date?: string;
  status?: BankStatusEnum;
  created_date?: string;
  created_by_id?: number;
  updated_date?: string;
  updated_by_id?: number;
}

export enum BankStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
}

export interface BankEntity {
  bank_code: string;
  bank_info: string;
  bank_document: string;
}
