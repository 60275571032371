import { Box, Button } from '@chakra-ui/react'
import { UserModel } from 'interfaces/user'
import { ArchiveModel } from 'interfaces/user-archives'
import { useEffect, useState } from 'react'
import { FileProp } from 'v2/components/forms/documents/contracts'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { createDocUser } from './pdfExportToSignature/pdfExportToSignature'
import { BeneficiaryModel } from 'interfaces/beneficiary'
import { BankModel } from 'interfaces/bank'

interface Props {
  files: ArchiveModel
  userData: UserModel
  beneficiarys: BeneficiaryModel[]
  accountData: BankModel[]
  changeFilesData: (files: ArchiveModel) => void
  readonly: boolean
}

interface FormFiles {
  file_company_registration?: FileProp[]
  file_representative_registration?: FileProp[]
}

const ToSignature = ({
  files,
  userData,
  beneficiarys,
  accountData,
  changeFilesData,
  readonly
}: Props): JSX.Element => {
  const [filesForm, setFileForm] = useState<FormFiles>({})

  function convert (data: ArchiveModel) {
    setFileForm({
      file_company_registration: data.file_company_registration
        ? [
            {
              isBase64: data.file_company_registration?.includes('https')
                ? true
                : false,
              src: data.file_company_registration,
              mimeType: data.file_company_registration_type,
              name: 'file_company_registration'
            }
          ]
        : undefined,
      file_representative_registration: data.file_representative_registration
        ? [
            {
              isBase64: data.file_representative_registration?.includes('https')
                ? true
                : false,
              src: data.file_representative_registration,
              mimeType: data.file_representative_registration_type,
              name: 'file_representative_registration'
            }
          ]
        : undefined
    })
  }

  useEffect(() => {
    convert(files)
  }, [files])
  return (
    <Box m={4}>
      <Box>
        <Button
          variant={'solid'}
          colorScheme='primary'
          onClick={() => {
            createDocUser(userData, beneficiarys, accountData)
          }}
        >
          Baixe aqui as Fichas
        </Button>
      </Box>
      <Box w={'50vw'}>
        <SimpleFormGenerator
          readOnly={readonly}
          rows={[
            {
              columns: 1,
              fields: [
                {
                  type: 'heading',
                  as: 'h1',
                  label: `Fichas Cadastrais`,
                  name: 'title',
                  size: 'lg'
                },
                {
                  type: 'heading',
                  as: 'h6',
                  label: `Os arquivos anexados não poderão passar de 10MB e só é possível anexar 1 arquivo, se adicionar outro em um local já anexado o arquivo será sobreposto. Caso seja necessário subir mais de um arquivo por item, os mesmos podem ser compactados em uma arquivo .zip`,
                  name: 'title',
                  size: 'sm'
                },
                {
                  type: 'files',
                  label: 'Ficha Cadastral da Empresa *',
                  name: 'file_company_registration'
                },
                {
                  type: 'files',
                  label: 'Ficha Cadastral do Representante *',
                  name: 'file_representative_registration'
                }
              ]
            }
          ]}
          value={filesForm}
          onChange={(v) => {
            const data = {
              ...files,
              alter: true,
              ...{
                file_company_registration:
                  v?.file_company_registration?.[0]?.src,
                file_representative_registration:
                  v?.file_representative_registration?.[0]?.src,
                file_company_registration_type:
                  v?.file_company_registration?.[0]?.mimeType,
                file_representative_registration_type:
                  v?.file_representative_registration?.[0]?.mimeType
              }
            }
            changeFilesData(data)
          }}
        />
      </Box>
    </Box>
  )
}

export default ToSignature
