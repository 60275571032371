import { actionApplication } from "./actionApplication";

export interface BeneficiaryModel extends actionApplication {
  id?: number;
  user_id?: number;
  id_syscambio?: number;
  syscambio_beneficiary?: string;
  beneficiary_name?: string;
  document?: string;
  participation_percentage?: string;
  final_beneficiary?: boolean;
  attorney?: boolean;
  legal_representative?: boolean;
  person?: BeneficiaryPersonEnum;
  type?: BeneficiaryTypeEnum;
  status?: BeneficiaryStatusEnum;
  created_date?: string;
  created_by_id?: number;
  updated_date?: string;
  updated_by_id?: number;
  //V2
  is_only_legal_representative?: boolean;
  legal_representative_name?: string;
  shareholder?: boolean;
  administrator?: boolean;
  pep?: boolean;
  name_mother?: string;
  name_father?: string;
  nationality?: string;
  email?: string;
  rg?: string;
  estimated_income?: number;
  //Address
  cod_address?: number
  address?: string;
  address_zipcode?: string;
  address_type?: string;
  address_number?: string;
  address_complement?: string;
  address_district?: string;
  address_city?: string;
  address_state?: string;

}

export enum BeneficiaryStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
}

export enum BeneficiaryTypeEnum {
  CONTATO = "CONTATO",
  SOCIO = "SOCIO / DIRETOR",
  DIRETOR = "DIRETOR",
  ADMINISTRADOR = "ADMINISTRADOR",
}

export enum BeneficiaryPersonEnum {
  FISICO = "FISICO",
  JURIDICO = "JURIDICO",
}
