import { axiosWebsite } from 'helpers/axiosInstances'
import { LegalEntityModel } from 'interfaces/legalNature'
import React, { useEffect, useMemo, useState } from 'react'
// import { InputSelect } from '../GenericInputs'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { Box } from '@chakra-ui/react'

interface Value {
  entityId: string | number
  legalNatureId: string
}

interface UserPickEntityLegalNatureProps {
  onChange?: (data: Value) => void
  value: Partial<Value>
}

const UserPickEntityLegalNature: React.FC<UserPickEntityLegalNatureProps> = ({
  value,
  onChange: onChangeParam
}) => {
  const [valueState, setValue] = useState(value)
  const [entities, setEntities] = useState<LegalEntityModel[]>([])

  const natures = useMemo(() => {
    if (!value.entityId) return []
    console.log(entities)
    const entity = entities.find((e) => e.Codigo === Number(value.entityId))

    if (!entity) return []

    return entity.Naturezas
  }, [value.entityId, entities])

  const onChange = (nextValue: Partial<Value>) => {
    setValue((oldValue) => {
      return { ...oldValue, ...nextValue }
    })

    onChangeParam?.({ ...valueState, ...nextValue } as any)
  }

  const load = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    axiosWebsite
      .get('/entidades', config)
      .then((res) => res.data)
      .then(setEntities)
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Box flexDir={'row'} flex={1}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 0,
            fields: [
              {
                type: 'select',
                label: 'Entidade *',
                name: 'entityId',
                options: entities.map((e) => {
                  return {
                    label: () => e.Descricao,
                    value: e.Codigo?.toString()
                  }
                })
              },
              {
                type: 'select',
                label: 'Natureza Jurídica *',
                name: 'legalNatureId',
                options: natures.map((e) => {
                  return {
                    label: () => e.Descricao,
                    value: e.Codigo?.toString()
                  }
                })
              }
            ]
          }
        ]}
        value={value}
        onChange={(v) => {
          if (!v.entityId) {
            v.legalNatureId = null
          }
          if (v.entityId !== value.entityId) {
            v.legalNatureId = null
          }
          onChange({
            entityId: v.entityId,
            legalNatureId: v.legalNatureId
          })
        }}
      />
      {
        // <InputSelect
        //   label={'Entidade *'}
        //   changeValue={(v) => {
        //     onChange({
        //       entityId: Number(v)
        //     })
        //   }}
        //   maxWidth={'350px'}
        //   value={value.entityId! as any}
        // >
        //   <option>Selecione...</option>
        //   {entities.map((entity) => (
        //     <option key={`option${entity.Codigo}`} value={entity.Codigo}>
        //       {entity.Descricao}
        //     </option>
        //   ))}
        // </InputSelect>
      }
      {
        // <InputSelect
        //   label={'Natureza Jurídica *'}
        //   changeValue={(v) => {
        //     onChange({
        //       legalNatureId: v
        //     })
        //   }}
        //   maxWidth={'350px'}
        //   value={value.legalNatureId!}
        //   readOnly={natures.length <= 0}
        // >
        //   <option>Selecione...</option>
        //   {natures.map((nature) => (
        //     <option key={`option${nature.Codigo}`} value={nature.Codigo}>
        //       {nature.Descricao}
        //     </option>
        //   ))}
        // </InputSelect>
      }
    </Box>
  )
}

export default UserPickEntityLegalNature
