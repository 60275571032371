import { actionApplication } from "./actionApplication";

export interface UserModel extends actionApplication {
  id?: number;
  company_id?: number;
  company_document?: string;
  company_name?: string;
  group_id?: string;
  id_syscambio?: string;
  login?: string;
  operates_export?: string;
  operates_import?: string;
  operates_entrance?: string;
  operates_remittance?: string;
  politically_exposed?: boolean;
  photo?: string;
  syscambio_email?: string;
  email?: string;
  document?: string;
  opening_date?: string;
  user_name?: string;
  fantasy_name?: string;
  bacen_code?: string;
  municipalregistration_ccm?: string;
  stateregistration?: string;
  legal_nature?: string;
  entity?: string;
  website?: string;
  main_activity?: string;
  net_worth?: string;
  average_billing?: string;
  syscambio_contact?: string;
  contact_name?: string;
  contact_role?: string;
  ddd_cellphone?: string;
  ddd_phone?: string;
  contact_branch?: string;
  contact_cellphone?: string;
  contact_phone?: string;
  contact_branchline?: string;
  nationality?: string;
  nationality_country?: string;
  birthdate?: string;
  passaport?: string;
  passaport_issue?: string;
  passaport_validity?: string;
  general_registry?: string;
  issuing_agency?: string;
  agency_state?: string;
  rg_issue?: string;
  cnh_number?: string;
  cnh_issue?: string;
  cnh_validity?: string;
  father_name?: string;
  mother_name?: string;
  marital_status?: UserMaritalEnum;
  spouse_name?: string;
  spouse_cpf?: string;
  country?: string;
  syscambio_address?: string;
  address_zipcode?: string;
  address_city?: string;
  address_state?: string;
  address_district?: string;
  address_type?: UserAddressTypeEnum;
  address_place?: string;
  address_number?: string;
  address_complement?: string;
  password?: string;
  status?: UserStatusEnum;
  cad_type?: UserTypeEnum;
  responsible_sector?: UserResponsibleEnum;
  old_sector?: string;
  client_list?: number[];
  created_date?: string;
  created_by_id?: string;
  updated_date?: string;
  updated_by_id?: string;
  last_access_at?: string;
  access_token?: string;
  refresh_token?: string;
  permission_list?: number[];
  permission_group_company?: number[];
  recover_token?: string;
  dascam_type?: string;
  notifiables?: string;
  //V2
  contract_cad_type?: number
  type_relationship?: number
  purpose_relationship?: string
  manager_dascam?: string
  form_constitution?: string
  origin_capital?: number
  share_capital?: number
  file_return_type?: number
  form_signature?: number
  invoicing_days?: number
  contact_emails?: string
  invoicing_doc?: string
}

export enum UserStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
  EVALUATION = "EVALUATION",
  PENDING = "PENDING",
  DISAPROVED = "DISAPPROVED",
}

export enum UserTypeEnum {
  FISICO = "FISICO",
  JURIDICO = "JURIDICO",
  ESTRANGEIRO = "ESTRANGEIRO",
  AUTORIZADO = "AUTORIZADO",
  DASCAM = "DASCAM",
}

export enum UserMaritalEnum {
  SOLTEIRO = "SOLTEIRO",
  CASADO = "CASADO",
  SEPARADO = "SEPARADO",
  DIVORCIADO = "DIVORCIADO",
  VIUVO = "VIUVO",
}

export enum UserResponsibleEnum {
  CLIENTE = "CLIENTE",
  CADASTRO = "CADASTRO",
  COMPLIANCE = "COMPLIANCE",
  DIRETORIA = "DIRETORIA",
}

export enum UserAddressTypeEnum {
  AEROPORTO = "AEROPORTO",
  ALAMEDA = "ALAMEDA",
  AVENIDA = "AVENIDA",
  CONDOMÍNIO = "CONDOMÍNIO",
  ESTRADA = "ESTRADA",
  RODOVIA = "RODOVIA",
  RUA = "RUA",
  VIADUTO = "VIADUTO",
}
