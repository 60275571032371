import { isUnauthorized } from "helpers/errors";
import axios, { AxiosError } from "axios";

const axiosLogin = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    baseURL: process.env.REACT_APP_AUTH_API
        ? process.env.REACT_APP_AUTH_API
        //: "https://auth-direct-homol.dascam.com.br/api",
        : "http://localhost:8081/api",
});

const axiosAuth = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    baseURL: process.env.REACT_APP_AUTH_API
        ? process.env.REACT_APP_AUTH_API
        //: "https://auth-direct-homol.dascam.com.br/api",
        : "http://localhost:8081/api",
});

const axiosWebsite = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    baseURL: process.env.REACT_APP_WEBSITE_API
        ? process.env.REACT_APP_WEBSITE_API
        //: "https://service-direct-homol.dascam.com.br/api",
        : "http://localhost:8080/api",
});

const axiosSupplier = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        "rpatoken": '2b9c4d28-2ccb-4864-8b62-96ce7e60f0d6'
    },
    baseURL: process.env.REACT_APP_SUPPLIER_API ? process.env.REACT_APP_SUPPLIER_API : "http://localhost:8085/api"
});

const axiosAws = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    baseURL: process.env.REACT_APP_AWS_API
        ? process.env.REACT_APP_AWS_API
        : // : "https://api-dascam-direct-homolog.digitaly.tech/api",
        "https://dascam-direct-s3.digitaly.tech/api",
});

const axiosEmail = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    baseURL: process.env.REACT_APP_MAIL_API
        ? process.env.REACT_APP_MAIL_API
        : "http://localhost:8088/api",
});

const axiosCep = axios.create({
    baseURL: process.env.REACT_APP_CEP_API
        ? process.env.REACT_APP_CEP_API
        : "https://viacep.com.br/ws/",
});

const axiosGeo = axios.create({
    baseURL: process.env.REACT_APP_GEO_API
        ? process.env.REACT_APP_GEO_API
        : "https://nominatim.openstreetmap.org/search?",
});

// Interceptor que verifica erro de autorização e tenta recarregar o token.

// axiosAuth.interceptors.response.use(
//   response => {
//     return response;
//   },
//   async function (error) {
//     const access_token = localStorage.getItem("access_token");
//     if (isUnauthorized(error) && access_token) {
//       const response = await refreshToken(error);
//       return response;
//     }
//     return Promise.reject(error);
//   }
// );

// async function refreshToken(error: any) {
//   return new Promise((resolve, reject) => {
//     try {
//       const refresh_token = localStorage.getItem("refresh_token");

//       const body = {
//         grant_type: "refresh_token",
//         refresh_token: refresh_token,
//       };

//       axiosLogin
//         .post("/login", body)
//         .then(async res => {
//           localStorage.setItem("access_token", res.data.access_token);
//           localStorage.setItem("refresh_token", res.data.refresh_token);
//           // Fazer algo caso seja feito o refresh token
//           return resolve(res);
//         })
//         .catch(err => {
//           // Fazer algo caso não seja feito o refresh token
//           // console.log("Error refresh");
//           return reject(error);
//         });
//     } catch (err) {
//       return reject(err);
//     }
//   });
// }

axiosAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config } = error as AxiosError;

        if (isUnauthorized(error)) {
            const refreshToken = localStorage.getItem("refresh_token");
            if (refreshToken) {
                try {
                    const { data } = await axiosLogin.post(`/login`, {
                        grant_type: "refresh_token",
                        refresh_token: refreshToken,
                    });

                    if (data.access_token && data.refresh_token) {
                        localStorage.setItem("access_token", data.access_token);
                        localStorage.setItem("refresh_token", data.refresh_token);
                        config.headers!["Authorization"] = `Bearer ${data.access_token}`;
                        return axiosAuth(config);
                    }
                } catch (error: any) {
                    console.error(`Erro ao recarregar token de refresh. ${error}`);
                    localStorage.clear();
                }
            }
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

axiosWebsite.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config } = error as AxiosError;
        if (isUnauthorized(error)) {
            const refreshToken = localStorage.getItem("refresh_token");
            if (refreshToken) {
                try {
                    const { data } = await axiosLogin.post(`/login`, {
                        grant_type: "refresh_token",
                        refresh_token: refreshToken,
                    });

                    if (data.access_token && data.refresh_token) {
                        localStorage.setItem("access_token", data.access_token);
                        localStorage.setItem("refresh_token", data.refresh_token);
                        config.headers!["Authorization"] = `Bearer ${data.access_token}`;
                        return axiosWebsite(config);
                    }
                } catch (error: any) {
                    console.error(`Erro ao recarregar token de refresh. ${error}`);
                    localStorage.clear();
                }
            }
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export function isAxiosError(candidate: any): candidate is AxiosError {
    return candidate.isAxiosError === true;
}

export {
    axiosLogin,
    axiosAuth,
    axiosWebsite,
    axiosSupplier,
    axiosAws,
    axiosEmail,
    axiosCep,
    axiosGeo,
};
