import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { BankEntity, BankModel, BankStatusEnum } from 'interfaces/bank'
import { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { v4 as uuidv4 } from 'uuid'
import { validateFieldV2 } from 'helpers/validFilds'
import { backAccount } from 'helpers/formats'

interface Props {
  accountData: BankModel[]
  banks: BankEntity[]
  changeAccountData: (accountData: BankModel[]) => void
  readonly: boolean
}

const Reference = ({
  accountData,
  banks,
  changeAccountData,
  readonly
}: Props): JSX.Element => {
  const toast = useToast()
  const [account, setAccountData] = useState<BankModel>({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columns: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.id,
      omit: true
    },
    {
      name: 'AÇÕES',
      cell: (row, i) => action(row, i),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true
    },
    {
      name: 'Banco',
      selector: (row) => row?.bank_name ?? '---',
      sortable: true
    },
    {
      name: 'Agência',
      selector: (row) => row.bank_agency ?? '',
      width: '100px'
    },
    {
      name: 'Conta',
      selector: (row) => row.bank_account,
      sortable: true,
      width: '130px'
    },
    {
      name: 'Tipo da Conta',
      selector: (row) => row.account_type,
      sortable: true,
      width: '130px',
      right: true,
      cell: (row) => (
        <Badge variant='solid' colorScheme={'primary'}>
          {backAccount(row.account_type ?? '')}
        </Badge>
      )
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      width: '160px',
      right: true,
      cell: (row) => {
        const active = row.status === 'ACTIVE'
        return (
          <Badge variant='solid' colorScheme={active ? 'green' : 'red'}>
            {active ? 'Ativo' : 'Inativo/Bloqueado'}
          </Badge>
        )
      }
    }
  ]

  function action (data: any, index: any) {
    return (
      <HStack m={1}>
        <IconButton
          onClick={() => {
            setAccountData(accountData[index])
            onOpen()
          }}
          aria-label='Editar'
          title='Editar'
          size={'sm'}
          colorScheme={'secondary_main'}
        >
          <EditDataIcon />
        </IconButton>
      </HStack>
    )
  }

  function addBank () {
    try {
      if (!account.id) {
        account.uuid = uuidv4()
      }
      changeAccountData([...accountData, account])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function editBank () {
    try {
      const ref = account.id || account.uuid

      const index = accountData.findIndex(
        (item) => item.id === ref || item.uuid === ref
      )

      if (index !== -1) {
        accountData.splice(index, 1)
      }

      changeAccountData([...accountData, account])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function clearData () {
    try {
      setAccountData({})
    } catch (error) {}
  }

  function validForm () {
    const valid = validateFieldV2(account, [
      'bank_name',
      'bank_code',
      'bank_agency',
      'bank_account',
      'account_type',
      'status'
    ])

    if (!valid) {
      toast({
        title: 'Existem campos sem preenchimento!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    return valid
  }

  function BankForm () {
    const statusReadOnly = !account.id
    if (statusReadOnly) {
      account.status = BankStatusEnum.ACTIVE
    }
    return (
      <>
        <Modal
          size={'5xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            clearData()
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent ml={4} mr={4} rounded={'xl'}>
            <ModalHeader>Banco</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'select',
                          label: 'Tipo de Conta *',
                          name: 'account_type',
                          readOnly: readonly,
                          options: [
                            {
                              label: () => 'Conta Corrente',
                              value: 'C'
                            },
                            {
                              label: () => 'Conta Poupança',
                              value: 'P'
                            }
                          ]
                        },
                        {
                          type: 'date',
                          label: `Data de Abertura`,
                          readOnly: readonly,
                          name: 'opening_date'
                        },
                        {
                          type: 'select',
                          label: 'Status Conta *',
                          readOnly: statusReadOnly,
                          name: 'status',
                          options: [
                            {
                              label: () => 'Ativo',
                              value: 'ACTIVE'
                            },
                            {
                              label: () => 'Inativo/Bloqueado',
                              value: 'INACTIVE'
                            }
                          ]
                        }
                      ]
                    },
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'select',
                          label: `Banco *`,
                          readOnly: readonly,
                          name: 'bank_code',
                          options: banks.map((e) => {
                            return {
                              label: () => e.bank_info,
                              value: e.bank_code
                            }
                          })
                        },
                        {
                          type: 'text',
                          readOnly: readonly,
                          label: `Agência *`,
                          name: 'bank_agency',
                          maxLength: 4
                        },
                        {
                          type: 'text',
                          readOnly: readonly,
                          label: `Conta *`,
                          name: 'bank_account',
                          maxLength: 11
                        }
                      ]
                    }
                  ]}
                  value={account}
                  onChange={(data) => {
                    data.alter = true
                    if (data.bank_agency) {
                      data.bank_agency = String(data.bank_agency).replace(
                        /\D/g,
                        ''
                      )
                    }
                    if (data.bank_account) {
                      data.bank_account = String(data.bank_account).replace(
                        /\D/g,
                        ''
                      )
                    }
                    if (data.bank_code) {
                      data.bank_name = banks.filter(
                        (e) => e.bank_code === data.bank_code
                      )[0].bank_info
                    } else {
                      data.bank_name = ''
                    }
                    setAccountData(data)
                  }}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={() => {
                  clearData()
                  onClose()
                }}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                colorScheme={'green'}
                disabled={readonly}
                mr='20px'
                onClick={() => {
                  if (validForm()) {
                    !account?.id && !account?.uuid ? addBank() : editBank()
                    onClose()
                  }
                }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      {BankForm()}
      <Box m={4}>
        <Button
          onClick={onOpen}
          variant='outline'
          disabled={readonly}
          colorScheme={'primary'}
        >
          Adicionar
        </Button>
        <Col
          mt={1}
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
          minHeight={`250px`}
          backgroundColor={'white'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={accountData ?? []}
            dense={true}
            striped={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={`250px`}
          />
        </Col>
      </Box>
    </>
  )
}

export default Reference
