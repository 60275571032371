import { Badge, Box, Heading, Text } from '@chakra-ui/react'

interface Props {
  list: any[]
}

const CheckList = ({ list }: Props): JSX.Element => {
  function valid (index: number) {
    const valid = list[index]
    return (
      <Badge colorScheme={valid ? 'green' : 'red'}>
        {valid ? 'Completo' : 'Incompleto'}
      </Badge>
    )
  }

  return (
    <Box m={4}>
      <Heading mb={2} as='h4' size='md'>
        Validação de Dados
      </Heading>
      <Text mb={2} fontSize='md'>
        Segue abaixo o checklist das informações necessario para fazer o envio a
        DASCAM, é preciso que todos os itens estejam completos para fazer o
        envio.
      </Text>
      <Text as='b' fontSize='md'>
        Dados Básicos: {valid(0)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Dados da Empresa: {valid(1)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Composição Acionária: {valid(2)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Representante Legal: {valid(3)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Dados Bancários: {valid(4)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Contatos: {valid(5)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Faturamento: {valid(6)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Documentos: {valid(7)}
      </Text>
      <br />
      <Text as='b' fontSize='md'>
        Impressão: {valid(8)}
      </Text>
    </Box>
  )
}

export default CheckList
