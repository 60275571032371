import {
  Box,
  Button,
  VStack,
  HStack,
  IconButton,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useToast,
  Text,
  AlertDialogFooter,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Center
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import { axiosEmail, axiosSupplier } from 'helpers/axiosInstances'
import { isConflicting, isUnauthorized } from 'helpers/errors'
import {
  showLoading,
  setGlobalMessage,
  clearLoading,
  setSuccessMessage
} from 'redux/actions'
import { BiUserCheck, BiUserX } from 'react-icons/bi'
import { PayerReceiverModel } from 'interfaces/payiesReceivies'
import {
  SupplierPortalModel,
  SupplierStatusEnum
} from 'interfaces/supplierPortal'

import DataTable, { TableColumn } from 'react-data-table-component'
import { AiOutlineEdit } from 'react-icons/ai'
import { Row } from 'v2/components/shared/Row'
import styled from 'styled-components'
import { validateFild } from 'helpers/validFilds'
import { convertProviderBody } from 'helpers/parseProvider'
import { apiClient } from 'v2/services/clients/apiClient'
import { Permissions } from 'interfaces/web/permission'
import { sendNotificationMail } from 'services/notificationMail'
import AutoSizer from 'react-virtualized-auto-sizer'

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`
const FilterComponent = ({ filterText, onFilter }: any) => (
  <>
    <TextField
      id='search'
      type='text'
      placeholder='Filtro por Nome'
      aria-label='Search Input'
      value={filterText}
      onChange={onFilter}
    />
  </>
)

const SupplierPortalPage: React.FC = () => {
  const { user } = useGlobals()
  const toast = useToast()
  const dispatch = useDispatch()
  const history = useHistory()
  const { hashData, redirect } = useHashData()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)

  const [suppliers, setSuppliers] = useState<SupplierPortalModel[]>([])
  const [editId, setEditId] = useState<string>('')
  const [supplierName, setSupplierName] = useState<string>('')
  const [supplierUserName, setSupplierUserName] = useState<string>('')
  const [supplierEmail, setSupplierEmail] = useState<string>('')
  const [supplierLogin, setSupplierLogin] = useState<string>('')
  const [selectedBusiness, setSelectedBusiness] = useState({})

  const [toggledClearRows, setToggleClearRows] = React.useState(false)
  const [group, setGroup] = useState<any[]>([])
  const [payersReceivers, setPayersReceivers] = useState<any[]>([])
  const [payersReceiversSelect, setPayersReceiversSelectProcess] = useState<
    any[]
  >([])
  const [payersReceiversToSend, setPayersReceiversSelectToSend] = useState<
    any[]
  >([])

  const [filterTextExternal, setFilterTextExternal] = React.useState<string>('')
  const [filterText, setFilterText] = React.useState<string>('')

  const [scrollBehavior, setScrollBehavior] = React.useState('inside')

  const [dateRequest, setDateRequest] = React.useState(
    localStorage.getItem('provider_request')
  )

  function isMoreThan24Hours () {
    const now = new Date()
    if (!dateRequest) {
      return true
    }

    const inputDate = new Date(Number(dateRequest))

    const millisecondsIn24Hours = 24 * 60 * 60 * 1000

    return now.getTime() - inputDate.getTime() > millisecondsIn24Hours
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        filterText={filterTextExternal}
        onFilter={(e: any) => {
          setFilterTextExternal(e.target.value)
        }}
      />
    )
  }, [filterTextExternal])

  const subHeaderComponentMemoInternal = React.useMemo(() => {
    return (
      <FilterComponent
        filterText={filterText}
        onFilter={(e: any) => {
          setFilterText(e.target.value)
        }}
      />
    )
  }, [filterText])

  function handleRowSelected (data: any) {
    setPayersReceiversSelectProcess(data.selectedRows)
  }

  const columnsModeOne: TableColumn<SupplierPortalModel>[] = [
    {
      name: 'Ações',
      cell: (row) => (
        <HStack m={1}>
          <Tooltip label='Editar'>
            <IconButton
              aria-label='Inativar'
              size={'sm'}
              colorScheme={'primary'}
              onClick={async () => {
                setEditProvider(row)
              }}
            >
              <AiOutlineEdit size={16} />
            </IconButton>
          </Tooltip>
          {row?.status === 'ACTIVE' ? (
            <>
              <Tooltip label='Inativar Fornecedor'>
                <IconButton
                  aria-label='Inativar'
                  size={'sm'}
                  colorScheme={'red'}
                  onClick={async () => {
                    await changeSupplierStatus(String(row.id!), 'INACTIVE')
                    getSuppliers()
                  }}
                >
                  <BiUserX size={16} />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip label='Ativar Fornecedor'>
                <IconButton
                  aria-label='Ativar'
                  size={'sm'}
                  colorScheme={'green'}
                  onClick={async () => {
                    await changeSupplierStatus(String(row.id!), 'ACTIVE')
                    getSuppliers()
                  }}
                >
                  <BiUserCheck size={16} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '90px'
    },
    {
      name: 'id',
      selector: (row) => row.id ?? '---',
      width: '100px',
      omit: true
    },
    {
      name: 'Fornecedor',
      selector: (row) => row.provider_name ?? '---',
      sortable: true
    },
    {
      name: 'E-mail',
      selector: (row) => row.provider_email ?? '---',
      sortable: true
    },
    {
      name: 'Login',
      selector: (row) => row.login ?? '---',
      sortable: true
    },
    {
      name: 'Status',
      selector: (row) => SupplierStatusEnum.format(row.status!),
      sortable: true,
      width: '90px'
    }
  ]

  const listaContas: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.provider_syscambio ?? '---',
      width: '100px',
      omit: true
    },
    {
      name: 'País',
      selector: (row) => row.country ?? '---',
      width: '58px'
    },
    {
      name: 'Fornecedor',
      selector: (row) => row.identification ?? '---',
      width: '300px'
    },
    {
      name: 'Conta',
      selector: (row) => row.account_data?.account ?? '---',
      width: '500px'
    },
    {
      name: 'Banco',
      selector: (row) => row.account_data?.bank_name ?? '---'
    }
  ]

  const listaContasToSend: TableColumn<any>[] = [
    {
      name: 'Ações',
      cell: (row) => (
        <HStack m={1}>
          <Tooltip label='Remover'>
            <IconButton
              aria-label='Remover'
              size={'sm'}
              colorScheme={'red'}
              onClick={async () => {
                setPayersReceiversSelectToSend(
                  payersReceiversToSend.filter(
                    (e) => e.account_data?.account !== row.account_data?.account
                  )
                )
                if (isEdit) {
                  const list = payersReceiversToSend.filter(
                    (e) => e.account_data?.account === row.account_data?.account
                  )
                  console.log(list)
                  for (const e of list) {
                    await removeAccount(e.provider_syscambio)
                  }
                }
              }}
            >
              <BiUserX size={16} />
            </IconButton>
          </Tooltip>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '60px'
    },
    {
      name: 'id',
      selector: (row) => row.provider_syscambio ?? '---',
      width: '100px',
      omit: true
    },
    {
      name: 'País',
      selector: (row) => row.country ?? '---',
      width: '58px'
    },
    {
      name: 'Fornecedor',
      selector: (row) => row.identification ?? '---',
      width: '300px'
    },
    {
      name: 'Conta',
      selector: (row) => row.account_data?.account ?? '---',
      width: '500px'
    },
    {
      name: 'Banco',
      selector: (row) => row.account_data?.bank_name ?? '---',
      width: '300px'
    },
    {
      name: 'Empresa',
      selector: (row) => row.company_name ?? '---',
      width: '360px'
    }
  ]

  const { isOpen, onOpen, onClose } = useDisclosure()

  async function getPayersReceivers (id_syscambio: any) {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )
      const res = await axiosSupplier.get(`/user/syscambio/${id_syscambio}`)
      if (res.status === 200) {
        const list = res.data?.map(convertProviderBody)
        setPayersReceivers(list)
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message:
              'Não foi possível realizar a busca! Verifique os filtros utilizados e tente novamente.',
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  async function allPayersReceivers () {
    try {
      let a: any[] = []
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Buscando empresas do grupo'
        })
      )

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Buscando contas do grupo'
        })
      )
      for (const iterator of group) {
        const res = await axiosSupplier.get(
          `/user/syscambio/${iterator.id_syscambio}`
        )
        if (res.status === 200) {
          const list: any[] = res.data?.map(convertProviderBody) ?? []
          // // console.log(list.length)
          a = [...a, ...list.filter((e) => e.account_data.account)]
        }
      }
      setPayersReceivers(a)
      // // console.log(a.length)
    } catch (error) {
    } finally {
      dispatch(clearLoading())
    }
  }

  const getSuppliers = useCallback(async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando Fornecedores',
          isLoading: true,
          subMessage: ''
        })
      )
      setSuppliers([])
      const res = await axiosSupplier.get(`/user/company/${user.id}`)
      // console.log(res.data)
      if (res.status === 200) {
        if (res.data.length > 0) {
          setSuppliers(res.data)
        }
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message:
              'Não foi possível realizar a busca! Verifique os filtros utilizados e tente novamente.',
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }, [hashData])

  async function getGroup () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando Grupo',
          isLoading: true,
          subMessage: ''
        })
      )
      const lista = await apiClient.customers.getCustomersByUser(
        user.company_id ?? user.id!
      )
      setGroup(lista)
    } catch (error) {
      dispatch(clearLoading())
    } finally {
    }
  }

  function clearData () {
    setSelectedBusiness({})
    setPayersReceivers([])
    setPayersReceiversSelectProcess([])
    setPayersReceiversSelectToSend([])
    setSupplierUserName('')
    setSupplierName('')
    setSupplierLogin('')
    setSupplierEmail('')
    setFilterText('')
    setEditId('')
  }

  function modalProviderCard () {
    setIsEdit(false)
    onOpen()
  }

  const changeSupplierStatus = useCallback(
    async (id: string, status: string) => {
      try {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true,
            subMessage: ''
          })
        )

        await axiosSupplier.put('/user/update', { id: id, status: status })
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: 'Não foi atualizar! Por favor tente novamente.',
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
      }
    },
    [hashData]
  )

  const required = ['provider_name', 'user_name', 'login', 'email']

  async function addAccountList () {
    try {
      dispatch(
        showLoading({
          message: 'Adicionando Conta',
          isLoading: true,
          subMessage: ''
        })
      )

      const listTemp: any[] = []

      payersReceiversSelect.forEach((data) => {
        data.origin_syscam = true
        data.is_base_acc = true
      })

      payersReceiversSelect.forEach((data) => {
        listTemp.push(
          ...payersReceivers.filter(
            (e) => e.account_data.account === data.account_data.account
          )
        )
      })

      listTemp.forEach((e) => {
        const name = group.find((data) => data.id_syscambio === e.cod_cli)
        if (name) {
          e.company_name = name.fantasy_name
        }
      })

      if (isEdit) {
        await addAccountPortal(listTemp, Number(editId))
      }

      setPayersReceiversSelectToSend([...payersReceiversToSend, ...listTemp])
    } catch (error) {
      // // console.log(error)
      dispatch(
        setGlobalMessage({
          message: 'Não foi cadastrar! Por favor tente novamente.',
          type: 'ERROR'
        })
      )
    } finally {
      dispatch(clearLoading())
      setPayersReceiversSelectProcess([])
      setFilterText('')
      setToggleClearRows(!toggledClearRows)
    }
  }

  const addSupplierPortal = useCallback(
    async (
      name: string,
      user_name: string,
      list: any,
      login: string,
      email: string
    ) => {
      try {
        if (
          validateFild(
            {
              provider_name: name,
              user_name: user_name,
              login: login,
              email: email
            },
            required
          )
        ) {
          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email)) {
            return toast({
              title: 'Validação!',
              position: 'bottom',
              description: 'E-mail inválido.',
              status: 'warning',
              duration: 3000,
              isClosable: true
            })
          }
          dispatch(
            showLoading({
              message: 'Cadastrando Fornecedor',
              isLoading: true,
              subMessage: ''
            })
          )
          const listaGroup = await apiClient.customers.getCustomersByUser(
            user.company_id ?? user.id!
          )

          const userBody = {
            company_id: user.id_syscambio ?? user.company_id,
            provider_name: name,
            company_name: user.user_name,
            user_name: user_name,
            provider_email: email,
            login: login,
            direct_id: user.id,
            create_account_in: listaGroup.map((e) => Number(e.id_syscambio))
          }

          const res = await axiosSupplier.post('/user/add', userBody)

          if (res.status === 201 && list.length !== 0) {
            showLoading({
              message: 'Vínculando Contas',
              isLoading: true,
              subMessage: ''
            })
            await addAccountPortal(list, res.data.id)
          }
          if (res.status === 201) {
            showLoading({
              message: 'Finalizando',
              isLoading: true,
              subMessage: ''
            })
            const bodyMail = {
              emails: [email],
              user: login,
              companyName: name,
              cadCompanyName: user.user_name,
              userName: user_name,
              password: res.data.password,
              isNewInSysCambio: list.length === 0
            }
            await axiosEmail.post(`/supplier-access`, bodyMail)
          }
        } else {
          return toast({
            title: 'Existem campos sem preenchimento!',
            position: 'bottom',
            description: 'Por favor, verifique os dados e tente novamente.',
            status: 'warning',
            duration: 3000,
            isClosable: true
          })
        }
        clearData()
        onClose()
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else if (isConflicting(error)) {
          dispatch(
            setGlobalMessage({
              message: 'Login já existe.',
              type: 'WARN'
            })
          )
        } else {
          dispatch(
            setGlobalMessage({
              message: 'Não foi cadastrar! Por favor tente novamente.',
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
        getSuppliers()
      }
    },
    [hashData]
  )

  const addAccountPortal = useCallback(
    async (list: PayerReceiverModel[], id: number) => {
      if (isEdit) {
        dispatch(
          showLoading({
            message: 'Adicionando Conta',
            isLoading: true,
            subMessage: ''
          })
        )
      }

      try {
        const listConvert = list.map((data: PayerReceiverModel) => {
          data = Object.assign({
            ...data,
            provider_id: id
            //company_id: hashData?.customer.id_syscambio
          })
          return data
        })
        const accountBody = {
          list: listConvert
        }

        await axiosSupplier.post('/accounts/add', accountBody)
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: 'Não foi cadastrar! Por favor tente novamente.',
              type: 'ERROR'
            })
          )
        }
      } finally {
        if (isEdit) {
          dispatch(clearLoading())
        }
      }
    },
    [hashData]
  )

  async function removeAccount (id: string) {
    try {
      dispatch(
        showLoading({
          message: 'Removendo Conta',
          isLoading: true,
          subMessage: ''
        })
      )

      const rest = await axiosSupplier.delete(`/accounts/${id}`)
      // // console.log(rest)
    } catch (error) {
      return toast({
        title: 'Erro!',
        position: 'bottom',
        description: 'Não foi possivel processar, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      dispatch(clearLoading())
    }
  }

  function setEditProvider (info: SupplierPortalModel) {
    setIsEdit(true)
    setEditId(String(info.id))
    setSupplierName(info.provider_name)
    setSupplierUserName(info.user_name)
    setSupplierEmail(info.provider_email)
    setSupplierLogin(info.login)
    setPayersReceiversSelectToSend(info.accounts ?? [])
    onOpen()
  }

  async function requestAccess () {
    try {
      dispatch(
        showLoading({
          message: 'Fazendo solicitação',
          isLoading: true,
          subMessage: ''
        })
      )
      await sendNotificationMail({
        emails: ['daniel.francisco@dascam.com.br'], //['paulo@digitaly.tech', 'daniel.francisco@dascam.com.br'],
        client: user.fantasy_name ?? '',
        action:
          'Cliente está solicitando a liberação ao modulo do Portal do Fornecedor',
        process: ''
      })

      const date = Date.now()
      localStorage.setItem('provider_request', String(date))
      setDateRequest(String(date))
      dispatch(setSuccessMessage('Solicitação realizada com sucesso!'))
    } catch (error) {
      setGlobalMessage({
        message: 'Erro ao solicitar! Por favor tente novamente.',
        type: 'ERROR'
      })
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    if (user.permission_list?.find((e) => e === Permissions.PROVIDER_ACCESS)) {
      getGroup().then((_) => {
        getSuppliers()
      })
      setIsShow(true)
    }
  }, [])

  const {
    isOpen: isOpenMultiAcc,
    onOpen: onOpenMultiAcc,
    onClose: onCloseMultiAcc
  } = useDisclosure()

  function isUniques (): boolean {
    const uniqueIds = new Set(
      payersReceiversToSend.map((obj) => obj.account_data.account)
    )
    const uniqueCount = uniqueIds.size
    return uniqueCount > 1
  }

  function AlertDialogConfirmation () {
    const cancelRef = useRef<HTMLButtonElement>(null)

    return (
      <>
        <AlertDialog
          isOpen={isOpenMultiAcc}
          leastDestructiveRef={cancelRef}
          onClose={onCloseMultiAcc}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Múltiplas Contas Selecionadas
              </AlertDialogHeader>

              <AlertDialogBody>
                Atenção, existem múltiplas contas selecionadas, caso necessario
                revise os itens selecionados ou clique em 'Salvar Mesmo Assim'.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseMultiAcc}>
                  Cancelar
                </Button>
                <Button
                  colorScheme='red'
                  onClick={async () => {
                    onCloseMultiAcc()
                    await addSupplierPortal(
                      supplierName,
                      supplierUserName,
                      payersReceiversToSend,
                      supplierLogin,
                      supplierEmail
                    )
                  }}
                  ml={3}
                >
                  Salvar Mesmo Assim
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  function ModalCad () {
    return (
      <Modal
        scrollBehavior={'inside'}
        isOpen={isOpen}
        onClose={() => {
          setSupplierName('')
          setSupplierLogin('')
          setSupplierEmail('')
          onClose()
          getSuppliers()
        }}
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent maxW='98%'>
          <ModalHeader color={'primary.600'}>Dados do Fornecedor</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontSize={'md'}>
              Esse formulário é usado para fazer o cadastro de um novo
              fornecedor ou um fornecedor já existente. No caso de um novo
              fornecedor bascar fazer o preenchimento dos campos abaixo e clicar
              em "Criar Fornecedor", caso já exista, clique em "Buscar Contas" e
              selecione as contas existentes para fazer o vínculo.
            </Text>
            <SimpleFormGenerator
              rows={[
                {
                  columns: 1,
                  fields: [
                    {
                      name: '2nd',
                      type: 'heading',
                      label: 'Cadastro Básico',
                      as: 'h5',
                      size: 'sm'
                    }
                  ]
                },
                {
                  columns: 4,
                  fields: [
                    {
                      type: 'text',
                      label: 'Nome Forncedor*',
                      name: 'name',
                      readOnly: isEdit
                    },
                    {
                      type: 'text',
                      label: 'Nome Usuário*',
                      name: 'user_name',
                      readOnly: isEdit
                    },
                    {
                      type: 'text',
                      label: 'E-mail*',
                      name: 'email',
                      readOnly: isEdit
                    },
                    {
                      type: 'text',
                      label: 'Login*',
                      name: 'login',
                      readOnly: isEdit
                    }
                  ]
                }
              ]}
              value={{
                email: supplierEmail,
                name: supplierName,
                login: supplierLogin,
                user_name: supplierUserName
              }}
              onChange={(v) => {
                if (v.email) {
                  setSupplierEmail(v.email)
                }
                if (v.login) {
                  setSupplierLogin(v.login)
                }
                if (v.name) {
                  setSupplierName(v.name)
                }
                if (v.user_name) {
                  setSupplierUserName(v.user_name)
                }
              }}
            />
            <VStack spacing={4} display={'flex'} align='start'>
              <Row justifyItems={'space-around'} alignItems={'end'}>
                <Box w={'100vh'} h={'100%'}>
                  <SimpleFormGenerator
                    rows={[
                      {
                        columns: 1,
                        fields: [
                          {
                            name: '2nd',
                            type: 'heading',
                            label: isEdit
                              ? 'Gerenciar Contas'
                              : 'Vínculo de Contas Existentes',
                            as: 'h5',
                            size: 'sm'
                          }
                        ]
                      }

                      /*{
                    columns: 2,
                    fields: [
                      createCustomerSearchPicker(
                        user.company_id ?? user.id!,
                        user.cad_type === 'AUTORIZADO'
                          ? user.permission_group_company ?? []
                          : undefined,
                        {
                          label: 'Empresa',
                          name: 'customer',
                          labelKey: 'fantasy_name'
                        }
                      ),
                      {
                        type: 'text',
                        label: 'CNPJ/CPF',
                        name: 'customer.document',
                        readOnly: true,
                        mask (data) {
                          return String(data).length === 11
                            ? formatCPF(data)
                            : formatCNPJ(data)
                        }
                      }
                    ]
                  }*/
                    ]}
                    value={selectedBusiness}
                    onChange={(v) => {
                      if (v?.customer?.id_syscambio) {
                        getPayersReceivers(v.customer.id_syscambio)
                      }
                    }}
                  />
                </Box>
              </Row>
            </VStack>

            <Row>
              <Box w={200}>
                <Button
                  isDisabled={payersReceivers.length > 0}
                  width={200}
                  marginBottom={5}
                  marginEnd={3}
                  marginLeft={2}
                  variant={'outline'}
                  color={'blue.400'}
                  colorScheme={'blue'}
                  onClick={async () => {
                    allPayersReceivers()
                  }}
                >
                  Buscar Contas
                </Button>
              </Box>
              <Box w={200} ml={3}>
                <Button
                  isDisabled={!(payersReceiversSelect.length > 0)}
                  width={200}
                  marginBottom={5}
                  marginEnd={3}
                  marginLeft={2}
                  variant={'outline'}
                  color={'green.400'}
                  colorScheme={'green'}
                  onClick={() => {
                    addAccountList()
                  }}
                >
                  + Adicionar Conta
                </Button>
              </Box>
            </Row>

            <Row>
              <Col
                mt={1}
                mr={2}
                rounded={'lg'}
                overflow={'auto'}
                border={'1px solid transparent'}
                borderColor={'primary.600'}
                w={'50%'}
                minH={`auto`}
              >
                <DataTable
                  noDataComponent=''
                  columns={listaContas}
                  data={payersReceivers
                    .filter(
                      (e) =>
                        payersReceiversToSend.find(
                          (a) =>
                            String(a.provider_syscambio) ===
                            String(e.provider_syscambio)
                        ) === undefined
                    )
                    .filter(
                      (item) =>
                        item.identification &&
                        item.identification
                          .toLowerCase()
                          .includes(filterText.toLowerCase())
                    )}
                  dense={true}
                  fixedHeader={true}
                  selectableRows={true}
                  onSelectedRowsChange={handleRowSelected}
                  subHeader
                  subHeaderComponent={
                    payersReceivers.length > 0
                      ? subHeaderComponentMemoInternal
                      : null
                  }
                  clearSelectedRows={toggledClearRows}
                  fixedHeaderScrollHeight={`35vh`}
                  striped={true}
                />
              </Col>
              <Col
                mt={1}
                rounded={'lg'}
                overflow={'auto'}
                border={'1px solid transparent'}
                borderColor={'primary.600'}
                w={'50%'}
                minH={`auto`}
              >
                <DataTable
                  noDataComponent=''
                  columns={listaContasToSend}
                  data={payersReceiversToSend}
                  dense={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`42vh`}
                  striped={true}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {!isEdit && (
              <Button
                colorScheme='primary'
                mr={3}
                onClick={async () => {
                  if (isUniques()) {
                    onOpenMultiAcc()
                  } else {
                    await addSupplierPortal(
                      supplierName,
                      supplierUserName,
                      payersReceiversToSend,
                      supplierLogin,
                      supplierEmail
                    )
                  }
                }}
              >
                {'Criar Forncedor'}
              </Button>
            )}
            <Button
              onClick={() => {
                clearData()
                onClose()
                getSuppliers()
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      {AlertDialogConfirmation()}
      {ModalCad()}
      {
        <CardPage
          title={'Cadastro de Fornecedores'}
          _headerRight={
            <HStack my={2} fontSize={'xs'}>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                onClick={() => {
                  history.push('/supplierPortalWorkflow')
                }}
              >
                Solicitação de Aprovação
              </Button>
            </HStack>
          }
          _headerBottom={
            isShow ? (
              <Col px={2} w={'100%'} mt={6}>
                <VStack spacing={4} align='end'>
                  <Box h='35px' mt={1}>
                    <Button
                      width={200}
                      marginBottom={5}
                      marginEnd={3}
                      variant={'outline'}
                      color={'green.400'}
                      colorScheme={'green'}
                      onClick={() => {
                        modalProviderCard()
                      }}
                    >
                      + Adicionar Fornecedor
                    </Button>
                    <Button
                      width={200}
                      marginBottom={5}
                      variant={'outline'}
                      color={'primary.400'}
                      colorScheme={'primary'}
                      onClick={() => {
                        getSuppliers()
                      }}
                    >
                      Buscar
                    </Button>
                  </Box>
                </VStack>
              </Col>
            ) : (
              <></>
            )
          }
        >
          {isShow ? (
            <Col m={4} overflow={'hidden'} flex={1}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <Col
                    border={'1px solid transparent'}
                    borderColor={'primary.600'}
                    rounded={'lg'}
                    h={height - 5}
                    w={width}
                    backgroundColor={'white'}
                  >
                    <DataTable
                      noDataComponent={
                        <Center flex={1} h={`${height - 10}px`}>
                          <Text fontWeight='light'>Sem dados</Text>
                        </Center>
                      }
                      columns={columnsModeOne}
                      data={suppliers.filter(
                        (item) =>
                          item.provider_name &&
                          item.provider_name
                            .toLowerCase()
                            .includes(filterTextExternal.toLowerCase())
                      )}
                      dense={true}
                      fixedHeader={true}
                      fixedHeaderScrollHeight={`${height}px`}
                      striped={true}
                      subHeader
                      subHeaderComponent={
                        suppliers.length > 0 ? subHeaderComponentMemo : null
                      }
                      customStyles={{
                        subHeader: {
                          style: {
                            maxHeight: '25px',
                            borderRadius: '7px'
                          }
                        }
                      }}
                    />
                  </Col>
                )}
              </AutoSizer>
            </Col>
          ) : (
            <>
              <Col marginTop={3} id='cont' minH={'80%'} px={2}>
                Olá, você não possui permissão para fazer o cadastro e gestão de
                seus forncedores, para obter esse acesso, clique no botão abaixo
                e aguarde um retorno de nosso time.
                <Button
                  width={240}
                  m={2}
                  disabled={isMoreThan24Hours() ? false : true}
                  variant={'outline'}
                  color={'green.400'}
                  colorScheme={'green'}
                  onClick={() => {
                    requestAccess()
                  }}
                >
                  {isMoreThan24Hours()
                    ? 'Solicitar acesso'
                    : 'Aguarde para solicitar novamente'}
                </Button>
              </Col>
            </>
          )}
        </CardPage>
      }
    </>
  )
}

export default SupplierPortalPage
