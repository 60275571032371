import React, { useEffect, useRef, useState } from 'react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { axiosWebsite } from 'helpers/axiosInstances'
import { ExchangeScheduleModel } from 'interfaces/exchange-schedule'
import './style.css'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import {
  Alert,
  AlertIcon,
  Box,
  Collapse,
  Text,
  Progress,
  useBoolean,
  HStack,
  IconButton,
  AlertDialogBody,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
  Center
} from '@chakra-ui/react'

import { getMoeda } from 'helpers/moedas'
import DataTable, {
  ExpanderComponentProps,
  TableColumn
} from 'react-data-table-component'
import { formatCNPJ, formatCPF, formatProduct } from 'helpers/formats'
import { todayString } from 'helpers/date'
import { BsCalendarCheck, BsFillXOctagonFill } from 'react-icons/bs'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { validateFild } from 'helpers/validFilds'
import _ from 'lodash'
import { Permissions } from 'interfaces/web/permission'
import { apiClient } from 'v2/services/clients/apiClient'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'

const ExchangeSchedule: React.FC = () => {
  const [isLoading, loading] = useBoolean()
  const dispatch = useDispatch()
  const toast = useToast()
  const { user, hasPermissions, isAutorizado } = useGlobals()

  const permCreation = isAutorizado
    ? !hasPermissions([Permissions.SCHEDULE_BILLING])
    : false
  const permCancel = isAutorizado
    ? hasPermissions([Permissions.SCHEDULE_CANCEL])
    : true
  let history = useHistory()
  const { hashData, redirect } = useHashData()
  const [groupList, setGroupList] = useState<any[]>([])
  const [schedules, setSchedules] = useState<ExchangeScheduleModel[]>([])
  const [scheduleSelect, setScheduleSelect] = useState<ExchangeScheduleModel>()
  const [dataInput, setDataInput] = useState<any>({})
  const [dateInitials, setDateInitials] = useState<string>()
  const [dateFinal, setDateFinal] = useState<string>()
  const [showAlert, setShowAlert] = useBoolean()
  const [idSyscambio, setSyscambio] = useState(hashData?.customer?.id_syscambio)

  const userId = user!.id!
  const [idToCancel, setIdToCancel] = useState<Number>()

  interface Props extends ExpanderComponentProps<ExchangeScheduleModel> {
    notAction?: boolean
  }

  useEffect(() => {
    const date = new Date().toISOString().split('T')[0]
    redirect({
      customer: user.permission_group_company?.includes(
        Number(user.id_syscambio)
      )
        ? {
            fantasy_name: user.fantasy_name,
            document:
              user.cad_type === 'AUTORIZADO'
                ? user.company_document
                : user.document,
            id_syscambio: user.id_syscambio
          }
        : undefined,
      start_date: date,
      end_date: date
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ExpandedComponent: React.FC<Props> = ({ data, notAction }) => {
    const columns: TableColumn<ExchangeScheduleModel>[] = [
      {
        name: 'PROCESSO',
        selector: (row) => row.process ?? '',
        sortable: true,
        width: '120px',
        omit: true
      },
      {
        name: '',
        cell: (row) => (
          <HStack m={1}>
            {permCancel && (
              <IconButton
                disabled={notAction}
                onClick={() => {
                  setIdToCancel(Number(row.id))
                  onOpenReportModal()
                }}
                aria-label='Estornar'
                title='Estornar'
                size={'sm'}
                colorScheme={'red'}
              >
                <BsFillXOctagonFill size={16} />
              </IconButton>
            )}
          </HStack>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      },
      {
        name: 'REFERÊNCIA',
        selector: (row) => row.refCli ?? '---',
        sortable: true,
        width: '190px'
      },
      {
        name: 'VALOR',
        selector: (row) => row.value ?? '',
        format: (row) =>
          Number(row.value).toLocaleString('pt-br', {
            minimumFractionDigits: 2
          }),
        right: true,
        sortable: true,
        width: '150px'
      },
      {
        name: 'PAGADOR/RECEBEDOR',
        selector: (row) => row.beneficiary ?? '',
        wrap: false,
        minWidth: '230px'
      }
    ]
    return (
      <pre>
        {
          <>
            <Text ml={2} mt={1} as='em'>
              Itens:
            </Text>
            <Col
              m={1}
              mb={6}
              rounded={'md'}
              overflow={'auto'}
              border={'1px solid transparent'}
              borderColor={'primary.600'}
            >
              <DataTable
                keyField='PROCESSO'
                noDataComponent=''
                columns={columns}
                data={data.list ?? []}
                dense={true}
                striped={true}
              />
            </Col>
          </>
        }
      </pre>
    )
  }

  const columns: TableColumn<ExchangeScheduleModel>[] = [
    {
      name: 'PROCESSO',
      selector: (row) => row.process ?? '',
      sortable: true,
      width: '120px',
      omit: true
    },
    {
      name: 'AÇÕES',
      cell: (row) => (
        <HStack m={1}>
          <IconButton
            disabled={permCreation}
            onClick={() => {
              setScheduleSelect(row)
              onOpenBilling()
            }}
            aria-label='Informar'
            title='Informar'
            size={'sm'}
            colorScheme={'green'}
          >
            <BsCalendarCheck size={16} />
          </IconButton>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    },
    {
      name: 'ID',
      selector: (row) => row.id ?? '',
      sortable: true,
      width: '90px'
    },
    {
      name: 'EMPRESA',
      selector: (row) =>
        groupList.find((e) => Number(e.id_syscambio) === Number(row.cod_cli))
          ?.fantasy_name ?? '',
      sortable: true,
      width: '300px'
    },
    {
      name: 'CNPJ',
      selector: (row) => formatCNPJ(row.doc_cli ?? ''),
      sortable: true,
      width: '160px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product ?? ''),
      sortable: true,
      width: '120px'
    },
    {
      name: 'MOEDA',
      selector: (row) => getMoeda(Number(row.currency)),
      sortable: true,
      right: true,
      width: '100px'
    },
    {
      name: 'VALOR TOTAL',
      selector: (row) => row.value ?? '',
      format: (row) =>
        Number(row.value).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      right: true,
      sortable: true,
      width: '160px'
    },
    {
      name: 'FECHAMENTO',
      selector: (row) =>
        row.closure
          ? new Date(row.closure)
              .toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      width: '130px'
    },
    {
      name: 'QTD. CONTRATOS',
      selector: (row) => _.uniqBy(row.list, 'beneficiary').length,
      sortable: true,
      width: '160px'
    },
    {
      name: 'QTD. PROCESSOS',
      selector: (row) => row.process?.split(',').length ?? '',
      sortable: true,
      width: '160px'
    }
  ]

  async function getData () {
    try {
      loading.on()
      setShowAlert.off()
      let itemsToFind

      if (groupList.length === 0) {
        const id = user.company_id ?? user.id!
        itemsToFind =
          groupList.length !== 0
            ? groupList
            : await apiClient.customers.getCustomersByUser(id)

        if (user.permission_group_company) {
          itemsToFind = itemsToFind.filter((e) =>
            user.permission_group_company?.includes(e.id_syscambio)
          )
        }
        setGroupList(itemsToFind)
      } else {
        itemsToFind = groupList
      }

      const byId = idSyscambio

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .post(
          `load-next-process-to-close`,
          {
            codCli: byId,
            list_id_syscambio: byId
              ? undefined
              : itemsToFind?.map((e) => e.id_syscambio),
            product_type: dataInput,
            initial_date: dateInitials ?? todayString(),
            end_date: dateFinal ?? todayString()
          },
          config
        )
        .then((res) => {
          setSchedules(() => res.data)
          loading.off()
        })
        .catch((e) => {
          loading.off()
        })
    } catch (error) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
      loading.off()
    }
  }

  async function sendDataCancel () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite
        .delete(`delete-process-to-close?id=${idToCancel}`, config)
        .then((res) => {
          getData()
        })
        .catch((e) => {})
    } catch (error) {
    } finally {
      onCloseReportModal()
      dispatch(clearLoading())
    }
  }

  const {
    isOpen: isOpenReportModal,
    onOpen: onOpenReportModal,
    onClose: onCloseReportModal
  } = useDisclosure()
  function AlertDialogConfirmationCancel () {
    const cancelRef = useRef<HTMLButtonElement>(null)
    return (
      <>
        <AlertDialog
          isOpen={isOpenReportModal}
          leastDestructiveRef={cancelRef}
          onClose={onCloseReportModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Cancelar Item
              </AlertDialogHeader>

              <AlertDialogBody>
                Você deseja cancelar a solicitação desse item?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseReportModal}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={sendDataCancel} ml={3}>
                  Aplicar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  useEffect(() => {
    if (!dateInitials && !dateFinal) {
      getData()
    }
  }, [])

  const {
    isOpen: isOpenBilling,
    onOpen: onOpenBilling,
    onClose: onCloseBilling
  } = useDisclosure()
  async function save () {
    try {
      if (
        validateFild(scheduleSelect, ['bank', 'rate', 'date_me', 'date_reais'])
      ) {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true,
            subMessage: ''
          })
        )
        const toSave = scheduleSelect
        if (toSave) {
          toSave.processes = scheduleSelect?.process?.split(',')
          toSave.referecies = scheduleSelect?.list?.map(
            (e) => e.refCli ?? 'Sem Ref.'
          )
          delete toSave.id
          delete toSave.created_date
        }
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
        await axiosWebsite
          .post(
            `save-billing`,
            {
              data: toSave
            },
            config
          )
          .then((_) => {
            onCloseBilling()
            getData()
          })
          .catch((e) => {
            return toast({
              title: 'Erro!',
              position: 'bottom',
              description: 'e',
              status: 'error',
              duration: 3000,
              isClosable: true
            })
          })
      } else {
        return toast({
          title: 'Existem campos sem preenchimento!',
          position: 'bottom',
          description: 'Por favor, verifique os dados e tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(clearLoading())
    }
  }
  function BillingForm () {
    return (
      <>
        <Modal size={'6xl'} isOpen={isOpenBilling} onClose={onCloseBilling}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Boletagem</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'text',
                          label: 'Produto',
                          name: 'product',
                          readOnly: true,
                          mask (data) {
                            return formatProduct(data ?? '')
                          }
                        },
                        {
                          type: 'text',
                          label: 'Moeda',
                          name: 'currency',
                          readOnly: true,
                          mask (data) {
                            return getMoeda(Number(data) ?? '')
                          }
                        },
                        {
                          type: 'currency',
                          label: 'Valor Total ME',
                          name: 'value',
                          readOnly: true
                        }
                      ]
                    }
                  ]}
                  value={scheduleSelect}
                  onChange={(_) => {}}
                />
              </Col>

              <Col m={4}>
                Por Favor, preencha todos os campos necessarios abaixo:
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'text',
                          label: 'Banco*',
                          name: 'bank'
                        },
                        {
                          type: 'rate',
                          label: 'Taxa*',
                          name: 'rate'
                        }
                      ]
                    },
                    {
                      columns: 2,
                      fields: [
                        {
                          type: 'date',
                          label: 'Data ME*',
                          name: 'date_me'
                        },
                        {
                          type: 'date',
                          label: 'Data Reais*',
                          name: 'date_reais'
                        }
                      ]
                    },
                    {
                      columns: 1,
                      fields: [
                        {
                          type: 'textarea',
                          label: 'Observação',
                          name: 'obs'
                        }
                      ]
                    }
                  ]}
                  value={scheduleSelect}
                  onChange={(v) => {
                    setScheduleSelect(v)
                  }}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={onCloseBilling}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                onClick={save}
              >
                Gerar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      {BillingForm()}
      {AlertDialogConfirmationCancel()}
      <CardPage title='Agenda de Câmbio'>
        <Col px={2} minH={'fill'} w={'100%'}>
          <SimpleFormGenerator
            rows={[
              {
                columns: 3,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Contrato',
                    name: 'contract_type',
                    options: [
                      {
                        label: () => 'A Pagar (Importação/Remessa)',
                        value: 'pagar'
                      },
                      {
                        label: () => 'A Receber (Exportação/Ingresso)',
                        value: 'receber'
                      },
                      {
                        label: () => 'Ingresso',
                        value: 'recebe_in'
                      },
                      {
                        label: () => 'Exportação',
                        value: 'recebe_ex'
                      },
                      {
                        label: () => 'Importação',
                        value: 'pagar_im'
                      },
                      {
                        label: () => 'Remessa',
                        value: 'pagar_re'
                      }
                    ]
                  },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'fantasy_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              },
              {
                columns: 4,
                fields: [
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const productTypeBySelect = {
                pagar: {
                  one: 'IMPORTACAO',
                  two: 'REMESSA'
                },
                receber: {
                  one: 'EXPORTACAO',
                  two: 'INGRESSO'
                },
                recebe_in: 'INGRESSO',
                recebe_ex: 'EXPORTACAO',
                pagar_im: 'IMPORTACAO',
                pagar_re: 'REMESSA'
              }
              const { fantasy_name, document, id_syscambio } = v.customer || {}
              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  fantasy_name,
                  document,
                  id_syscambio
                }
              }

              if (v.contract_type) {
                const type: string = v.contract_type
                nextParams.contract_type = v.contract_type
                // @ts-ignore
                setDataInput(productTypeBySelect[type || 'pagar'])
              } else {
                setDataInput({})
              }
              setSyscambio(id_syscambio ?? undefined)
              setDateInitials(v.start_date)
              setDateFinal(v.end_date)

              redirect(nextParams)
            }}
          />
          <VStack spacing={2} align='end'>
            <Box h='40px' mt={1}>
              <Button
                disabled={isLoading}
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                onClick={() => {
                  getData()
                }}
              >
                Buscar
              </Button>
            </Box>
          </VStack>
          <Col mt={0}>
            {showAlert && (
              <Alert status='warning'>
                <AlertIcon />
                Preencha todos os campos de filtro
              </Alert>
            )}
            <Collapse in={isLoading}>
              <Progress isIndeterminate colorScheme={'secondary'} h={1} />
            </Collapse>
          </Col>
          {!dateInitials && !dateFinal && (
            <Box>
              <Text as='em'>
                Os dados da tabela são referente ao dia de fechamento{' '}
                {
                  new Date(todayString())
                    .toLocaleString('pt-BR', { timeZone: 'UTC' })
                    .split(',')[0]
                }
                , e são uma representação das somatorias com base em produto,
                moeda e empresa.
              </Text>
            </Box>
          )}
        </Col>
        <Col px={2} overflow={'hidden'} flex={1}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <Col
                mt={1}
                rounded={'lg'}
                overflow={'auto'}
                border={'1px solid transparent'}
                borderColor={'primary.600'}
                h={height - 15}
                w={width}
                backgroundColor={'white'}
              >
                <DataTable
                  keyField='PROCESSO'
                  noDataComponent={
                    <Center flex={1} h={`${height - 20}px`}>
                      <Text fontWeight='light'>Sem dados</Text>
                    </Center>
                  }
                  columns={columns}
                  data={schedules}
                  dense={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`${height}px`}
                  striped={true}
                  expandableRows={true}
                  expandableRowsComponent={ExpandedComponent}
                />
              </Col>
            )}
          </AutoSizer>
        </Col>
      </CardPage>
    </>
  )
}

export default ExchangeSchedule
