import { useEffect, useRef, useState } from 'react'
import { OperationModel } from 'interfaces/operation'
import { useDispatch } from 'react-redux'
import {
  setGlobalMessage,
  clearLoading,
  showLoading,
  setSuccessMessage
} from 'redux/actions'
import { axiosWebsite } from 'helpers/axiosInstances'
import { Link, useHistory } from 'react-router-dom'
import { isUnauthorized } from 'helpers/errors'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import './style.css'

import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { createCurrencySearchPicker } from 'v2/services/app/factories/forms/createCurrencySearchPicker'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { createPayerReceiverSearchPicker } from 'v2/services/app/factories/forms/createPayerReceiverSearchPicker'
import { useGlobals } from 'hooks/helpers/useGlobals'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  IconButton,
  VStack,
  useBoolean
} from '@chakra-ui/react'
import {
  formatCNPJ,
  formatCPF,
  formatColumn,
  formatDate,
  formatProduct
} from 'helpers/formats'

import DataTable, { TableColumn } from 'react-data-table-component'
import { BiShow } from 'react-icons/bi'
import AppContext from 'hooks/helpers/useLastFilter'
import { Permissions } from 'interfaces/web/permission'
import { apiClient } from 'v2/services/clients/apiClient'
import AutoSizer from 'react-virtualized-auto-sizer'

interface Props {
  typeOperation: string
  changeRender: (value: string) => void
  changePastRender: (value: string) => void
  isReadOperation: boolean
  setIsReadOperation: (value: boolean) => void
  setOperationId: (value: number) => void
  setUserSyscambio: (value: number) => void
}

const HomePostClosing = ({ setIsReadOperation }: Props): JSX.Element => {
  const { data: currencies } = useCurrencyQuery()
  const { user, hasPermissions, isAutorizado } = useGlobals()

  const permView = isAutorizado
    ? hasPermissions([Permissions.POSCLOSE_VIEW])
    : true

  const date = new Date().toISOString().split('T')[0]
  const Default = AppContext?.data_post_closing
    ? AppContext?.data_post_closing
    : {
        initial_date: date,
        end_date: date
      }
  useEffect(() => {
    redirect(hashData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const dispatch = useDispatch()
  const history = useHistory()

  const groupList = useRef<any[]>([])
  const [showAlert, setShowAlert] = useBoolean()
  const [showAlertText, setShowAlertText] = useState<string>('')

  const [saveColumns, setSaveColumns] = useState<any[]>([])
  const [idProfile, setIdProfile] = useState<number>()

  const [operationData, setOperationData] = useState<OperationModel[]>([])
  const { hashData, redirect } = useHashData(Default)
  const [idSyscambio, setSyscambio] = useState(hashData?.customer?.id_syscambio)

  const columns: TableColumn<OperationModel>[] = [
    {
      name: 'AÇÕES',
      cell: (row) => action(row.operation_number, row.user_syscambio),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    },
    {
      name: 'Empresa',
      selector: (row) =>
        groupList.current?.find(
          (e) => Number(e.id_syscambio) === Number(row.user_syscambio)
        )?.fantasy_name ?? '',
      sortable: true,
      width: '230px',
      reorder: true
    },
    {
      name: 'Produto',
      selector: (row) => formatProduct(row.product_type ?? ''),
      width: '120px',
      reorder: true
    },
    {
      name: 'Contrato (N. Banco Central)',
      selector: (row) => row.bacen_number ?? '',
      wrap: false,
      minWidth: '110px',
      reorder: true
    },
    {
      name: 'Pag/Rec',
      selector: (row) => row.beneficiary_name ?? '',
      wrap: true,
      minWidth: '280px',
      reorder: true
    },
    {
      name: 'Pag/Rec Identificação',
      selector: (row) => row.beneficiary_identification ?? '',
      wrap: true,
      minWidth: '280px',
      reorder: true
    },
    {
      name: 'Moeda',
      selector: (row) => row.currency_iso ?? '',
      reorder: true,
      width: '80px',
      right: true
    },
    {
      name: 'Valor ME',
      selector: (row) => Number(row.operation_value ?? 0),
      format: (row) =>
        Number(row.operation_value ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) ?? '',
      sortable: true,
      reorder: true,
      width: '130px',
      right: true
    },
    {
      name: 'Taxa',
      selector: (row) =>
        Number(row.taxa ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 4
        }),
      sortable: true,
      reorder: true,
      width: '80px',
      right: true
    },
    {
      name: 'Valor Reais',
      selector: (row) => Number(row.reais_value ?? 0),
      format: (row) =>
        Number(row.reais_value ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) ?? '',
      sortable: true,
      reorder: true,
      width: '130px',
      right: true
    },
    {
      name: 'Fechamento',
      selector: (row) =>
        row.currency_closure
          ? new Date(row.currency_closure)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      reorder: true,
      width: '130px'
    },
    {
      name: 'Liquidação',
      selector: (row) =>
        row.bacen_closure
          ? new Date(row.bacen_closure)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '',
      sortable: true,
      reorder: true,
      width: '120px'
    },
    {
      name: 'Inst. Financ.',
      selector: (row) => row.instituicao ?? '',
      wrap: false,
      width: '250px',
      reorder: true
    },
    {
      name: 'IOF',
      selector: (row) =>
        Number(row.iof ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      sortable: true,
      reorder: true,
      width: '100px',
      right: true
    },
    {
      name: 'Valor IR',
      selector: (row: OperationModel) => Number(row.ir?.VlrRecolhido ?? 0),
      format: (row: OperationModel) =>
        Number(row.ir?.VlrRecolhido ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) ?? '',
      wrap: false,
      width: '130px',
      reorder: true,
      right: true
    }
  ]

  function orderKey (obj: any, keyOrder: any[]) {
    try {
      keyOrder.forEach((k) => {
        const v = obj[k]
        delete obj[k]
        obj[k] = v
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function exportOperations (params: OperationModel[]) {
    const final: any[] = []
    const listOrder: any[] = []
    if (saveColumns?.length !== 0) {
      saveColumns.forEach((element) => {
        if (element.name !== 'AÇÕES') listOrder.push(element.name)
      })
    } else {
      columns.forEach((element) => {
        if (element.name !== 'AÇÕES') listOrder.push(element.name)
      })
    }
    for (const item of params) {
      const obj = {
        Empresa:
          groupList.current.find(
            (e) => Number(e.id_syscambio) === Number(item.user_syscambio)
          )?.fantasy_name ?? '',
        Produto: item.product_type,
        'Contrato (N. Banco Central)': item.bacen_number,
        Fechamento: formatDate(item.currency_closure ?? ''),
        Liquidação: formatDate(item.bacen_closure ?? ''),
        Moeda: item.currency_iso,
        'Valor ME': item.operation_value,
        Taxa: item.taxa,
        'Pag/Rec': item.beneficiary_name,
        'Pag/Rec Identificação': item.beneficiary_identification,
        'Valor Reais': item.reais_value,
        'Inst. Financ.': item.instituicao,
        IOF: item.iof,
        'Valor IR': item.ir?.VlrRecolhido ?? 0
      }
      orderKey(obj, listOrder)
      final.push(obj)
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(final)
    formatColumn(
      ws,
      listOrder.findIndex((p) => p === 'Valor ME'),
      '#,##0.00'
    )
    formatColumn(
      ws,
      listOrder.findIndex((p) => p === 'Valor Reais'),
      '#,##0.00'
    )
    formatColumn(
      ws,
      listOrder.findIndex((p) => p === 'Valor IR'),
      '#,##0.00'
    )
    formatColumn(
      ws,
      listOrder.findIndex((p) => p === 'IOF'),
      '#,##0.00'
    )
    formatColumn(
      ws,
      listOrder.findIndex((p) => p === 'Taxa'),
      '#,##0.0000'
    )

    let objectMaxLength: any[] = []
    for (let i = 0; i < final.length; i++) {
      let value: any = Object.values(final[i])
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == 'number') {
          objectMaxLength[j] = 15
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j]?.length
              ? objectMaxLength[j]
              : value[j]?.length >= 15
              ? value[j]?.length + 1
              : 15
        }
      }
    }
    const wscols = [
      { width: Number(objectMaxLength[0]) },
      { width: Number(objectMaxLength[1]) },
      { width: Number(objectMaxLength[2]) },
      { width: Number(objectMaxLength[3]) },
      { width: Number(objectMaxLength[4]) },
      { width: Number(objectMaxLength[5]) },
      { width: Number(objectMaxLength[6]) },
      { width: Number(objectMaxLength[7]) },
      { width: Number(objectMaxLength[8]) },
      { width: Number(objectMaxLength[9]) },
      { width: Number(objectMaxLength[10]) },
      { width: Number(objectMaxLength[11]) },
      { width: Number(objectMaxLength[12]) },
      { width: Number(objectMaxLength[13]) },
    ]
    ws['!cols'] = wscols
    ws['!autofilter'] = { ref: 'A1:M1' }
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'PosFechamento-DascamDirect' + fileExtension)
  }

  const handleFilter = async () => {
    try {
      setShowAlert.off()
      setShowAlertText('')

      if (!hashData.initial_date && !hashData.operation_number) {
        setShowAlert.on()
        return setShowAlertText('Selecione uma Data Inicial')
      }
      if (!hashData.end_date && !hashData.operation_number) {
        setShowAlert.on()
        return setShowAlertText('Selecione uma Data Final')
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      //   },
      // };

      let itemsToFind

      if (groupList.current.length === 0) {
        const id = user.company_id ?? user.id!
        itemsToFind =
          groupList.current.length !== 0
            ? groupList.current
            : await apiClient.customers.getCustomersByUser(id)

        if (user.permission_group_company) {
          itemsToFind = itemsToFind.filter((e) =>
            user.permission_group_company?.includes(e.id_syscambio)
          )
        }
        groupList.current = itemsToFind
      } else {
        itemsToFind = groupList.current
      }

      const byId = idSyscambio

      const body = {
        id_syscambio: byId,
        list_document: byId ? undefined : itemsToFind?.map((e) => e.document),
        document: hashData.customer
          ? hashData.customer.document
          : user?.document,
        initial_date: hashData.initial_date,
        end_date: hashData.end_date
      }

      if (hashData.operation_number) {
        Object.assign(body, { operation_number: hashData.operation_number })
      }
      if (hashData.currency_iso) {
        Object.assign(body, { currency_iso: hashData.currency_iso.CodigoISO })
      }
      if (hashData.payer_receiver) {
        Object.assign(body, {
          beneficiary_name: hashData.payer_receiver.NomeBeneficiario
        })
      }
      if (hashData.bacen_number) {
        Object.assign(body, { bacen_number: hashData.bacen_number })
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const { data } = await axiosWebsite.post(
        `/operation/posfec/search`,
        body,
        config
      )
      setOperationData(data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const getProfiles = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Buscando perfil, aguarde!',
          isLoading: true
        })
      )
      let group: any[]
      if (groupList.current.length === 0) {
        group = await getGroup()
      } else {
        group = groupList.current
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosWebsite.get(`/exportprofiles/${user?.id}`, config)

      if (res.status === 200) {
        if (res.data?.[0]?.message) {
          setIdProfile(undefined)
          setSaveColumns([])
        } else if (res.data.post_closing !== null) {
          setIdProfile(res.data.id)
          res.data.post_closing.forEach(function (v: any) {
            delete v.id
          })
          res.data.post_closing.forEach(function (v: any) {
            switch (String(v.name)) {
              case 'AÇÕES':
                Object.assign(v, {
                  cell: (row: OperationModel) =>
                    action(row.operation_number, row.user_syscambio)
                })
                break
              case 'Empresa':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    group.find(
                      (e) => Number(e.id_syscambio) === Number(row.client_code)
                    )?.fantasy_name ?? '---'
                })
                break
              case 'Produto':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    formatProduct(row.product_type ?? '')
                })
                break
              case 'Contrato (N. Banco Central)':
                Object.assign(v, {
                  selector: (row: OperationModel) => row.bacen_number ?? ''
                })
                break
              case 'Pag/Rec':
                Object.assign(v, {
                  selector: (row: OperationModel) => row.beneficiary_name ?? ''
                })
                break
                case 'Pag/Rec Pag/Rec Identificação':
                    Object.assign(v, {
                      selector: (row: OperationModel) => row.beneficiary_identification ?? ''
                    })
                    break
              case 'Moeda':
                Object.assign(v, {
                  selector: (row: OperationModel) => row.currency_iso ?? '',
                  right: true
                })
                break
              case 'Valor ME':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    Number(row.operation_value ?? 0),
                  format: (row: OperationModel) =>
                    Number(row.operation_value ?? 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2
                    }) ?? '',
                  right: true
                })
                break
              case 'Valor Reais':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    Number(row.reais_value ?? 0),
                  format: (row: OperationModel) =>
                    Number(row.reais_value ?? 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2
                    }) ?? '',
                  right: true
                })
                break
              case 'Taxa':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    Number(row.taxa ?? 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 4
                    }),
                  right: true
                })
                break
              case 'IOF':
                Object.assign(v, {
                  selector: (row: OperationModel) => Number(row.iof ?? 0),
                  format: (row: OperationModel) =>
                    Number(row.iof ?? 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2
                    }) ?? '',
                  right: true
                })
                break
              case 'Valor IR':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    Number(row.ir?.VlrRecolhido ?? 0),
                  format: (row: OperationModel) =>
                    Number(row.ir?.VlrRecolhido ?? 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) ?? '',
                  right: true
                })
                break
              case 'Fechamento':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    row.currency_closure
                      ? new Date(row.currency_closure)
                          ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
                          .split(',')[0]
                      : ''
                })
                break
              case 'Liquidação':
                Object.assign(v, {
                  selector: (row: OperationModel) =>
                    row.bacen_closure
                      ? new Date(row.bacen_closure)
                          ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
                          .split(',')[0]
                      : ''
                })
                break
              case 'Inst. Financ.':
                Object.assign(v, {
                  selector: (row: OperationModel) => row.instituicao ?? ''
                })
                break
              default:
                break
            }
          })
          setSaveColumns(res.data.post_closing)
        } else {
          setIdProfile(res.data.id)
          setSaveColumns([])
        }
      } else {
        setIdProfile(undefined)
        setSaveColumns([])
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
      handleFilter()
    }
  }

  const saveProfiles = async (profile: any[]) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Gravando perfil, aguarde!',
          isLoading: true
        })
      )

      const body = {
        id: idProfile ? idProfile : undefined,
        user_id: user?.id,
        post_closing: profile
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosWebsite.post(`/exportprofiles`, body, config)
      dispatch(setSuccessMessage('Salvo com sucesso!'))
      if (res) {
        getProfiles()
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  function onColumnOrderChange (data: any[]) {
    setSaveColumns(data)
  }

  function action (operation_number: any, user_syscambio: any) {
    return (
      <HStack m={1}>
        {permView && (
          <Link
            to={`/operation/show/${operation_number}/${user_syscambio}/3`}
            title='Visualizar'
          >
            <IconButton
              aria-label='Visualizar'
              size={'sm'}
              colorScheme={'blue'}
            >
              <BiShow size={16} />
            </IconButton>
          </Link>
        )}
      </HStack>
    )
  }

  const cleanFilter = () => {
    redirect({})
    setOperationData([])
  }

  async function getGroup () {
    if (groupList.current.length === 0) {
      const id = user.company_id ?? user.id!
      let itemsToFind =
        groupList.current.length !== 0
          ? groupList.current
          : await apiClient.customers.getCustomersByUser(id)

      if (user.permission_group_company) {
        itemsToFind = itemsToFind.filter((e) =>
          user.permission_group_company?.includes(e.id_syscambio)
        )
      }
      groupList.current = itemsToFind
      return itemsToFind
    } else {
      return groupList.current
    }
  }

  useEffect(() => {
    setIsReadOperation(false)

    getProfiles()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Col px={2} minH={'fill'} w={'100%'}>
        <SimpleFormGenerator
          rows={[
            {
              columns: 3,
              fields: [
                createCustomerSearchPicker(
                  user.company_id ?? user.id!,
                  user.cad_type === 'AUTORIZADO'
                    ? user.permission_group_company ?? []
                    : undefined,
                  {
                    label: 'Empresa',
                    name: 'customer',
                    labelKey: 'fantasy_name'
                  }
                ),
                {
                  type: 'text',
                  label: 'CNPJ/CPF',
                  name: 'customer.document',
                  readOnly: true,
                  mask (data) {
                    return String(data).length === 11
                      ? formatCPF(data)
                      : formatCNPJ(data)
                  }
                },
                {
                  type: 'text',
                  label: 'Contrato (N. Banco Central)',
                  name: 'bacen_number'
                }
              ]
            },
            {
              columns: 4,
              fields: [
                {
                  type: 'date',
                  label: 'De',
                  name: 'initial_date'
                },
                {
                  type: 'date',
                  label: 'Até',
                  name: 'end_date'
                },
                createCurrencySearchPicker(
                  {
                    label: 'Moeda',
                    name: 'currency_iso',
                    labelKey: 'Nome'
                  },
                  currencies
                ),
                createPayerReceiverSearchPicker(
                  hashData?.customer?.id_syscambio,
                  {
                    readOnly: !hashData?.customer?.id_syscambio,
                    label: 'Pagador/Recebedor',
                    name: 'payer_receiver',
                    labelKey: 'NomeBeneficiario'
                  }
                )
              ]
            }
          ]}
          value={hashData}
          onChange={(v) => {
            const { fantasy_name, document, id_syscambio } = v.customer || {}
            const { CodPagReg, NomeBeneficiario } = v.payer_receiver || {}

            const nextParams: any = { ...v }
            // setSyscambio(id_syscambio)
            if (v.customer) {
              nextParams.customer = {
                fantasy_name,
                document,
                id_syscambio
              }
            }

            if (v.payer_receiver) {
              nextParams.payer_receiver = { CodPagReg, NomeBeneficiario }
            }

            if (v.currency_code) {
              nextParams.currency_code = { ...v.currency_code }
            }
            setSyscambio(id_syscambio ?? undefined)
            redirect(nextParams)
          }}
        />
        {showAlert && (
          <Alert status='warning'>
            <AlertIcon />
            {showAlertText}
          </Alert>
        )}
        <VStack spacing={1} align='end'>
          <Box h='40px' mt={1}>
            <Button
              disabled={operationData.length === 0}
              variant={'ghost'}
              color={'primary.400'}
              colorScheme={'primary'}
              mr='20px'
              onClick={() => {
                saveProfiles(saveColumns)
              }}
            >
              Salvar Ordenação (Tabela)
            </Button>
            <Button
              disabled={operationData.length === 0}
              variant={'ghost'}
              color={'green.400'}
              colorScheme={'green'}
              mr='20px'
              onClick={() => {
                exportOperations(operationData)
              }}
            >
              Exportação Excel
            </Button>
            <>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='10px'
                onClick={() => {
                  cleanFilter()
                }}
              >
                Limpar Filtros
              </Button>
              <Button
                variant={'outline'}
                color={'primary.400'}
                colorScheme={'primary'}
                onClick={() => {
                  handleFilter()
                  AppContext.data_post_closing = hashData
                }}
              >
                Buscar
              </Button>
            </>
          </Box>
        </VStack>
      </Col>
      <Col m={2} overflow={'hidden'} flex={1}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <Col
              border={'1px solid transparent'}
              borderColor={'primary.600'}
              rounded={'lg'}
              h={height - 2}
              w={width}
              backgroundColor={'white'}
            >
              <DataTable
                noDataComponent=''
                columns={saveColumns?.length === 0 ? columns : saveColumns}
                data={operationData}
                dense={true}
                fixedHeader={true}
                fixedHeaderScrollHeight={`${height}px`}
                striped={true}
                onColumnOrderChange={onColumnOrderChange}
              />
            </Col>
          )}
        </AutoSizer>
      </Col>
    </>
  )
}

export default HomePostClosing
