import { Box, Button, useToast } from '@chakra-ui/react'
import { axiosAuth } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { ArchiveModel } from 'interfaces/user-archives'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearLoading, showLoading } from 'redux/actions'
import { FileProp } from 'v2/components/forms/documents/contracts'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  files: ArchiveModel
  changeFilesData: (files: ArchiveModel) => void
  readonly: boolean
}

interface FormFiles {
  attorney_letter?: FileProp[]
  rg_cpf_doc?: FileProp[]
  residence_proof?: FileProp[]
  service_contract?: FileProp[]
  income_tax?: FileProp[]
  constitutive_act?: FileProp[]
  board_election?: FileProp[]
  business_residence?: FileProp[]
  monthly_billing?: FileProp[]
  nda_doc?: FileProp[]
  novoex_doc?: FileProp[]
  additional_documents?: FileProp[]
  identification_beneficiaries?: FileProp[]
}

const Documents = ({
  files,
  changeFilesData,
  readonly
}: Props): JSX.Element => {
  const [filesForm, setFileForm] = useState<FormFiles>({})
  const history = useHistory()
  const dispatch = useDispatch()
  const toast = useToast()

  function convert (data: ArchiveModel) {
    setFileForm({
      attorney_letter: data.attorney_letter
        ? [
            {
              isBase64: data.attorney_letter?.includes('https') ? true : false,
              src: data.attorney_letter,
              mimeType: data.attorney_letter_type,
              name: 'attorney_letter'
            }
          ]
        : undefined,
      rg_cpf_doc: data.rg_cpf_doc
        ? [
            {
              isBase64: data.rg_cpf_doc?.includes('https') ? true : false,
              src: data.rg_cpf_doc,
              mimeType: data.rg_cpf_doc_type,
              name: 'rg_cpf_doc'
            }
          ]
        : undefined,
      residence_proof: data.residence_proof
        ? [
            {
              isBase64: data.residence_proof?.includes('https') ? true : false,
              src: data.residence_proof,
              mimeType: data.residence_proof_type,
              name: 'residence_proof'
            }
          ]
        : undefined,
      service_contract: data.service_contract
        ? [
            {
              isBase64: data.service_contract?.includes('https') ? true : false,
              src: data.service_contract,
              mimeType: data.service_contract_type,
              name: 'service_contract'
            }
          ]
        : undefined,
      income_tax: data.income_tax
        ? [
            {
              isBase64: data.income_tax?.includes('https') ? true : false,
              src: data.income_tax,
              mimeType: data.income_tax_type,
              name: 'income_tax'
            }
          ]
        : undefined,
      constitutive_act: data.constitutive_act
        ? [
            {
              isBase64: data.constitutive_act?.includes('https') ? true : false,
              src: data.constitutive_act,
              mimeType: data.constitutive_act_type,
              name: 'constitutive_act'
            }
          ]
        : undefined,
      board_election: data.board_election
        ? [
            {
              isBase64: data.board_election?.includes('https') ? true : false,
              src: data.board_election,
              mimeType: data.board_election_type,
              name: 'board_election'
            }
          ]
        : undefined,
      business_residence: data.business_residence
        ? [
            {
              isBase64: data.business_residence?.includes('https')
                ? true
                : false,
              src: data.business_residence,
              mimeType: data.business_residence_type,
              name: 'business_residence'
            }
          ]
        : undefined,
      monthly_billing: data.monthly_billing
        ? [
            {
              isBase64: data.monthly_billing?.includes('https') ? true : false,
              src: data.monthly_billing,
              mimeType: data.monthly_billing_type,
              name: 'monthly_billing'
            }
          ]
        : undefined,
      novoex_doc: data.novoex_doc
        ? [
            {
              isBase64: data.novoex_doc?.includes('https') ? true : false,
              src: data.novoex_doc,
              mimeType: data.novoex_doc_type,
              name: 'novoex_doc'
            }
          ]
        : undefined,
      additional_documents: data.additional_documents
        ? [
            {
              isBase64: data.additional_documents?.includes('https')
                ? true
                : false,
              src: data.additional_documents,
              mimeType: data.additional_documents_type,
              name: 'additional_documents'
            }
          ]
        : undefined,
      identification_beneficiaries: data.identification_beneficiaries
        ? [
            {
              isBase64: data.identification_beneficiaries?.includes('https')
                ? true
                : false,
              src: data.identification_beneficiaries,
              mimeType: data.identification_beneficiaries_type,
              name: 'identification_beneficiaries'
            }
          ]
        : undefined
    })
  }

  const getForm = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Buscando',
          isLoading: true,
          subMessage: 'Buscando arquivos para baixar'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/forms-type?type=JURIDICO`, config)

      if (
        data.attached_file.includes('https://') &&
        data.attached_file !== undefined &&
        data.attached_file !== null &&
        data.attached_file !== ''
      ) {
        const anchor = document.createElement('a')
        anchor.href = data.attached_file
        anchor.target = '_blank'
        anchor.rel = 'ext'

        anchor.click()

        setTimeout(() => {
          anchor.remove()
        }, 1000)
      } else {
        toast({
          title: 'Sem arquivos',
          position: 'bottom',
          description: 'Arquivos não localizados',
          status: 'warning',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        toast({
          title: 'Erro',
          position: 'bottom',
          description: 'Erro ao buscar arquivos',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    convert(files)
  }, [files])

  return (
    <Box m={4}>
      <Button variant={'solid'} colorScheme='primary' onClick={getForm}>
        Baixe aqui os Formulários
      </Button>
      <Box w={'50vw'}>
        <SimpleFormGenerator
          readOnly={readonly}
          rows={[
            {
              columns: 1,
              fields: [
                {
                  type: 'heading',
                  as: 'h1',
                  label: `Documentação necessária para cadastro`,
                  name: 'title',
                  size: 'lg'
                },
                {
                  type: 'heading',
                  as: 'h6',
                  label: `Os arquivos anexados não poderão passar de 10MB e só é possível anexar 1 arquivo, se adicionar outro em um local já anexado o arquivo será sobreposto. Caso seja necessário subir mais de um arquivo por item, os mesmos podem ser compactados em uma arquivo .zip`,
                  name: 'title',
                  size: 'sm'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Cópia da última alteração do Contrato Social e/ou Estatuto *',
                  name: 'constitutive_act'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label: 'Cópia da Ata de eleição da diretoria',
                  name: 'board_election'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label: 'Cópia da procuração vigente',
                  name: 'attorney_letter'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Cópia do Organograma societário com identificação do beneficiário final *',
                  name: 'identification_beneficiaries'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Cópia do último balanço patrimonial e faturamento médio mensal, (últimos 12 meses) (assinado pelo representante legal da empresa e contador) *',
                  name: 'monthly_billing'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Termo de autorização para consulta de registros de exportação e importação',
                  name: 'novoex_doc'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Cópia do comprovante de endereço da empresa (com emissão até 90 dias) *',
                  name: 'business_residence'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label: 'Cópia do RG e CPF dos representantes legais *',
                  name: 'rg_cpf_doc'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label:
                    'Cópia do comprovante de endereço dos representantes legais (com emissão até 90 dias) *',
                  name: 'residence_proof'
                },
                {
                  type: 'files',
                  viewMode: true,
                  label: 'Documentos Adicionais',
                  name: 'additional_documents'
                }
              ]
            }
          ]}
          value={filesForm}
          onChange={(v) => {
            const data = {
              ...files,
              alter: true,
              ...{
                attorney_letter: v?.attorney_letter?.[0]?.src,
                rg_cpf_doc: v?.rg_cpf_doc?.[0]?.src,
                residence_proof: v?.residence_proof?.[0]?.src,
                service_contract: v?.service_contract?.[0]?.src,
                income_tax: v?.income_tax?.[0]?.src,
                constitutive_act: v?.constitutive_act?.[0]?.src,
                board_election: v?.board_election?.[0]?.src,
                business_residence: v?.business_residence?.[0]?.src,
                monthly_billing: v?.monthly_billing?.[0]?.src,
                nda_doc: v?.nda_doc?.[0]?.src,
                novoex_doc: v?.novoex_doc?.[0]?.src,
                additional_documents: v?.additional_documents?.[0]?.src,
                identification_beneficiaries:
                  v?.identification_beneficiaries?.[0]?.src,
                attorney_letter_type: v?.attorney_letter?.[0]?.mimeType,
                rg_cpf_doc_type: v?.rg_cpf_doc?.[0]?.mimeType,
                residence_proof_type: v?.residence_proof?.[0]?.mimeType,
                service_contract_type: v?.service_contract?.[0]?.mimeType,
                income_tax_type: v?.income_tax?.[0]?.mimeType,
                constitutive_act_type: v?.constitutive_act?.[0]?.mimeType,
                board_election_type: v?.board_election?.[0]?.mimeType,
                business_residence_type: v?.business_residence?.[0]?.mimeType,
                monthly_billing_type: v?.monthly_billing?.[0]?.mimeType,
                nda_doc_type: v?.nda_doc?.[0]?.mimeType,
                novoex_doc_type: v?.novoex_doc?.[0]?.mimeType,
                additional_documents_type:
                  v?.additional_documents?.[0]?.mimeType,
                identification_beneficiaries_type:
                  v?.identification_beneficiaries?.[0]?.mimeType
                //file_company_registration:
                //  v?.file_company_registration?.[0]?.mimeType,
                //file_representative_registration:
                //  v?.file_representative_registration?.[0]?.mimeType,
                //file_company_registration_type:
                //  v?.file_company_registration?.[0]?.mimeType,
                //file_representative_registration_type:
                //  v?.file_representative_registration?.[0]?.mimeType
              }
            }
            changeFilesData(data)
          }}
        />
      </Box>
    </Box>
  )
}

export default Documents
