import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
  useDisclosure,
  useSteps,
  useToast
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import {
  FaCheck,
  FaExclamationTriangle,
  FaList,
  FaMapPin
} from 'react-icons/fa'
import CardPage from 'v2/components/pages/CardPage'
import BasicData from './1Basic'
import DataClient from './2DataClient'
import ShareholdingComposition from './3ShareholdingComposition'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import LegalRepresentative from './4LegalRepresentative'
import Reference from './5Reference'
import Contacts from './6Contacts'
import Documents from './7Documents'
import { useEffect, useState } from 'react'
import {
  UserModel,
  UserResponsibleEnum,
  UserStatusEnum,
  UserTypeEnum
} from 'interfaces/user'
import { useDispatch } from 'react-redux'
import { clearLoading, showLoading } from 'redux/actions'
import { axiosAuth, axiosEmail, axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { useHistory, useParams } from 'react-router-dom'
import { BankEntity, BankModel } from 'interfaces/bank'
import { BeneficiaryModel } from 'interfaces/beneficiary'
import { ArchiveModel } from 'interfaces/user-archives'
import { validateFieldV2 } from 'helpers/validFilds'
import { AlertDialogComp } from 'v2/components/shared/Alert'
import CheckList from './9Checklist'
import { AlertDialogMsgComp } from 'v2/components/shared/AlerWithMsg'
import { Permissions } from 'interfaces/web/permission'
import ToSignature from './8ToSignature'
import Invoicing from './Invoicing'

const steps = [
  { title: 'Dados Básicos' },
  { title: 'Dados da Empresa' },
  { title: 'Composição Acionária' },
  { title: 'Representante Legal' },
  { title: 'Dados Bancários' },
  { title: 'Contatos' },
  { title: 'Faturamento' },
  { title: 'Documentos' },
  { title: 'Impressão' },
  { title: 'Validar' }
]

interface PathNav {
  id: string
  email: string
}

const RegisterBase: React.FC = ({ children }) => {
  const { user, isDascam } = useGlobals()
  const { hasPermissions } = useGlobals()
  const pathNav = useParams<PathNav>()
  const dispatch = useDispatch()
  const toast = useToast()
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isVisible, onClose: onCloseAlertDascam } = useDisclosure({
    defaultIsOpen: true
  })
  const {
    isOpen: isOpenSaveDascam,
    onOpen: onOpenSaveDascam,
    onClose: onCloseSaveDascam
  } = useDisclosure()
  const {
    isOpen: isOpenApproval,
    onOpen: onOpenApproval,
    onClose: onCloseApproval
  } = useDisclosure()
  const {
    isOpen: isOpenRepproval,
    onOpen: onOpenRepproval,
    onClose: onCloseRepproval
  } = useDisclosure()
  const {
    isOpen: isOpenSendToApproval,
    onOpen: onOpenSendToApproval,
    onClose: onCloseSendToApproval
  } = useDisclosure()
  const title = isDascam ? 'Gestão do Cliente' : 'Minha Empresa'
  const [dataNotSave, setDataNotSave] = useState<boolean>(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [userData, setUserData] = useState<UserModel>({})
  const [accountBanks, setAccountBanks] = useState<BankModel[]>([])
  const [banks, setBanks] = useState<BankEntity[]>([])
  const [beneficiarys, setBeneficiarys] = useState<BeneficiaryModel[]>([])
  const [legalRepresentative, setLegalRepresentative] = useState<
    BeneficiaryModel[]
  >([])
  const [userDocumentation, setUserDocumentation] = useState<ArchiveModel>({})

  const [validStepList, setValidStepList] = useState<any[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ])

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  })

  function checkCompleteStep () {
    validStepList[0] = validateFieldV2(userData, [
      'contract_cad_type',
      'type_relationship',
      'manager_dascam',
      'purpose_relationship'
    ])

    validStepList[1] = validateFieldV2(userData, [
      'user_name',
      //'fantasy_name',
      'manager_dascam',
      'municipalregistration_ccm',
      'stateregistration',
      'website',
      'address_zipcode',
      'address_place',
      'address_number',
      'address_district',
      'address_city',
      'address_state',
      //'ddd_cellphone',
      //'contact_cellphone',
      'ddd_phone',
      'contact_phone',
      'contact_name',
      'email',
      'contact_role',
      'opening_date',
      'form_constitution',
      'origin_capital',
      'share_capital',
      'net_worth',
      'average_billing',
      'purpose_relationship',
      'entity',
      'legal_nature'
    ])

    validStepList[2] = beneficiarys.length > 0
    validStepList[3] = legalRepresentative.length > 0
    validStepList[4] = accountBanks.length > 0

    validStepList[5] = validateFieldV2(userData, [
      'file_return_type',
      'form_signature',
      'contact_emails'
    ])

    validStepList[6] = validateFieldV2(userData, [
      'invoicing_days',
      'invoicing_doc'
    ])

    validStepList[7] = validateFieldV2(userDocumentation, [
      'constitutive_act',
      //'board_election',
      //'attorney_letter',
      'identification_beneficiaries',
      'monthly_billing',
      //'novoex_doc',
      'business_residence',
      'rg_cpf_doc',
      'residence_proof'
    ])

    validStepList[8] = validateFieldV2(userDocumentation, [
      'file_company_registration',
      'file_representative_registration'
    ])

    setValidStepList(validStepList)
  }

  function checkCompletrToSend () {
    return validStepList.filter((e) => e === true).length === 9
  }

  function next () {
    if (activeStep < 9) {
      setActiveStep(activeStep + 1)
    }
  }

  function back () {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  function stepTitle () {
    switch (activeStep) {
      case 0:
        return 'Dados Básicos'
      case 1:
        return 'Dados da Empresa'
      case 2:
        return 'Composição Acionária'
      case 3:
        return 'Representante Legal'
      case 4:
        return 'Dados Bancários'
      case 5:
        return 'Contatos'
      case 6:
        return 'Faturamento'
      case 7:
        return 'Documentos'
      case 8:
        return 'Impressão'
      case 9:
        return 'Confirmação'
      default:
        break
    }
  }

  async function getUserData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const id = user.cad_type === UserTypeEnum.DASCAM ? pathNav.id : user?.id
      const { data } = await axiosAuth.get(`/users/${id}`, config)

      setUserData(data)
    } catch (error) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
      }
    } finally {
    }
  }

  async function getBankData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados bancários'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const id = user.cad_type === UserTypeEnum.DASCAM ? pathNav.id : user?.id
      const { data } = await axiosAuth.get(`/bank/${id}`, config)

      setAccountBanks(data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const getBeneficiaryData = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando Representantes'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const id = user.cad_type === UserTypeEnum.DASCAM ? pathNav.id : user?.id
      const { data } = await axiosAuth.get(`/beneficiary/${id}`, config)

      setBeneficiarys(data?.filter((e: any) => !e.is_only_legal_representative))
      setLegalRepresentative(
        data?.filter((e: any) => e.is_only_legal_representative)
      )
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
      }
    } finally {
      // dispatch(clearLoading())
    }
  }

  async function getDocuments () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando documentos'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const id = user.cad_type === UserTypeEnum.DASCAM ? pathNav.id : user?.id
      const { data } = await axiosAuth.get(`/archives/${id}`, config)

      setUserDocumentation(data)
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
      }
    }
  }

  const getBanksToCad = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando Bancos'
        })
      )
      setBanks(() => [])
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      axiosWebsite
        .get('/bancos', config)
        .then((res) => res.data)
        .then(setBanks)
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      // dispatch(clearLoading())
    }
  }

  async function loadData () {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados'
        })
      )
      await getUserData()
      await getBankData()
      await getBanksToCad()
      await getBeneficiaryData()
      await getDocuments()
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      dispatch(clearLoading())
    }
  }

  function checkWithDataNotSave () {
    try {
      let contensNotSave = false
      if (accountBanks.filter((e) => e.alter).length !== 0) {
        contensNotSave = true
      }
      if (beneficiarys.filter((e) => e.alter).length !== 0) {
        contensNotSave = true
      }
      if (legalRepresentative.filter((e) => e.alter).length !== 0) {
        contensNotSave = true
      }
      if (userData.alter === true) {
        contensNotSave = true
      }
      if (userDocumentation.alter === true) {
        contensNotSave = true
      }

      return contensNotSave
    } catch (error) {
      return false
    }
  }

  async function saveUser () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando',
          isLoading: true,
          subMessage: 'Salvando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      if (user.cad_type !== UserTypeEnum.DASCAM) {
        userData.status = 'PENDING' as UserStatusEnum
        userData.responsible_sector = 'CLIENTE' as UserResponsibleEnum
        userData.old_sector = 'CLIENTE'
      }


      const id = user.cad_type === UserTypeEnum.DASCAM ? userData.id : user?.id
      await axiosAuth.put(`/users/${id}`, userData, config)
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  async function saveBanks () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando Bancos',
          isLoading: true,
          subMessage: 'Salvando as informações de banco'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const listToAdd = accountBanks.filter((e) => e.alter && !e.id)
      const listToEdit = accountBanks.filter((e) => e.alter && e.id)

      if (listToAdd.length > 0) {
        for (const element of listToAdd) {
          const id =
            user.cad_type === UserTypeEnum.DASCAM ? userData.id : user?.id
          element.user_id = Number(id)
          await axiosAuth.post(`/bank`, element, config)
        }
      }

      if (listToEdit.length > 0) {
        for (const element of listToEdit) {
          await axiosAuth.put(`/bank/${element.id}`, element, config)
        }
      }
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  async function saveBeneficiarys () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando Cadeia Societária',
          isLoading: true,
          subMessage: 'Salvando as informações'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const listToAdd = beneficiarys.filter((e) => e.alter && !e.id)
      const listToEdit = beneficiarys.filter((e) => e.alter && e.id)

      if (listToAdd.length > 0) {
        for (const element of listToAdd) {
          const id =
            user.cad_type === UserTypeEnum.DASCAM ? userData.id : user?.id
          element.user_id = Number(id)
          await axiosAuth.post(`/beneficiary`, element, config)
        }
      }

      if (listToEdit.length > 0) {
        for (const element of listToEdit) {
          await axiosAuth.put(`/beneficiary/${element.id}`, element, config)
        }
      }
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  async function saveLegalRepresentative () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando Cadeia Societária',
          isLoading: true,
          subMessage: 'Salvando as informações'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const listToAdd = legalRepresentative.filter((e) => e.alter && !e.id)
      const listToEdit = legalRepresentative.filter((e) => e.alter && e.id)

      if (listToAdd.length > 0) {
        for (const element of listToAdd) {
          const id =
            user.cad_type === UserTypeEnum.DASCAM ? userData.id : user?.id
          element.user_id = Number(id)
          await axiosAuth.post(`/beneficiary`, element, config)
        }
      }

      if (listToEdit.length > 0) {
        for (const element of listToEdit) {
          await axiosAuth.put(`/beneficiary/${element.id}`, element, config)
        }
      }
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  async function saveFiles () {
    try {
      dispatch(
        showLoading({
          message: 'Salvando Arquivos',
          isLoading: true,
          subMessage: 'Salvando as informações'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      if (!userDocumentation.user_id) {
        const id =
          user.cad_type === UserTypeEnum.DASCAM ? userData.id : user?.id
        userDocumentation.user_id = Number(id)
      }
      await axiosAuth.post(`/archives`, userDocumentation, config)
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
      }
    }
  }

  async function saveData () {
    try {
      await saveUser()
      if (accountBanks.filter((e) => e.alter).length !== 0) {
        await saveBanks()
      }
      if (beneficiarys.filter((e) => e.alter).length !== 0) {
        await saveBeneficiarys()
      }
      if (legalRepresentative.filter((e) => e.alter).length !== 0) {
        await saveLegalRepresentative()
      }
      if (userDocumentation.alter === true) {
        await saveFiles()
      }
      await loadData()
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      if (
        user.status === UserStatusEnum.ACTIVE &&
        user.cad_type !== UserTypeEnum.DASCAM
      ) {
        history.go(0)
      }
      dispatch(clearLoading())
    }
  }

  async function sendToApproval () {
    try {
        await saveData()

      dispatch(
        showLoading({
          message: 'Enviando',
          isLoading: true,
          subMessage: 'Enviando solicitação'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      userData.status = UserStatusEnum.EVALUATION
      setUserData(userData)

      const bodyApproval = {
        status: 'EVALUATION',
        responsible_sector: 'CADASTRO',
        responsible_sector_notf: 'CADASTRO',
        user_name: userData.user_name
      }
      await axiosAuth.put(`/users/${userData?.id}`, bodyApproval, config)
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      dispatch(clearLoading())
      loadData()
    }
  }

  async function sendStatusStep (msg: string) {
    try {
      if (checkWithDataNotSave()) {
        await saveData()
      }

      dispatch(
        showLoading({
          message: 'Enviando',
          isLoading: true,
          subMessage: 'Enviando solicitação'
        })
      )
      let bodyApproval
      let isToSync = false

      switch (userData.responsible_sector) {
        case UserResponsibleEnum.CADASTRO:
          bodyApproval = {
            responsible_sector: UserResponsibleEnum.COMPLIANCE,
            responsible_sector_notf: UserResponsibleEnum.COMPLIANCE
          }
          break
        case UserResponsibleEnum.COMPLIANCE:
          bodyApproval = {
            responsible_sector: UserResponsibleEnum.DIRETORIA,
            responsible_sector_notf: UserResponsibleEnum.DIRETORIA
          }
          break
        case UserResponsibleEnum.DIRETORIA:
          isToSync = true
          bodyApproval = {
            status: UserStatusEnum.EVALUATION,
            responsible_sector: UserResponsibleEnum.DIRETORIA
          }
          break
        default:
          break
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth.put(`/users/${userData?.id}`, bodyApproval, config)

      if (isToSync === true) {
        let res = null;
        //userData.status = UserStatusEnum.ACTIVE
        //userData.responsible_sector = UserResponsibleEnum.CLIENTE
        const body = {
          user: userData,
          bank: accountBanks,
          beneficiary: [...beneficiarys, ...legalRepresentative]
        }

        if (!userData.id_syscambio) {
            res = await axiosAuth.post(`/user/approved`, body, config)
        } else {
            res = await axiosAuth.put(`/user/update`, body, config)
        }

        if (res.status === 200) {
            await axiosAuth.put(`/users/${userData?.id}`, {
                status: UserStatusEnum.ACTIVE,
                responsible_sector: UserResponsibleEnum.CLIENTE
            }, config)
            const bodyMail = {
                emails: [userData.email],
                name: userData.user_name,
                status: 'Liberado',
                sector: 'Cliente',
                text: `Cadastro Liberado! Acesse o DASCAM Direct para verificar.`
              }
              await axiosEmail.post(`/update-status`, bodyMail)
        } else {
            toast({
                title: 'Erro',
                position: 'bottom',
            description: `Erro: não foi possivel sincronizar com o sistema de cambio`,
                status: 'error',
                duration: 3000,
                isClosable: true
              })
        }


      }

      const bodyObserv = {
        user_id: userData?.id,
        text_content: msg ?? 'Liberado',
        creator_name: user?.user_name,
        creator_sector: user?.dascam_type,
        created_by_id: user?.id
      }

      await axiosAuth.post(`/observation`, bodyObserv, config)
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      history.push('/dascam/registration-management')
      dispatch(clearLoading())
    }
  }

  async function sendRepproval (msg: string) {
    try {
      dispatch(
        showLoading({
          message: 'Enviando',
          isLoading: true,
          subMessage: 'Enviando solicitação'
        })
      )
      let body = {
        status: UserStatusEnum.PENDING,
        responsible_sector: UserResponsibleEnum.CLIENTE
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth.put(`/users/${userData?.id}`, body, config)

      const bodyObserv = {
        user_id: userData?.id,
        text_content: msg ?? 'Reprovado',
        creator_name: user?.user_name,
        creator_sector: user?.dascam_type,
        created_by_id: user?.id
      }

      await axiosAuth.post(`/observation`, bodyObserv, config)

      const bodyMail = {
        emails: [userData.email],
        name: userData.user_name,
        status: 'Pendente',
        sector: 'Cliente',
        text: `Cadastro Inválido: ${msg}`
      }
      await axiosEmail.post(`/update-status`, bodyMail)
    } catch (error) {
      toast({
        title: 'Erro',
        position: 'bottom',
        description: `Erro: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      history.push('/dascam/registration-management')
      dispatch(clearLoading())
    }
  }

  function alertPermission () {
    let msg
    if (
      userData.responsible_sector === UserResponsibleEnum.DIRETORIA &&
      !hasPermissions([Permissions.DIRETORIA_EDIT])
    ) {
      msg =
        'Seu perfil não permite liberação do cadastro, necessario solicitar permissão de [DIRETORIA = ALTERAR]'
    }
    if (
      userData.responsible_sector === UserResponsibleEnum.CADASTRO &&
      !hasPermissions([Permissions.CADASTRO_EDIT])
    ) {
      msg =
        'Seu perfil não permite liberação do cadastro, necessario solicitar permissão de [CADASTRO = ALTERAR]'
    }
    if (
      userData.responsible_sector === UserResponsibleEnum.COMPLIANCE &&
      !hasPermissions([Permissions.COMPLIANCE_EDIT])
    ) {
      msg =
        'Seu perfil não permite liberação do cadastro, necessario solicitar permissão de [COMPLIANCE = ALTERAR]'
    }

    if (msg) {
      return (
        <Alert mb={2} status='warning' rounded={'lg'}>
          <AlertIcon />
          <AlertTitle>Permissão:</AlertTitle>
          <AlertDescription>{msg}</AlertDescription>
        </Alert>
      )
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (user.cad_type === UserTypeEnum.JURIDICO) {
      setReadOnly(userData.status === UserStatusEnum.EVALUATION)
    } else if (user.cad_type === UserTypeEnum.DASCAM) {
      setReadOnly(
        (userData.status !== UserStatusEnum.EVALUATION &&
          userData.responsible_sector !== UserResponsibleEnum.CADASTRO) ||
          (userData.status === UserStatusEnum.EVALUATION &&
            userData.responsible_sector !== UserResponsibleEnum.CADASTRO)
      )
    }
  }, [user, userData])

  useEffect(() => {
    setDataNotSave(checkWithDataNotSave())
    checkCompleteStep()
  }, [
    userData,
    accountBanks,
    legalRepresentative,
    beneficiarys,
    userDocumentation
  ])

  return (
    <>
      {AlertDialogComp({
        title: 'Salvar',
        msg:
          userData.status === 'ACTIVE'
            ? 'Ao realizar essa ação, seu acesso será definido como pendente e só será liberado após ser enviar para a DASCAM e obter aprovacão das alterações!'
            : 'Você confirma essa ação?',
        isOpen,
        onClose,
        action: () => {
          saveData()
        }
      })}
      {AlertDialogComp({
        title: 'Salvar (Lado DASCAM)',
        msg: 'Você confirma essa ação?',
        isOpen: isOpenSaveDascam,
        onClose: onCloseSaveDascam,
        action: () => {
          saveData()
        }
      })}
      {AlertDialogComp({
        title: 'Enviar para Análise',
        msg: 'Você confirma essa ação?',
        isOpen: isOpenSendToApproval,
        onClose: onCloseSendToApproval,
        action: () => {
          sendToApproval()
        }
      })}
      {AlertDialogMsgComp({
        title: 'Enviar',
        msg: 'Caso tenha uma observação, descreva abaixo.',
        isOpen: isOpenApproval,
        isRequiredMsg: false,
        onClose: onCloseApproval,
        action: (data) => {
          sendStatusStep(data)
        }
      })}
      {AlertDialogMsgComp({
        title: 'Reprovar',
        msg: 'Necessario colocar uma observação, descreva abaixo.',
        isRequiredMsg: true,
        isOpen: isOpenRepproval,
        onClose: onCloseRepproval,
        action: (data) => {
          sendRepproval(data)
        },
        custom: (
          <Alert mb={2} status='warning' rounded={'lg'} variant='left-accent'>
            <AlertDescription>
              <AlertTitle>Atenção!</AlertTitle>
              Essa mensagem será enviada ao cliente para fazer as correções
              necessarias.
            </AlertDescription>
          </Alert>
        )
      })}
      <CardPage
        title={`${title} - ${stepTitle()}`}
        _headerRight={
          <Flex gap={2}>
            <Spacer />
            {user.cad_type === UserTypeEnum.DASCAM && (
              <Button
                onClick={() => {
                  history.push('/dascam/registration-management')
                }}
                variant='solid'
                colorScheme={'primary'}
              >
                <FaList /> Todos os Clientes
              </Button>
            )}
            {activeStep !== 0 && (
              <Button onClick={back} variant='outline' colorScheme={'red'}>
                <MdNavigateBefore /> Voltar Etapa
              </Button>
            )}
            {dataNotSave && userData.status !== UserStatusEnum.EVALUATION && (
              <Button
                onClick={() => {
                  onOpen()
                }}
                variant='outline'
                colorScheme={'primary'}
              >
                Salvar
              </Button>
            )}
            {dataNotSave &&
              userData.status === UserStatusEnum.EVALUATION &&
              user.cad_type === UserTypeEnum.DASCAM &&
              userData.responsible_sector === UserResponsibleEnum.CADASTRO && (
                <Button
                  onClick={() => {
                    onOpenSaveDascam()
                  }}
                  variant='outline'
                  colorScheme={'primary'}
                  disabled={!hasPermissions([Permissions.CADASTRO_EDIT])}
                >
                  Salvar
                </Button>
              )}
            {activeStep === 9 &&
              userData.status === UserStatusEnum.PENDING &&
              user.cad_type !== UserTypeEnum.DASCAM && (
                <Button
                  disabled={!checkCompletrToSend()}
                  onClick={onOpenSendToApproval}
                  variant='solid'
                  colorScheme={'green'}
                >
                  Enviar Para Análise
                </Button>
              )}
            {activeStep === 9 &&
              user.cad_type === UserTypeEnum.DASCAM &&
              (userData.responsible_sector === UserResponsibleEnum.CADASTRO ||
                userData.responsible_sector ===
                  UserResponsibleEnum.COMPLIANCE ||
                userData.responsible_sector ===
                  UserResponsibleEnum.DIRETORIA) && (
                <Button
                  onClick={onOpenRepproval}
                  variant='solid'
                  colorScheme={'red'}
                >
                  Reprovar
                </Button>
              )}
            {activeStep === 9 &&
              userData.responsible_sector === UserResponsibleEnum.CADASTRO &&
              user.cad_type === UserTypeEnum.DASCAM && (
                <Button
                  onClick={onOpenApproval}
                  variant='solid'
                  colorScheme={'green'}
                  disabled={
                    !hasPermissions([Permissions.CADASTRO_EDIT]) ||
                    !checkCompletrToSend()
                  }
                >
                  Enviar Para Compliance
                </Button>
              )}
            {activeStep === 9 &&
              userData.responsible_sector === UserResponsibleEnum.COMPLIANCE &&
              user.cad_type === UserTypeEnum.DASCAM && (
                <Button
                  onClick={onOpenApproval}
                  variant='solid'
                  colorScheme={'green'}
                  disabled={!hasPermissions([Permissions.COMPLIANCE_EDIT])}
                >
                  Enviar Para Diretoria
                </Button>
              )}
            {activeStep === 9 &&
              userData.responsible_sector === UserResponsibleEnum.DIRETORIA &&
              user.cad_type === UserTypeEnum.DASCAM && (
                <Button
                  onClick={onOpenApproval}
                  variant='solid'
                  colorScheme={'green'}
                  disabled={!hasPermissions([Permissions.DIRETORIA_EDIT])}
                >
                  Aprovar e Sincronizar com SysCambio
                </Button>
              )}
            {activeStep < 8 && (
              <Button onClick={next} variant='outline' colorScheme={'green'}>
                Próximo <MdNavigateNext />
              </Button>
            )}
            {activeStep === 8 && (
              <Button onClick={next} variant='solid' colorScheme={'orange'}>
                Validar <FaCheck style={{ marginLeft: 2 }} />
              </Button>
            )}
          </Flex>
        }
      >
        <Box m={4}>
          <Flex>
            <Stepper colorScheme='primary' w={'full'} index={activeStep}>
              {steps.map((step, index) => (
                <Box maxH={'80px'} minH={'80px'} w={'full'}>
                  <Box>
                    <Step
                      key={index}
                      onClick={() => {
                        setActiveStep(index)
                      }}
                    >
                      <StepIndicator
                        sx={{
                          '[data-status=complete] &': {
                            background: 'white',
                            borderColor: '#0F4C86',
                            color: '#0F4C86',
                            border: '2px'
                          }
                        }}
                      >
                        <StepStatus
                          complete={
                            validStepList[index] ? (
                              <StepIcon color='green' />
                            ) : (
                              <FaExclamationTriangle color='red' />
                            )
                          }
                          incomplete={<StepNumber />}
                          active={<FaMapPin color='#0F4C86' />}
                        />
                      </StepIndicator>

                      <StepSeparator />
                    </Step>
                  </Box>
                  <Box alignContent={'center'}>
                    <Text fontWeight={'light'} fontSize={'sm'}>
                      {`${step.title}`}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Stepper>
          </Flex>
          {dataNotSave && (
            <Alert mb={2} status='warning' rounded={'lg'}>
              <AlertIcon />
              <AlertTitle>Atenção!</AlertTitle>
              <AlertDescription>
                Existe dados alterados e não salvos.
              </AlertDescription>
            </Alert>
          )}
          {user.status === UserStatusEnum.EVALUATION &&
            user.cad_type !== UserTypeEnum.DASCAM && (
              <Alert mb={2} status='info' colorScheme='cyan' rounded={'lg'}>
                <AlertIcon />
                <AlertTitle>Atenção!</AlertTitle>
                <AlertDescription>
                  Seu cadastro está em fase de validação com a equipe DASCAM,
                  assim que tivermos um retorno você receberá um e-mail com as
                  instruções dos proximos passos.
                </AlertDescription>
              </Alert>
            )}

          {user.status === UserStatusEnum.PENDING &&
            user.cad_type !== UserTypeEnum.DASCAM && (
              <Alert mb={2} status='info' colorScheme='cyan' rounded={'lg'}>
                <AlertIcon />
                <AlertTitle>Atenção!</AlertTitle>
                <AlertDescription>
                  Seu cadastro está pendente de validação pela DASCAM, para
                  validar vá até a última etapa e clique em "Envia para
                  Análise".
                </AlertDescription>
              </Alert>
            )}
          {userData.status === UserStatusEnum.EVALUATION &&
            user.cad_type === UserTypeEnum.DASCAM && (
              <Alert
                hidden={!isVisible}
                mb={2}
                status='info'
                colorScheme='cyan'
                rounded={'lg'}
              >
                <AlertIcon />
                <Box w={'full'}>
                  <AlertTitle>Setor atual de aprovação:</AlertTitle>
                  <AlertDescription>
                    {userData.responsible_sector === 'CADASTRO'
                      ? 'Cadastro'
                      : userData.responsible_sector === 'COMPLIANCE'
                      ? 'Compliance'
                      : 'Diretoria'}
                  </AlertDescription>
                </Box>
                <CloseButton
                  alignSelf='flex-start'
                  position='relative'
                  right={-1}
                  top={-1}
                  onClick={onCloseAlertDascam}
                />
              </Alert>
            )}

          {userData.status === UserStatusEnum.EVALUATION &&
            user.cad_type === UserTypeEnum.DASCAM &&
            alertPermission()}
        </Box>
        <Box>
          {activeStep === 0 ? (
            <BasicData
              userData={userData}
              readonly={readOnly}
              changeUserData={(value) => setUserData(value)}
            />
          ) : null}
          {activeStep === 1 ? (
            <DataClient
              userData={userData}
              readonly={readOnly}
              changeUserData={(value) => setUserData(value)}
            />
          ) : null}
          {activeStep === 2 ? (
            <ShareholdingComposition
              shareholdingData={beneficiarys}
              readonly={readOnly}
              changeShareholdingData={(value) => setBeneficiarys(value)}
            />
          ) : null}
          {activeStep === 3 ? (
            <LegalRepresentative
              representativeData={legalRepresentative}
              readonly={readOnly}
              changeRepresentativeData={(value) =>
                setLegalRepresentative(value)
              }
            />
          ) : null}
          {activeStep === 4 ? (
            <Reference
              accountData={accountBanks}
              banks={banks}
              readonly={readOnly}
              changeAccountData={(value) => setAccountBanks(value)}
            />
          ) : null}
          {activeStep === 5 ? (
            <Contacts
              userData={userData}
              readonly={readOnly}
              changeUserData={(value) => setUserData(value)}
            />
          ) : null}
          {activeStep === 6 ? (
            <Invoicing
              userData={userData}
              readonly={readOnly}
              changeUserData={(value) => setUserData(value)}
            />
          ) : null}
          {activeStep === 7 ? (
            <Documents
              files={userDocumentation}
              readonly={readOnly}
              changeFilesData={(value) => setUserDocumentation(value)}
            />
          ) : null}
          {activeStep === 8 ? (
            <ToSignature
              files={userDocumentation}
              readonly={readOnly}
              userData={userData}
              beneficiarys={[...beneficiarys, ...legalRepresentative]}
              accountData={accountBanks}
              changeFilesData={(value) => setUserDocumentation(value)}
            />
          ) : null}
          {activeStep === 9 ? <CheckList list={validStepList} /> : null}
        </Box>
      </CardPage>
    </>
  )
}

export default RegisterBase
