import { Checkbox, FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface CheckBoxFieldProps extends FieldProps<'checkbox', boolean, boolean> {}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  infos,
  value,
  onChange,
  mask,
  isReadonly,
  maxLength
}) => {
  const isUndefined = value === undefined
  const isNull = value === null
  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return false
    }

    if (isUndefined || isNull) {
      return false
    }
    return value
  }, [value, isUndefined, isNull, isReadonly])

  return (
    <FormControl
      minH={'100%'}
      //display={'flex'}
      flexDir={'column'}
      fontFamily={'heading'}
      alignContent={'start'}
      alignItems={'start'}
      //backgroundColor={'red'}
    >
      <Col pos={'relative'} role='group' mt={'auto'}>
        <Checkbox
          alignContent={'baseline'}
          alignItems={'center'}
          rounded={'sm'}
          maxLength={maxLength}
          isChecked={fieldValue}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked
            onChange?.(value)
          }}
          isDisabled={isReadonly}
          variant={'filled'}
        >
          <FormLabel fontWeight={'bold'} mb={0}>
            {infos.label}
          </FormLabel>
        </Checkbox>
      </Col>
    </FormControl>
  )
}

export default CheckBoxField
