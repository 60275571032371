import { devices } from 'helpers/devices'
// import { useEffect, useState } from "react";
import Logo2 from 'assets/Logo 2.png'
import NoImg from 'assets/NoImg.png'
import styled from 'styled-components'
// import GenericButton from "components/generic/GenericButton";
import Background from 'assets/bgRestricted.png'
import { ReactComponent as CheckIcon } from 'assets/checkIcon.svg'
import { ReactComponent as BellIcon } from 'assets/bellIcon.svg'
import { ReactComponent as DascamNavIcon1 } from 'assets/dascamNavIcon1.svg'
import { ReactComponent as DascamNavIcon2 } from 'assets/dascamNavIcon2.svg'
import { ReactComponent as DascamNavIcon3 } from 'assets/dascamNavIcon3.svg'
import { ReactComponent as DascamNavIcon4 } from 'assets/dascamNavIcon4.svg'
import { ReactComponent as DascamNavIcon5 } from 'assets/dascamNavIcon5.svg'
import { ReactComponent as NavIcon1 } from 'assets/navIcon1.svg'
import { ReactComponent as NavIcon2 } from 'assets/navIcon2.svg'
import { ReactComponent as NavIcon3 } from 'assets/navIcon3.svg'
import { ReactComponent as NavIcon4 } from 'assets/navIcon4.svg'
import { ReactComponent as NavIcon5 } from 'assets/navIcon5.svg'
// import { ReactComponent as NavIcon6 } from "assets/navIcon6.svg";
import { ReactComponent as NavIcon7 } from 'assets/navIcon7.svg'
import { ReactComponent as NavIcon8 } from 'assets/navIcon8.svg'
import { ReactComponent as NavIcon9 } from 'assets/navIcon9.svg'
import { ReactComponent as NavIcon10 } from 'assets/navIcon10.svg'
import { ReactComponent as NavIcon11 } from 'assets/navIcon11.svg'
import { ReactComponent as NavIcon12 } from 'assets/billing.svg'
import { ReactComponent as LoggoutIcon } from 'assets/loggoutIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/types'
import { clearGlobalMessage, clearUser, setGlobalMessage } from 'redux/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import ConfigUser from 'components/generic/ConfigUser'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { Permissions } from 'interfaces/web/permission'
import { useDisclosure } from 'hooks/helpers/useDisclosure'
import GenericDropdownItems, {
  DropdownItemProps
} from 'components/generic/GenericDropdownItems'
import { AiOutlineNotification } from 'react-icons/ai'
import { NotificationModel } from 'interfaces/notification'
import { axiosWebsite } from 'helpers/axiosInstances'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Divider
} from '@chakra-ui/react'

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(120px, 120px) 1fr;
  max-width: 100%;
  max-height: 100vh;
  background-image: url('${Background}');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;

  @media ${devices.phoneOnly} {
    grid-template-rows: minmax(100px, 100px) 1fr;
  }

  @media ${devices.tabletPortrait} {
    grid-template-rows: minmax(100px, 100px) 1fr;
  }

  @media ${devices.desktop} {
    grid-template-rows: minmax(100px, 100px) 1fr;
  }

  @media ${devices.bigDesktop} {
    grid-template-rows: minmax(120px, 120px) 1fr;
  }
`

const HeaderStyle = styled.header`
  width: 100%;
  padding: 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.95); */
  display: flex;
  align-items: center;
`

const Section = styled.section`
  height: auto;
  max-height: calc(100vh - 100px);
  max-width: 100%;
  /* background: rgba(255, 255, 255, 0.8); */
  overflow: auto;
`

const TemplateHeader = styled.div`
  width: 100%;
  max-width: full;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${(props) => props.theme.margins.from_sides};
  }
`

const ImgContainer = styled.div`
  height: 60px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Logo = styled.img`
  height: 100%;
  width: auto;
`

const UserContainer = styled.div`
  height: 90px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`

const BlueContainer = styled.div`
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: ${(props) => props.theme.color.primary_main};
`

const Icons = styled.div`
  width: 50px;
  height: 50px;
  padding: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;

  & svg {
    height: 100%;
    width: auto;
  }
`

const UserData = styled.div`
  padding: 0.5rem;
  height: 100%;
  width: 250px;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.color.white1};
  margin-left: 0.5rem;
`

const RelativeImg = styled.div`
  position: relative;
  cursor: pointer;

  & svg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
`

const InsertImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.color.primary_light};
  overflow: hidden;
  position: relative;
  background: ${(props) => props.theme.color.primary_main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  @media ${devices.desktop} {
    width: 40px;
    height: 40px;
  }

  @media ${devices.bigDesktop} {
    width: 50px;
    height: 50px;
  }
`

const BaseImg = styled.img`
  object-fit: fill;
  max-width: auto;
  max-height: 100%;
`

const LoggoutButton = styled(LoggoutIcon)`
  cursor: pointer;

  @media ${devices.desktop} {
    width: 25px;
    height: 25px;
  }

  @media ${devices.bigDesktop} {
    width: 35px;
    height: 35px;
  }
`

const NameUser = styled.p`
  color: ${(props) => props.theme.color.gray1};
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15ch;
  cursor: default;
`

// const Button = styled(GenericButton) <{ isDeny?: boolean }>`
//   margin: 0.2rem;
//   width: 260px;
//   color: ${(props) => props.theme.color.white1};
//   background-color: ${(props) =>
//     props.isDeny
//       ? props.theme.color.button_deny
//       : props.theme.color.button_normal};

//   &:hover {
//     background-color: ${(props) =>
//     props.isDeny
//       ? props.theme.color.button_deny_hover
//       : props.theme.color.button_normal_hover};
//   }
// `;

const Navigation = styled.div`
  width: auto;
  height: auto;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavButton = styled.div<{ isActive?: boolean; isSelected?: boolean }>`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.1rem;
  padding: 0.4rem;
  border-radius: 12px;
  border: 1px solid;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};
  border-color: ${(props) =>
    props.isSelected
      ? props.theme.color.secondary_light
      : props.isActive
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.color.primary_dark
      : props.isActive
      ? props.theme.color.primary_light
      : props.theme.color.primary_dark};

  & svg {
    width: 45px;
    height: 45px;
    & path {
      fill: ${(props) =>
        props.isSelected
          ? props.theme.color.white1
          : props.isActive
          ? props.theme.color.white1
          : props.theme.color.primary_main};
    }
  }
`

interface Props {
  children: React.ReactNode
}

const Header = ({ children }: Props): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const showNotification = useDisclosure()
  const [showConfigUser, setShowConfigUser] = useState<boolean>(false)
  const [showBot, setShowBot] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const {
    hasPermissions,
    withPermissions,
    hasSomePermissions,
    withSomePermissions
  } = useGlobals()

  const pathsDascam = [
    '/dascam/users',
    '/dascam/registration-management',
    '/dascam/review',
    '/dascam/ap-ar-review',
    '/dascam/billing-review',
    '/dascam/configuration',
    '/dascam/contract-released',
    '/dascam/exchange-schedule-mg',
    '/supplier-portal-workflow-management'
  ]
  const pathsUsers = [
    '/data/company',
    '/data/individual',
    '/data/foreign',
    '/data/users',
    '/data/pre-closing',
    '/data/processes-close',
    '/data/post-closing',
    '/data/exchange-schedule',
    '/operations',
    '/reports',
    '/monitor',
    '/supplierPortal',
    '/data/ap-ar',
    '/data/billing',
    '/registration_management'
  ]
  const checkActive = (check: string): boolean => {
    return pathname.includes(check)
  }

useEffect(() => {
    if(!process.env.REACT_APP_CHAT_URL) return;
    const script = document.createElement("script");
    script.src = (process.env.REACT_APP_CHAT_URL || "http://localhost:8088")+"/chatbot.js";
    script.async = true;
    script.id = "chatbot-script";

    document.body.appendChild(script);

    return () => {
        const chatbotWidget = document.getElementById("chatbot-widget");
        if (chatbotWidget) {
            chatbotWidget.remove();
        }
        const chatbotTab = document.getElementById("chatbot-tab");
        if (chatbotTab) {
            chatbotTab.remove();
        }
        const existingScript = document.getElementById("chatbot-script");
        if (existingScript) {
            existingScript.remove();
        }
    };
}, []);

  const logout = () => {
    dispatch(clearGlobalMessage())
    dispatch(clearUser())
    localStorage.clear()
    history.push('/')
  }

  const exit = () => {
    dispatch(
      setGlobalMessage({
        message: `Deseja realmente sair do sistema?`,
        type: 'MESSAGE',
        buttons: (
          <>
            <Button
              variant={'outline'}
              color={'green'}
              colorScheme={'green'}
              onClick={() => logout()}
            >
              Sair
            </Button>
            <Button
              mt={4}
              variant={'solid'}
              color={'white'}
              colorScheme={'red'}
              onClick={() => dispatch(clearGlobalMessage())}
            >
              Cancelar
            </Button>
          </>
        )
      })
    )
  }

  const loadNotifications = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    return axiosWebsite
      .get<NotificationModel[]>(`/notification/${4}`, config)
      .then((res) => res.data)
      .then(setNotifications)
  }

  const markAsRead = (notificationId: number | string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    }
    return axiosWebsite
      .post(
        `/notification`,
        {
          id: `${notificationId}`,
          read_by: [Number(4)]
        },
        config
      )
      .then(loadNotifications)
  }

  useEffect(() => {
    /*const refreshNotification = () => {
      loadNotifications().finally(() => {
        setTimeout(() => {
          refreshNotification()
        }, 1000 * 60 * 1) // 1 minute
      })
    }*/
    //refreshNotification()
  }, [])

  const notificationsDropdownItems = useMemo(() => {
    return notifications.map<DropdownItemProps>((notification, index) => {
      const tags = []
      const buttons = []

      !notification.read && tags.push('Novo')
      !notification.read &&
        buttons.push({
          label: 'Marcar Como Lido',
          onClick: async () => {
            return markAsRead(notification.id)
          }
        })

      return {
        title: notification.title,
        description: notification.message,
        link: notification.link,
        tags,
        icon: <AiOutlineNotification size={28} />,
        buttons
      }
    })
  }, [notifications])

  // const [scrolling, setScrolling] = useState(false);
  // const [scrollTop, setScrollTop] = useState(0);

  // const onScroll = (event: any) => {
  //   setScrollTop(event.target.documentElement.scrollTop);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  // }, []);

  // useEffect(() => {
  //   if (scrollTop > 50) {
  //     setScrolling(true);
  //   } else {
  //     setScrolling(false);
  //   }
  // }, [scrollTop]);

  return (
    <>
      <GenericDropdownItems
        isOpen={showNotification.isOpen}
        onClose={showNotification.onClose}
        items={notificationsDropdownItems}
        messageEmpty={`Você não possui nenhuma notificação`}
      />
      <Container>
        <HeaderStyle>
          <TemplateHeader>
            <ImgContainer onClick={() => history.push('/home')}>
              <Logo src={Logo2} alt='Logotipo da Dascam' />
            </ImgContainer>
            <UserContainer>
              <Card
                p={'1rem'}
                h={'100%'}
                width={'100%'}
                position={'relative'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                backdropFilter='auto'
                blur={'md'}
                backdropBlur={'sm'}
                rounded={'lg'}
                shadow={'md'}
                backgroundColor={'#0F4C8699'}
              >
                <Box display={'flex'} flexDirection={'row'}>
                  {user?.status === 'ACTIVE' ? (
                    <Navigation>
                      {user?.cad_type === 'DASCAM' ? (
                        <>
                          <NavButton
                            title='Análise de Processos'
                            isActive={hasPermissions([
                              Permissions.PROCESS_ACCESS
                            ])}
                            isSelected={checkActive(
                              pathsDascam[pathsDascam.indexOf('/dascam/review')]
                            )}
                            onClick={() =>
                              hasPermissions([Permissions.PROCESS_ACCESS])
                                ? history.push('/dascam/review')
                                : null
                            }
                          >
                            <DascamNavIcon5 />
                          </NavButton>
                          <NavButton
                            title='Análise de Processos a Fechar'
                            isActive={hasPermissions([Permissions.APAR_ACCESS])}
                            isSelected={checkActive(
                              pathsDascam[
                                pathsDascam.indexOf('/dascam/ap-ar-review')
                              ]
                            )}
                            onClick={() =>
                              hasPermissions([Permissions.APAR_ACCESS])
                                ? history.push('/dascam/ap-ar-review')
                                : null
                            }
                          >
                            <NavIcon9 />
                          </NavButton>

                          <NavButton
                            title='Agenda de Câmbio'
                            isActive={hasPermissions([
                              Permissions.BILLING_ACCESS
                            ])}
                            isSelected={checkActive(
                              pathsDascam[
                                pathsDascam.indexOf(
                                  '/dascam/exchange-schedule-mg'
                                )
                              ]
                            )}
                            onClick={() =>
                              hasPermissions([Permissions.BILLING_ACCESS])
                                ? history.push('/dascam/exchange-schedule-mg')
                                : null
                            }
                          >
                            <NavIcon3 />
                          </NavButton>

                          <NavButton
                            title='Boletagem'
                            isActive={hasPermissions([
                              Permissions.BILLING_ACCESS
                            ])}
                            isSelected={checkActive(
                              pathsDascam[
                                pathsDascam.indexOf('/dascam/billing-review')
                              ]
                            )}
                            onClick={() =>
                              hasPermissions([Permissions.BILLING_ACCESS])
                                ? history.push('/dascam/billing-review')
                                : null
                            }
                          >
                            <NavIcon12 />
                          </NavButton>

                          <NavButton
                            title='Cadastro de Clientes'
                            isActive={hasSomePermissions([
                              Permissions.CADASTRO_VIEW,
                              Permissions.COMPLIANCE_VIEW,
                              Permissions.DIRETORIA_VIEW
                            ])}
                            isSelected={checkActive(
                              pathsDascam[
                                pathsDascam.indexOf(
                                  '/dascam/registration-management'
                                )
                              ]
                            )}
                            onClick={() => {
                              if (
                                hasSomePermissions([
                                  Permissions.CADASTRO_VIEW,
                                  Permissions.COMPLIANCE_VIEW,
                                  Permissions.DIRETORIA_VIEW
                                ])
                              ) {
                                history.push('/dascam/registration-management')
                              } else {
                                setGlobalMessage({
                                  message: `Você não possui acesso`,
                                  type: 'WARN'
                                })
                              }
                            }}
                          >
                            <DascamNavIcon1 />
                          </NavButton>
                          {/* <NavButton
                          isActive={false}
                          isSelected={false}
                          onClick={() =>
                            dispatch(
                              setGlobalMessage({
                                message: `Em desenvolvimento!`,
                                type: "WARN",
                              })
                            )
                          }
                        >
                          <DascamNavIcon2 />
                        </NavButton> */}
                          {
                            <NavButton
                              title='Cadastro de Fornecedores'
                              isActive={hasPermissions([
                                Permissions.PROVIDER_DASCAM_ACCESS
                              ])}
                              isSelected={checkActive(
                                pathsDascam[
                                  pathsDascam.indexOf(
                                    '/supplier-portal-workflow-management'
                                  )
                                ]
                              )}
                              onClick={() => {
                                return hasPermissions([
                                  Permissions.PROVIDER_DASCAM_ACCESS
                                ])
                                  ? history.push(
                                      '/supplier-portal-workflow-management'
                                    )
                                  : null
                              }}
                            >
                              <NavIcon10 />
                            </NavButton>
                          }
                          <NavButton
                            title='Usuários Dascam'
                            isActive={hasPermissions([Permissions.USERS_VIEW])}
                            isSelected={checkActive(
                              pathsDascam[pathsDascam.indexOf('/dascam/users')]
                            )}
                            onClick={() => {
                              withPermissions(
                                [Permissions.USERS_VIEW],
                                (success) => {
                                  if (success) {
                                    history.push('/dascam/users')
                                  } else {
                                    dispatch(
                                      setGlobalMessage({
                                        message: `Você não possui acesso`,
                                        type: 'WARN'
                                      })
                                    )
                                  }
                                }
                              )
                            }}
                          >
                            <DascamNavIcon3 />
                          </NavButton>
                          <NavButton
                            title='Configurações Gerais'
                            isActive={hasPermissions([
                              Permissions.DOCUMENT_VIEW
                            ])}
                            isSelected={checkActive(
                              pathsDascam[
                                pathsDascam.indexOf('/dascam/configuration')
                              ]
                            )}
                            onClick={() => {
                              withPermissions(
                                [Permissions.DOCUMENT_VIEW],
                                (success) => {
                                  if (success) {
                                    history.push('/dascam/configuration')
                                  } else {
                                    dispatch(
                                      setGlobalMessage({
                                        message: `Você não possui acesso`,
                                        type: 'WARN'
                                      })
                                    )
                                  }
                                }
                              )
                            }}
                          >
                            <DascamNavIcon4 />
                          </NavButton>

                          {/* <NavButton
                          title="Liberação dos Processos para Cotação e Fechamento"
                          isActive={true}
                          isSelected={checkActive(pathsDascam[pathsDascam.indexOf('/dascam/contract-released')])}
                          onClick={() => history.push("/dascam/contract-released")
                          }
                        >
                          <NavIcon4 />
                        </NavButton> */}
                        </>
                      ) : (
                        <>
                          <NavButton
                            title='Processos'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.PROCESS_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[pathsUsers.indexOf('/operations')]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.PROCESS_ACCESS])
                                : true)
                                ? history.push('/operations')
                                : null
                            }
                          >
                            <NavIcon2 />
                          </NavButton>
                          <NavButton
                            title='Contas a Pagar/Receber'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.APAR_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[pathsUsers.indexOf('/data/ap-ar')]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.APAR_ACCESS])
                                : true)
                                ? history.push('/data/ap-ar')
                                : null
                            }
                          >
                            <NavIcon9 />
                          </NavButton>
                          <NavButton
                            title='Agenda de Câmbio'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.SCHEDULE_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[
                                pathsUsers.indexOf('/data/exchange-schedule')
                              ]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.SCHEDULE_ACCESS])
                                : true)
                                ? history.push('/data/exchange-schedule')
                                : null
                            }
                          >
                            <NavIcon3 />
                          </NavButton>
                          <NavButton
                            title='Boletagem'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.BILLING_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[pathsUsers.indexOf('/data/billing')]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.BILLING_ACCESS])
                                : true)
                                ? history.push('/data/billing')
                                : null
                            }
                          >
                            <NavIcon12 />
                          </NavButton>
                          <NavButton
                            title='Monitor de Operações'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.MONITOR])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[pathsUsers.indexOf('/monitor')]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.MONITOR])
                                : true)
                                ? history.push('/monitor')
                                : null
                            }
                          >
                            <NavIcon11 />
                          </NavButton>
                          <NavButton
                            title='Pós-fechamento Operações de Câmbio'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.POSCLOSE_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[
                                pathsUsers.indexOf('/data/post-closing')
                              ]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.POSCLOSE_ACCESS])
                                : true)
                                ? history.push('/data/post-closing')
                                : null
                            }
                          >
                            <NavIcon5 />
                          </NavButton>
                          {/* <NavButton isActive={false} isSelected={false}>
                          <NavIcon6 />
                        </NavButton> */}
                          <NavButton
                            title='Relatórios Fiscais e Consultas'
                            isActive={
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.REPORTS_ACCESS])
                                : true)
                            }
                            isSelected={checkActive(
                              pathsUsers[pathsUsers.indexOf('/reports')]
                            )}
                            onClick={() =>
                              (user.cad_type === 'AUTORIZADO' ||
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO') &&
                              (user.cad_type === 'AUTORIZADO'
                                ? hasPermissions([Permissions.REPORTS_ACCESS])
                                : true)
                                ? history.push('/reports')
                                : null
                            }
                          >
                            <NavIcon7 />
                          </NavButton>
                          {
                            // <NavButton
                            //   title='Cadastro de Fornecedores'
                            //   isActive={
                            //     user.cad_type === 'JURIDICO' ||
                            //     user.cad_type === 'FISICO' ||
                            //     user.cad_type === 'ESTRANGEIRO'
                            //   }
                            //   isSelected={checkActive(
                            //     pathsUsers[
                            //      pathsUsers.indexOf('/supplierPortal')
                            //    ]
                            //   )}
                            //   onClick={() => {
                            //     return user.cad_type === 'JURIDICO' ||
                            //       user.cad_type === 'FISICO' ||
                            //       user.cad_type === 'ESTRANGEIRO'
                            //       ? history.push('/supplierPortal')
                            //       : null
                            //   }}
                            // >
                            //   <NavIcon10 />
                            // </NavButton>
                          }
                          <Center height='50px' w={2}>
                            <Divider orientation='vertical' />
                          </Center>
                          {
                            // (user.cad_type === 'JURIDICO' ||
                            //   user.cad_type === 'FISICO' ||
                            //   user.cad_type === 'ESTRANGEIRO') && (
                            //   <NavButton
                            //     title='Cadastro da Empresa'
                            //     isActive={
                            //       user.cad_type === 'JURIDICO' ||
                            //       user.cad_type === 'FISICO' ||
                            //       user.cad_type === 'ESTRANGEIRO'
                            //     }
                            //     isSelected={
                            //       checkActive(
                            //         pathsUsers[pathsUsers.indexOf('/data/company')]
                            //       ) ||
                            //       checkActive(
                            //         pathsUsers[
                            //           pathsUsers.indexOf('/data/individual')
                            //         ]
                            //       ) ||
                            //       checkActive(
                            //         pathsUsers[pathsUsers.indexOf('/home')]
                            //       )
                            //     }
                            //     onClick={() => {
                            //       user.cad_type === 'JURIDICO'
                            //         ? history.push('/data/company')
                            //         : user.cad_type === 'FISICO'
                            //         ? history.push('/data/individual')
                            //         : user.cad_type === 'ESTRANGEIRO'
                            //         ? history.push('/data/foreign')
                            //         : history.push('/home')
                            //     }}
                            //   >
                            //     <NavIcon8 />
                            //   </NavButton>
                            // )
                          }
                          {(user.cad_type === 'JURIDICO' ||
                            user.cad_type === 'FISICO' ||
                            user.cad_type === 'ESTRANGEIRO') && (
                            <NavButton
                              title='Cadastro da Empresa'
                              isActive={
                                user.cad_type === 'JURIDICO' ||
                                user.cad_type === 'FISICO' ||
                                user.cad_type === 'ESTRANGEIRO'
                              }
                              isSelected={checkActive(
                                pathsUsers[
                                  pathsUsers.indexOf('/registration_management')
                                ]
                              )}
                              onClick={() => {
                                history.push('/registration_management')
                              }}
                            >
                              <NavIcon8 />
                            </NavButton>
                          )}
                          {
                            <NavButton
                              title='Cadastro de Usuários / Permissões'
                              isActive={
                                (user.cad_type === 'AUTORIZADO' ||
                                  user.cad_type === 'JURIDICO' ||
                                  user.cad_type === 'FISICO' ||
                                  user.cad_type === 'ESTRANGEIRO') &&
                                (user.cad_type === 'AUTORIZADO'
                                  ? hasPermissions([Permissions.USER_ACCESS])
                                  : true)
                              }
                              isSelected={checkActive(
                                pathsUsers[pathsUsers.indexOf('/data/users')]
                              )}
                              onClick={() =>
                                (user.cad_type === 'AUTORIZADO' ||
                                  user.cad_type === 'JURIDICO' ||
                                  user.cad_type === 'FISICO' ||
                                  user.cad_type === 'ESTRANGEIRO') &&
                                (user.cad_type === 'AUTORIZADO'
                                  ? hasPermissions([Permissions.USER_ACCESS])
                                  : true)
                                  ? history.push('/data/users')
                                  : null
                              }
                            >
                              <NavIcon1 />
                            </NavButton>
                          }
                        </>
                      )}
                    </Navigation>
                  ) : null}
                  <UserData>
                    {/* <RelativeImg>
                    <CheckIcon
                      onClick={() => setShowConfigUser(!showConfigUser)}
                    />
                  </RelativeImg> */}
                    <Button
                      size={'xl'}
                      borderRadius={'100%'}
                      onClick={() => setShowConfigUser(!showConfigUser)}
                    >
                      <Avatar
                        bg={'primary.200'}
                        name={user?.user_name}
                        src={user?.photo}
                        size='md'
                      />
                    </Button>
                    <NameUser title={user?.user_name}>
                      {user?.user_name}
                    </NameUser>
                    <LoggoutButton onClick={() => exit()} title='Sair!' />
                  </UserData>
                </Box>
              </Card>
            </UserContainer>
          </TemplateHeader>
        </HeaderStyle>
        <Section>{children}</Section>
      </Container>
      <ConfigUser
        id={String(user?.id)!}
        showModal={showConfigUser}
        handleModal={() => setShowConfigUser(!showConfigUser)}
        logout={logout}
      />
    </>
  )
}

export default Header
