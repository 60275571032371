import { Button } from '@chakra-ui/react'
import AttachFile from 'components/generic/AttachFileToBase64'
import GenericButton from 'components/generic/GenericButton'
import { axiosAuth } from 'helpers/axiosInstances'
import { devices } from 'helpers/devices'
import { isUnauthorized } from 'helpers/errors'
import { formatDate } from 'helpers/formats'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { FormsModel, FormsTypeEnum } from 'interfaces/forms_example'
import { Permissions } from 'interfaces/web/permission'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { GlobalState } from 'redux/types'
import styled from 'styled-components'

const Section = styled.section`
  background: transparent;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  /* overflow-y: auto; */
`

const Container = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`

const TemplateFlex = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${(props) => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${(props) => props.theme.margins.from_sides};
  }
`

const CardForm = styled.form`
  padding: 1rem 0;
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
`

const DivBlue = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.color.primary_main};
  padding: 0.5rem 1rem;
`

const Title = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.white1};
`

const DivItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: auto;
  padding: 1rem;
`

const DivText = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`

const OnlyItem = styled.div`
  padding: 1rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 450px;
  height: auto;
  margin: 1rem;
  /* overflow-y: auto; */
`

const H1 = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.primary_main};
  margin-bottom: 1rem;
  align-self: center;
`

const P = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.color.gray3};
  margin-bottom: 1rem;
  text-align: center;
`

const Date = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.color.primary_light};
`

const ButtonConfirm = styled(GenericButton)<{ isDeny?: boolean }>`
  margin: 1rem 0.2rem;
  min-width: 180px;
  color: ${(props) =>
    props.isDeny ? props.theme.color.white1 : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.secondary_light};
  }
`

const TieButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const Configuration = (): JSX.Element => {
  // const user = useSelector((state: GlobalState) => state.user);
  const { user, hasPermissions } = useGlobals()
  const dispatch = useDispatch()
  const history = useHistory()
  const [documentPf, setDocumentPf] = useState<FormsModel>({})
  const [documentPj, setDocumentPj] = useState<FormsModel>({})
  const [documentEs, setDocumentEs] = useState<FormsModel>({})

  const getDocuments = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/forms`, config)

      setDocumentPf(
        data.find((i: FormsModel) => i.form_type === FormsTypeEnum.FISICO)
      )
      setDocumentPj(
        data.find((i: FormsModel) => i.form_type === FormsTypeEnum.JURIDICO)
      )
      setDocumentEs(
        data.find((i: FormsModel) => i.form_type === FormsTypeEnum.ESTRANGEIRO)
      )
    } catch (error) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleSubmit = async (id: number, body: FormsModel) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: 'Carregando dados do usuário'
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      await axiosAuth.post(`/forms`, body, config)

      getDocuments()
    } catch (error) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  useEffect(() => {
    getDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!hasPermissions([Permissions.DOCUMENT_VIEW]) && (
        <Redirect to='/unauthorized' />
      )}
      <Section>
        <Container>
          <TemplateFlex>
            <CardForm
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              <DivBlue>
                <Title>Configurações Gerais</Title>
              </DivBlue>
              <DivText>
                <H1>Documentação de cadastro</H1>
                <P>
                  Faça um upload de um arquivo .zip ou .rar contendo a
                  documentação necessária para realizar o cadastro do Dascam
                  Direct, ao anexar outro zip o atual será substituído.
                </P>
              </DivText>
              <DivItems>
                {
                  // <OnlyItem>
                  //   <H1>Pessoa Física</H1>
                  //   <P>
                  //     Arquivo referente aos documentos necessários para cadastro
                  //     como Pessoa Física
                  //   </P>
                  //   <Date>
                  //     Atualizado em:{' '}
                  //     <b>
                  //       {documentPf
                  //         ? formatDate(String(documentPf?.created_date))
                  //         : 'Não definido'}
                  //     </b>
                  //   </Date>
                  //   <AttachFile
                  //     newId='documentPhysical'
                  //     value={documentPf?.attached_file!}
                  //     changeValue={(value, name, type) =>
                  //       setDocumentPf({
                  //         ...documentPf,
                  //         attached_name: name,
                  //         attached_file: value,
                  //         attached: type
                  //       })
                  //     }
                  //     readOnly={!hasPermissions([Permissions.DOCUMENT_CREATE])}
                  //   />
                  //   <TieButtons>
                  //     <Button
                  //       variant={'outline'}
                  //       color={'red.400'}
                  //       colorScheme={'red'}
                  //       onClick={() => getDocuments()}
                  //     >
                  //       Cancelar
                  //     </Button>
                  //     <Button
                  //       variant={'outline'}
                  //       color={'primary.400'}
                  //       colorScheme={'primary'}
                  //       onClick={() =>
                  //         handleSubmit(Number(documentPf?.id), documentPf)
                  //       }
                  //     >
                  //       Enviar Arquivo
                  //     </Button>
                  //   </TieButtons>
                  // </OnlyItem>
                }
                <OnlyItem>
                  <H1>Pessoa Jurídica</H1>
                  <P>
                    Arquivo referente aos documentos necessários para cadastro
                    como Pessoa Jurídica
                  </P>
                  <Date>
                    Atualizado em:{' '}
                    <b>
                      {documentPj
                        ? formatDate(String(documentPj?.created_date))
                        : 'Não definido'}
                    </b>
                  </Date>
                  <AttachFile
                    newId='documentLegal'
                    value={documentPj?.attached_file!}
                    changeValue={(value, name, type) =>
                      setDocumentPj({
                        ...documentPj,
                        attached_name: name,
                        attached_file: value,
                        attached: type
                      })
                    }
                    readOnly={!hasPermissions([Permissions.DOCUMENT_CREATE])}
                  />
                  <TieButtons>
                    <Button
                      variant={'outline'}
                      color={'red.400'}
                      colorScheme={'red'}
                      onClick={() => getDocuments()}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant={'outline'}
                      color={'primary.400'}
                      colorScheme={'primary'}
                      onClick={() =>
                        handleSubmit(Number(documentPj?.id), documentPj)
                      }
                    >
                      Enviar Arquivo
                    </Button>
                  </TieButtons>
                </OnlyItem>
                {/*
                  <OnlyItem>
                  <H1>Estrangeiro</H1>
                  <P>
                    Arquivo referente aos documentos necessários para cadastro
                    como Estrangeiro
                  </P>
                  <Date>
                    Atualizado em:{" "}
                    <b>
                      {documentEs
                        ? formatDate(String(documentEs?.created_date))
                        : "Não definido"}
                    </b>
                  </Date>
                  <AttachFile
                    newId="documentForeign"
                    value={documentEs?.attached_file!}
                    changeValue={(value, name, type) =>
                      setDocumentEs({
                        ...documentEs,
                        attached_name: name,
                        attached_file: value,
                        attached: type,
                      })
                    }
                    readOnly={!hasPermissions([Permissions.DOCUMENT_CREATE])}
                  />
                  <TieButtons>
                    <ButtonConfirm isDeny onClick={() => getDocuments()}>
                      Cancelar
                    </ButtonConfirm>
                    <ButtonConfirm
                      onClick={() =>
                        handleSubmit(Number(documentEs?.id), documentEs)
                      }
                    >
                      Enviar
                    </ButtonConfirm>
                  </TieButtons>
                </OnlyItem>
                  */}
              </DivItems>
            </CardForm>
          </TemplateFlex>
        </Container>
      </Section>
    </>
  )
}

export default Configuration
