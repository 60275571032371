import { Box } from '@chakra-ui/react'
import { formatCNPJ } from 'helpers/formats'
import { UserModel } from 'interfaces/user'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

interface Props {
  userData: UserModel
  changeUserData: (userData: UserModel) => void
  readonly: boolean
}

const BasicData = ({
  userData,
  changeUserData,
  readonly
}: Props): JSX.Element => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 3,
            fields: [
              {
                type: 'date',
                label: 'Data do Cadastro (DIRECT)',
                name: 'created_date',
                readOnly: true
              },
              {
                type: 'text',
                label: 'CNPJ da Empresa*',
                name: 'document',
                readOnly: true,
                mask (data) {
                  return formatCNPJ(String(data))
                }
              }
            ]
          },
          {
            columns: 3,
            fields: [
              {
                type: 'select',
                label: 'Tipo do Cadastro*',
                name: 'contract_cad_type',
                readOnly: readonly,
                options: [
                  {
                    label: () => 'ABERTURA',
                    value: 1
                  },
                  {
                    label: () => 'RENOVAÇÃO',
                    value: 2
                  }
                ]
              },
              {
                type: 'select',
                label: 'Tipo de Relacionamento*',
                name: 'type_relationship',
                readOnly: readonly,
                options: [
                  {
                    label: () => 'CÂMBIO',
                    value: 1
                  },
                  {
                    label: () => 'ASSESSORIA',
                    value: 2
                  },
                  {
                    label: () => 'AMBOS',
                    value: 3
                  }
                ]
              },
              {
                type: 'text',
                label: 'Contato Dascam*',
                name: 'manager_dascam',
                readOnly: readonly
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'textarea',
                label: 'Proposito do Relacionamento*',
                readOnly: readonly,
                name: 'purpose_relationship'
              }
            ]
          }
        ]}
        value={userData}
        onChange={(v) => {
          v.alter = true
          changeUserData(v)
        }}
      />
    </Box>
  )
}

export default BasicData
