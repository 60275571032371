import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { formatRate } from 'v2/services/helpers/formatRate'
import { FieldProps } from '../..'

interface RateFieldProps extends FieldProps<'rate', string | number, number> {}

const RateField: React.FC<RateFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly
}) => {
  const isUndefined = value === undefined
  const isNull = value === null

  const { toNumber, toString } = formatRate(infos.formatRateFraction)

  const fieldValue = useMemo(() => {
    let numberValue = Number(value)

    if (isUndefined && isReadonly) {
      numberValue = 0
    }

    if (isUndefined || isNull) {
      numberValue = 0
    }

    return toString(numberValue)
  }, [value, isUndefined, isNull, isReadonly, toString])

  return (
    <FormControl>
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0} fontFamily={'heading'}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
        <Input
          maxLength={18}
          paddingLeft={5}
          rounded={'sm'}
          type={'text'}
          value={`${fieldValue}`}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value
            onChange?.(toNumber(rawValue))
          }}
          readOnly={isReadonly}
          variant={'filled'}
          border={'1px solid #818c96'}
          backgroundColor={'white'}
          fontStyle={isUndefined ? 'italic' : 'normal'}
          color={isUndefined ? 'gray.500' : 'inherit'}
          _focus={
            isReadonly
              ? {}
              : {
                  bg: 'white'
                }
          }
          // as={CurrencyFormat}
        />
        <ButtonCopy value={fieldValue ? `${fieldValue}` : ''} />
      </Row>
    </FormControl>
  )
}

export default RateField
