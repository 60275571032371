import * as XLSX from 'xlsx'

//Telefone
export const formatPhone = (phone: string) => {
  phone = phone.replace(/\D/g, "");
  // phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d{4})(\d{4})$/, "$1-$2");
  return String(phone);
};

export const formatCelPhone = (phone: string) => {
  phone = phone.replace(/\D/g, "");
  // phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d{5})(\d{4})$/, "$1-$2");
  return String(phone);
};

//Datas
export const formatDate = (data: string) => {
  const newData = data.substring(0, 10).split("-").reverse().join("/");
  return newData;
};

export const formatDateHour = (data: string) => {
  const newData = data.substring(0, 10).split("-").reverse().join("/");
  const newHour = data.substring(11, 5);
  return newData + " - " + newHour;
};

export const formatDateHourV2 = (data: string) => {
  try {
    const date = new Date(data);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    return '----'
  }
};

export const formatDateAgo = (data: string) => {
  const newDate = new Date();
  const dateAgo = new Date(data);

  let mileSeconds = newDate.getTime() - (dateAgo.getTime() + 10800000);
  let seconds = Math.floor(mileSeconds / 1000);
  let minutes = Math.floor(mileSeconds / (1000 * 60));
  let hours = Math.floor(mileSeconds / (1000 * 60 * 60));
  let days = Math.floor(mileSeconds / (1000 * 60 * 60 * 24));

  if (Number(seconds) < 60) return seconds + " Seg";
  else if (Number(minutes) < 60) return minutes + " Min";
  else if (Number(hours) < 24) return hours + " Hrs";
  else return days + " Dias";
};

/* const maskDate = value => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1");
  }; */

//Jogo Dascam
export const formatGame = (game: number) => {
  let padded = game.toString().padStart(3, "0");
  return String(padded);
};
// mask of DI
export const formatDI = (di: string) => {
  di = di.replace(/\D/g, "");
  di = di.replace(/(\d{2})(\d)/, "$1/$2");
  di = di.replace(/(\d{7})(\d{1})$/, "$1-$2");
  return String(di);
};

//Cep
export const formatCEP = (cep: string) => {
  cep = cep.replace(/\D/g, "");
  cep = cep.replace(/(\d{5})(\d{3})$/, "$1-$2");
  return String(cep);
};

// RG
export const formatRG = (rg: string) => {
  rg = rg.replace(/\D/g, ""); //Substituí o que não é dígito por "", /g é [Global][1]
  rg = rg.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  return String(rg);
};
// CPF
export const formatCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return String(cpf);
};

export const formatCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  return String(cnpj);
};

export const formatPercentage = (value: string) => {
  value = value.replace(/\D/g, "");
  // value = value.replace(/(\d{3})$/, "$1");
  return String(value);
};

export const formatMoneyOLD = (value: string) => {
  let newValue = parseFloat(value)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return newValue;
};
export const formatMoney = (value: string) => {
  let newValue = parseFloat(value)
    .toLocaleString('pt-br', { minimumFractionDigits: 2 });
  return newValue;
};

export const formatAgency = (value: string) => {
  value = value.replace(/\D/g, "");
  value = "00000" + value;
  value = value.slice(-5);
  // value = value.replace(/(\d{1,})(\d{1})$/, "$1-$2");

  return String(value);
};

export const formatAccountBank = (value: string) => {
  value = value.replace(/\D/g, "");
  value = "00000000000" + value;
  value = value.slice(-11);
  value = value.replace(/(\d{1,})(\d{1})$/, "$1-$2");

  return String(value);
};

export const capitalizeFirstLetter = (value: string) => {
  const words = value.trim().toLocaleLowerCase().split(" ");

  for (let i = 0; i < words.length; i++) {
    if (words[i].length !== 1) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    } else {
      words[i] = words[i][0].toLowerCase() + words[i].substring(1);
    }
  }

  return words.join(" ");
};

export const formatContractCadType = (value: number) => {
  switch (value) {
    case 1:
      return 'ABERTURA';
    case 2:
      return 'Exportação';
    default:
      return '';
  }
};

export const formatTypeRelationship = (value: number) => {
  switch (value) {
    case 1:
      return 'CÂMBIO';
    case 2:
      return 'ASSESSORIA';
    case 3:
      return 'AMBOS';
    default:
      return '';
  }
};

export const formatCapital = (value: number) => {
  switch (value) {
    case 1:
      return 'Nacional';
    case 2:
      return 'Internacional';
    default:
      return '';
  }
};

export const formatProduct = (value: string) => {
  switch (value) {
    case 'IMPORTACAO':
      return 'Importação';
    case 'EXPORTACAO':
      return 'Exportação';
    case 'INGRESSO':
      return 'Ingresso';
    case 'REMESSA':
      return 'Remessa';
    default:
      return '';
  }
};

export const formatUserStatus = (value: string) => {
  console.log(value)
  switch (value) {
    case 'ACTIVE':
      return 'Ativo';
    case 'BLOCKED':
      return 'Bloqueado';
    case 'EVALUATION':
      return 'Em Análise';
    case 'PENDING':
      return 'Pendente';
    case 'DISAPPROVED':
      return 'Reprovado';
    default:
      return '';
  }
};

export const formatResposible = (value: string) => {
  switch (value) {
    case 'CLIENTE':
      return 'Cliente';
    case 'CADASTRO':
      return 'Cadastro';
    case 'COMPLIANCE':
      return 'Compliance';
    case 'DIRETORIA':
      return 'Diretória';
    default:
      return '';
  }
};

export const formatStatus = (value: string) => {
  switch (value) {
    case 'TO_INTEGRATION':
      return 'Sincronizando';
    case 'PENDING_COMPANY':
      return 'Aguardando Validação';
    case 'PENDING_DASCAM':
      return 'Aguardando Validação';
    default:
      return '---';
  }
};

export const formatDoc = (value: string) => {
  switch (value.replace(/\D/g, '').length) {
    case 11:
      return formatCPF(value);
    case 14:
      return formatCNPJ(value);
    default:
      return '---';
  }
};

export const yesNo = (value: boolean) => {
  switch (value) {
    case true:
      return 'Sim';
    case false:
      return 'Não';
    default:
      return '';
  }
};

export const typeSend = (value: number) => {
  switch (value) {
    case 1:
      return 'Contrato de Cambio';
    case 2:
      return 'Notas de Corretagem';
    case 3:
      return 'Ambos';
    default:
      return '';
  }
};

export const typeFormSignature = (value: number) => {
  switch (value) {
    case 1:
      return 'Digital';
    case 2:
      return 'Manual';
    default:
      return '';
  }
};

export const backAccount = (value: string) => {
  switch (value) {
    case 'P':
      return 'Poupança';
    case 'C':
      return 'Corrente';
    default:
      return '';
  }
};

export const typeClient = (value: string) => {
  return value === 'JURIDICO'
    ? 'Jurídico'
    : value === 'FISICO'
      ? 'Físico'
      : 'Estrangeiro'
}

export const typeContract = (value: string) => {
  switch (value) {
    case 'IMPORTACAO':
      return 'Venda';
    case 'REMESSA':
      return 'Venda';
    default:
      return 'Compra';
  }
}

export const statusClient = (value: string) => {
  return value === 'DISAPPROVED'
    ? 'Reprovado'
    : value === 'PENDING'
      ? 'Pendente'
      : value === 'EVALUATION'
        ? 'Em análise'
        : value === 'BLOCKED'
          ? 'Bloqueado'
          : 'Aprovado'
}

export function formatColumn(worksheet: any, col: any, fmt: any) {
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col })
    if (worksheet[ref] && worksheet[ref].t === 'n') {
      worksheet[ref].z = fmt
    }
  }
}

export function typeOp(data: string) {
  switch (data) {
    case 'P':
      return 'POSISÃO PRÓPRIA'
    case 'T':
      return 'TURISMO'
    case 'I':
      return 'INTERMEDIAÇÃO'
    default:
      return ''
  }
}