export const coutriesList = [
    { nome: "Afeganistão", codigo: "AF" },
    { nome: "África do Sul", codigo: "ZA" },
    { nome: "Albânia", codigo: "AL" },
    { nome: "Alemanha", codigo: "DE" },
    { nome: "Andorra", codigo: "AD" },
    { nome: "Angola", codigo: "AO" },
    { nome: "Antígua e Barbuda", codigo: "AG" },
    { nome: "Arábia Saudita", codigo: "SA" },
    { nome: "Argélia", codigo: "DZ" },
    { nome: "Argentina", codigo: "AR" },
    { nome: "Armênia", codigo: "AM" },
    { nome: "Austrália", codigo: "AU" },
    { nome: "Áustria", codigo: "AT" },
    { nome: "Azerbaijão", codigo: "AZ" },
    { nome: "Bahamas", codigo: "BS" },
    { nome: "Bahrein", codigo: "BH" },
    { nome: "Bangladesh", codigo: "BD" },
    { nome: "Barbados", codigo: "BB" },
    { nome: "Bélgica", codigo: "BE" },
    { nome: "Belize", codigo: "BZ" },
    { nome: "Benim", codigo: "BJ" },
    { nome: "Bielorrússia", codigo: "BY" },
    { nome: "Bolívia", codigo: "BO" },
    { nome: "Bósnia e Herzegovina", codigo: "BA" },
    { nome: "Botsuana", codigo: "BW" },
    { nome: "Brasil", codigo: "BR" },
    { nome: "Brunei", codigo: "BN" },
    { nome: "Bulgária", codigo: "BG" },
    { nome: "Burquina Faso", codigo: "BF" },
    { nome: "Burundi", codigo: "BI" },
    { nome: "Butão", codigo: "BT" },
    { nome: "Cabo Verde", codigo: "CV" },
    { nome: "Camarões", codigo: "CM" },
    { nome: "Camboja", codigo: "KH" },
    { nome: "Canadá", codigo: "CA" },
    { nome: "Catar", codigo: "QA" },
    { nome: "Cazaquistão", codigo: "KZ" },
    { nome: "Chade", codigo: "TD" },
    { nome: "Chile", codigo: "CL" },
    { nome: "China", codigo: "CN" },
    { nome: "Chipre", codigo: "CY" },
    { nome: "Colômbia", codigo: "CO" },
    { nome: "Comores", codigo: "KM" },
    { nome: "Coreia do Norte", codigo: "KP" },
    { nome: "Coreia do Sul", codigo: "KR" },
    { nome: "Costa do Marfim", codigo: "CI" },
    { nome: "Costa Rica", codigo: "CR" },
    { nome: "Croácia", codigo: "HR" },
    { nome: "Cuba", codigo: "CU" },
    { nome: "Dinamarca", codigo: "DK" },
    { nome: "Djibuti", codigo: "DJ" },
    { nome: "Dominica", codigo: "DM" },
    { nome: "Egito", codigo: "EG" },
    { nome: "El Salvador", codigo: "SV" },
    { nome: "Emirados Árabes Unidos", codigo: "AE" },
    { nome: "Equador", codigo: "EC" },
    { nome: "Eritreia", codigo: "ER" },
    { nome: "Eslováquia", codigo: "SK" },
    { nome: "Eslovênia", codigo: "SI" },
    { nome: "Espanha", codigo: "ES" },
    { nome: "Estados Unidos", codigo: "US" },
    { nome: "Estônia", codigo: "EE" },
    { nome: "Etiópia", codigo: "ET" },
    { nome: "Fiji", codigo: "FJ" },
    { nome: "Filipinas", codigo: "PH" },
    { nome: "Finlândia", codigo: "FI" },
    { nome: "França", codigo: "FR" },
    { nome: "Gabão", codigo: "GA" },
    { nome: "Gâmbia", codigo: "GM" },
    { nome: "Gana", codigo: "GH" },
    { nome: "Geórgia", codigo: "GE" },
    { nome: "Granada", codigo: "GD" },
    { nome: "Grécia", codigo: "GR" },
    { nome: "Guatemala", codigo: "GT" },
    { nome: "Guiana", codigo: "GY" },
    { nome: "Guiné", codigo: "GN" },
    { nome: "Guiné-Bissau", codigo: "GW" },
    { nome: "Guiné Equatorial", codigo: "GQ" },
    { nome: "Haiti", codigo: "HT" },
    { nome: "Holanda", codigo: "NL" },
    { nome: "Honduras", codigo: "HN" },
    { nome: "Hungria", codigo: "HU" },
    { nome: "Iêmen", codigo: "YE" },
    { nome: "Ilhas Maldivas", codigo: "MV" },
    { nome: "Índia", codigo: "IN" },
    { nome: "Indonésia", codigo: "ID" },
    { nome: "Irã", codigo: "IR" },
    { nome: "Iraque", codigo: "IQ" },
    { nome: "Irlanda", codigo: "IE" },
    { nome: "Islândia", codigo: "IS" },
    { nome: "Israel", codigo: "IL" },
    { nome: "Itália", codigo: "IT" },
    { nome: "Jamaica", codigo: "JM" },
    { nome: "Japão", codigo: "JP" },
    { nome: "Jordânia", codigo: "JO" },
    { nome: "Kiribati", codigo: "KI" },
    { nome: "Kosovo", codigo: "XK" },
    { nome: "Kuwait", codigo: "KW" },
    { nome: "Laos", codigo: "LA" },
    { nome: "Lesoto", codigo: "LS" },
    { nome: "Letônia", codigo: "LV" },
    { nome: "Líbano", codigo: "LB" },
    { nome: "Libéria", codigo: "LR" },
    { nome: "Líbia", codigo: "LY" },
    { nome: "Liechtenstein", codigo: "LI" },
    { nome: "Lituânia", codigo: "LT" },
    { nome: "Luxemburgo", codigo: "LU" },
    { nome: "Madagáscar", codigo: "MG" },
    { nome: "Malásia", codigo: "MY" },
    { nome: "Malawi", codigo: "MW" },
    { nome: "Mali", codigo: "ML" },
    { nome: "Malta", codigo: "MT" },
    { nome: "Marrocos", codigo: "MA" },
    { nome: "Maurício", codigo: "MU" },
    { nome: "México", codigo: "MX" },
    { nome: "Moçambique", codigo: "MZ" },
    { nome: "Mônaco", codigo: "MC" },
    { nome: "Mongólia", codigo: "MN" },
    { nome: "Montenegro", codigo: "ME" },
    { nome: "Nepal", codigo: "NP" },
    { nome: "Nicarágua", codigo: "NI" },
    { nome: "Nigéria", codigo: "NG" },
    { nome: "Noruega", codigo: "NO" },
    { nome: "Nova Zelândia", codigo: "NZ" },
    { nome: "Omã", codigo: "OM" },
    { nome: "Países Baixos", codigo: "NL" },
    { nome: "Paquistão", codigo: "PK" },
    { nome: "Paraguai", codigo: "PY" },
    { nome: "Peru", codigo: "PE" },
    { nome: "Polônia", codigo: "PL" },
    { nome: "Portugal", codigo: "PT" },
    { nome: "Reino Unido", codigo: "GB" },
    { nome: "República Tcheca", codigo: "CZ" },
    { nome: "Romênia", codigo: "RO" },
    { nome: "Rússia", codigo: "RU" },
    { nome: "São Tomé e Príncipe", codigo: "ST" },
    { nome: "Senegal", codigo: "SN" },
    { nome: "Sérvia", codigo: "RS" },
    { nome: "Síria", codigo: "SY" },
    { nome: "Suécia", codigo: "SE" },
    { nome: "Suíça", codigo: "CH" },
    { nome: "Tailândia", codigo: "TH" },
    { nome: "Uruguai", codigo: "UY" },
    { nome: "Venezuela", codigo: "VE" }
];

