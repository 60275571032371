import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  formatCEP,
  formatCNPJ,
  formatCPF
} from 'helpers/formats'
import {
  BeneficiaryModel,
  BeneficiaryPersonEnum,
  BeneficiaryStatusEnum
} from 'interfaces/beneficiary'
import { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { v4 as uuidv4 } from 'uuid'
import { validateFieldV2 } from 'helpers/validFilds'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { listUF, tiposLougradoro } from 'helpers/listsTypes'
import { coutriesList } from 'helpers/countries'

interface Props {
  shareholdingData: BeneficiaryModel[]
  changeShareholdingData: (shareholdingData: BeneficiaryModel[]) => void
  readonly: boolean
}

const ShareholdingComposition = ({
  shareholdingData,
  changeShareholdingData,
  readonly
}: Props): JSX.Element => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [shareholding, setShareholding] = useState<BeneficiaryModel>({})
  const [percentRest, setPercentRest] = useState<number>(100)

  const columns: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.id,
      omit: true
    },
    {
      name: 'AÇÕES',
      cell: (row, i) => action(row, i),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true
    },
    {
      name: 'Nome',
      selector: (row) =>
        row.beneficiary_name
          ? capitalizeFirstLetter(row.beneficiary_name)
          : '---',
      sortable: true
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) =>
        row.document
          ? row.document.replace(/\D/g, '').length > 11
            ? formatCNPJ(String(row.document.replace(/\D/g, '')))
            : formatCPF(String(row.document.replace(/\D/g, '')))
          : '---',
      width: '165px'
    },
    {
      name: 'Acionista',
      selector: (row) => (row.shareholder ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'B. Final',
      selector: (row) => (row.final_beneficiary ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'Procurador',
      selector: (row) => (row.attorney ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'R. Legal',
      selector: (row) => (row.legal_representative ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'Nacionalidade',
      selector: (row) => row.nationality ?? '---',
      sortable: true,
      width: '165px'
    },
    {
      name: 'Participação(%)',
      selector: (row) => `${Number(row.participation_percentage ?? 0).toFixed(2)} %`,
      sortable: true,
      width: '160px',
      right: true
    }
  ]

  function action (data: any, index: any) {
    return (
      <HStack m={1}>
        <IconButton
          onClick={() => {
            setShareholding(shareholdingData[index])
            getPercenteRest(
              shareholdingData[index].id ?? shareholdingData[index].uuid
            )
            onOpen()
          }}
          aria-label='Editar'
          title='Editar'
          size={'sm'}
          colorScheme={'secondary_main'}
        >
          <EditDataIcon />
        </IconButton>
      </HStack>
    )
  }

  function addShareholding () {
    try {
      if (!shareholding.id) {
        shareholding.uuid = uuidv4()
      }
      changeShareholdingData([...shareholdingData, shareholding])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function editShareholding () {
    try {
      const ref = shareholding.id || shareholding.uuid

      const index = shareholdingData.findIndex(
        (item) => item.id === ref || item.uuid === ref
      )

      if (index !== -1) {
        shareholdingData.splice(index, 1)
      }

      changeShareholdingData([...shareholdingData, shareholding])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function clearData () {
    try {
      setShareholding({})
    } catch (error) {}
  }

  function getPercenteRest (id?: any) {
    let totalParticipation = 0
    if (id) {
      totalParticipation = shareholdingData
        .filter((e) => e.id !== id && e.uuid !== id)
        .reduce((sum, item) => {
          return sum + (Number(item.participation_percentage) || 0)
        }, 0)
    } else {
      totalParticipation = shareholdingData.reduce((sum, item) => {
        return sum + (Number(item.participation_percentage) || 0)
      }, 0)
    }
    console.log(totalParticipation)
    console.log(totalParticipation.toFixed(2))
    setPercentRest(Number(Number(100 - Number(totalParticipation.toFixed(2))).toFixed(2)))
  }

  function validForm () {
    const valid = validateFieldV2(
      shareholding,
      shareholding.person === 'FISICO'
        ? [
            'beneficiary_name',
            'document',
            'person',
            'status',
            'type',
            'address',
            'address_zipcode',
            'address_type',
            'address_number',
            'address_district',
            'address_city',
            'address_state',
            'nationality',
            'name_mother',
            'name_father',
            'rg',
            'estimated_income'
          ]
        : [
            'beneficiary_name',
            'document',
            'person',
            'status',
            'type',
            'address',
            //'address_zipcode',
            'address_type',
            'address_number',
            //'address_district',
            //'address_city',
            //'address_state',
            //'nationality',
            'legal_representative_name'
          ]
    )

    if (shareholding.address_zipcode?.replace('-', '').length !== 8) {
        toast({
          title: 'CEP inválido',
          position: 'bottom',
          description: 'Por favor, verifique o CEP e tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return false
      }

    if (!valid) {
      toast({
        title: 'Existem campos sem preenchimento!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    return valid
  }

  function CompositionForm () {
    const statusReadOnly = !shareholding.id
    if (statusReadOnly) {
      shareholding.status = BeneficiaryStatusEnum.ACTIVE
    }
    if (!shareholding.person) {
      shareholding.person = BeneficiaryPersonEnum.FISICO
    }

    return (
      <>
        <Modal
          size={'8xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            clearData()
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent ml={4} mr={4} rounded={'xl'}>
            <ModalHeader>Composição Acionária</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'select',
                          label: 'Tipo de Pessoa *',
                          name: 'person',
                          readOnly: readonly,
                          options: [
                            {
                              label: () => 'Pessoa Fisica (CPF)',
                              value: 'FISICO'
                            },
                            {
                              label: () => 'Pessoa Juridica (CNPJ)',
                              value: 'JURIDICO'
                            }
                          ]
                        },
                        {
                          type: 'select',
                          label: 'Tipo de Representante *',
                          name: 'type',
                          readOnly: readonly,
                          options: [
                            //{
                            //  label: () => 'Contato',
                            //  value: 'CONTATO'
                            //},
                            {
                              label: () => 'Socio',
                              value: 'SOCIO / DIRETOR'
                            }
                            //{
                            //  label: () => 'Administrador',
                            //  value: 'ADMINISTRADOR'
                            //},
                            //{
                            //  label: () => 'Diretor',
                            //  value: 'DIRETOR'
                            //}
                          ]
                        },
                        {
                          type: 'select',
                          label: 'Status Conta *',
                          readOnly: statusReadOnly,
                          name: 'status',
                          options: [
                            {
                              label: () => 'Ativo',
                              value: 'ACTIVE'
                            },
                            {
                              label: () => 'Inativo/Bloqueado',
                              value: 'INACTIVE'
                            }
                          ]
                        }
                      ]
                    },
                    {
                      columns: 0,
                      fields: [
                        {
                          type: 'checkbox',
                          label: `Acionista`,
                          readOnly: readonly,
                          name: 'shareholder'
                        },
                        {
                          type: 'checkbox',
                          label: `Beneficiário final`,
                          readOnly: readonly,
                          name: 'final_beneficiary'
                        },
                        {
                          type: 'checkbox',
                          label: `Representante Legal`,
                          readOnly: readonly,
                          name: 'legal_representative'
                        },
                        {
                          type: 'checkbox',
                          label: `Procurador`,
                          readOnly: readonly,
                          name: 'attorney'
                        },
                        {
                          type: 'checkbox',
                          label: `PeP`,
                          readOnly: readonly,
                          name: 'pep'
                        }
                      ]
                    },
                    {
                      columns: 0,
                      fields:
                        shareholding.person === 'FISICO'
                          ? [
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: 'Nome *',
                                name: 'beneficiary_name'
                              },
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: 'CPF *',
                                name: 'document',
                                maxLength: 14,
                                mask (data) {
                                  return formatCPF(String(data))
                                }
                              },
                              {
                                type: 'text',
                                label: `RG ou RNE *`,
                                readOnly: readonly,
                                name: 'rg'
                              },
                              //{
                              //  type: 'text',
                              //  readOnly: readonly,
                              //  label: `Nacionalidade *`,
                              //  name: 'nationality'
                              //},
                              {
                                type: 'select',
                                readOnly: readonly,
                                label: `Nacionalidade *`,
                                name: 'nationality',
                                options: coutriesList.map(
                                    (country) =>
                                        ({
                                        label: () => country.nome,
                                        value: country.codigo
                                        } as any)
                                )
                              },
                              {
                                type: 'text',
                                label: `Nome do Pai *`,
                                readOnly: readonly,
                                name: 'name_father'
                              },
                              {
                                type: 'text',
                                label: `Nome da Mãe *`,
                                readOnly: readonly,
                                name: 'name_mother'
                              },
                              {
                                type: 'currency',
                                label: `Renda Estimada *`,
                                description: '.',
                                readOnly: readonly,
                                name: 'estimated_income'
                              },
                              {
                                type: 'rate',
                                readOnly: readonly,
                                formatRateFraction: 2,
                                label: `Participação (%) *`,
                                name: 'participation_percentage',
                                description: `Percentual máximo para aplicacão: ${percentRest} %`
                              }
                            ]
                          : [
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: 'Razão Social *',
                                name: 'beneficiary_name'
                              },
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: 'CNPJ *',
                                name: 'document',
                                maxLength: 18,
                                mask (data) {
                                  return formatCNPJ(String(data))
                                }
                              },
                              {
                                type: 'rate',
                                readOnly: readonly,
                                label: `Participação (%) *`,
                                name: 'participation_percentage',
                                formatRateFraction: 2,
                                description: `Percentual máximo para aplicacão: ${percentRest} %`
                              },
                              {
                                type: 'text',
                                label: `Representante Legal *`,
                                readOnly: readonly,
                                name: 'legal_representative_name'
                              }
                            ]
                    },
                    {
                      columns: 0,
                      fields: [
                        {
                          type: 'text',
                          label: 'CEP',
                          name: 'address_zipcode',
                          mask (data) {
                            return formatCEP(String(data))
                          },
                          readOnly: readonly,
                          maxLength: 9
                        },
                        {
                          type: 'select',
                          label: 'Tipo de Lougradoro *',
                          readOnly: readonly,
                          options: tiposLougradoro.map((tipo) => ({
                            label: () => tipo,
                            value: tipo
                          })),
                          name: 'address_type'
                        },
                        {
                          type: 'text',
                          label:
                            shareholding.person === 'FISICO'
                              ? `Endereço Residencial *`
                              : `Endereço da Empresa *`,
                          readOnly: readonly,
                          name: 'address'
                        },
                        {
                          type: 'text',
                          label: 'Número *',
                          name: 'address_number',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Complemento',
                          name: 'address_complement',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Bairro',
                          name: 'address_district',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Cidade',
                          name: 'address_city',
                          readOnly: readonly
                        },
                        {
                          type: 'select',
                          label: 'UF',
                          name: 'address_state',
                          options: listUF,
                          readOnly: readonly
                        }
                      ]
                    }
                  ]}
                  value={shareholding}
                  onChange={(data) => {
                    data.alter = true

                    if (data.participation_percentage) {
                      // data.participation_percentage = String(
                      //   data.participation_percentage
                      // ).replace(/\D/g, '')
                      if (data.participation_percentage > percentRest) {
                        data.participation_percentage = percentRest
                      }
                    } else {
                      data.participation_percentage = 0
                    }

                    if (data.person !== shareholding.person) {
                      setShareholding({
                        id: data.id,
                        uuid: data.uuid,
                        person: data.person,
                        alter: data.alter,
                        status: data.status
                      })
                    } else {
                      setShareholding(data)
                    }
                  }}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={() => {
                  clearData()
                  onClose()
                }}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                colorScheme={'green'}
                mr='20px'
                disabled={readonly}
                onClick={() => {
                  if (validForm()) {
                    !shareholding?.id && !shareholding?.uuid
                      ? addShareholding()
                      : editShareholding()
                    onClose()
                  }
                }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      {CompositionForm()}
      <Box m={4}>
        <Button
          onClick={() => {
            getPercenteRest()
            onOpen()
          }}
          disabled={readonly}
          variant='outline'
          colorScheme={'primary'}
        >
          Adicionar
        </Button>
        <Col
          mt={1}
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
          minHeight={`250px`}
          backgroundColor={'white'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={shareholdingData}
            dense={true}
            striped={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={`250px`}
          />
        </Col>
      </Box>
    </>
  )
}

export default ShareholdingComposition
