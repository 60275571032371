import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  formatCEP,
  formatCNPJ,
  formatCPF
} from 'helpers/formats'
import { validateFieldV2 } from 'helpers/validFilds'
import {
  BeneficiaryModel,
  BeneficiaryPersonEnum,
  BeneficiaryStatusEnum
} from 'interfaces/beneficiary'
import { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as EditDataIcon } from 'assets/editDataIcon.svg'
import { listUF, tiposLougradoro } from 'helpers/listsTypes'

interface Props {
  representativeData: BeneficiaryModel[]
  changeRepresentativeData: (shareholdingData: BeneficiaryModel[]) => void
  readonly: boolean
}

const LegalRepresentative = ({
  representativeData,
  changeRepresentativeData,
  readonly
}: Props): JSX.Element => {
  const toast = useToast()
  const [representative, setRepresentative] = useState<BeneficiaryModel>({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columns: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.id,
      omit: true
    },
    {
      name: 'AÇÕES',
      cell: (row, i) => action(row, i),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true
    },
    {
      name: 'Nome',
      selector: (row) =>
        row.beneficiary_name
          ? capitalizeFirstLetter(row.beneficiary_name)
          : '---',
      sortable: true
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) =>
        row.document
          ? row.document.replace(/\D/g, '').length > 11
            ? formatCNPJ(String(row.document.replace(/\D/g, '')))
            : formatCPF(String(row.document.replace(/\D/g, '')))
          : '---',
      width: '165px'
    },
    {
      name: 'Admin.',
      selector: (row) => (row.administrator ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    },
    {
      name: 'Procurador',
      selector: (row) => (row.attorney ? 'Sim' : 'Não'),
      sortable: true,
      width: '110px'
    }
  ]

  function action (data: any, index: any) {
    return (
      <HStack m={1}>
        <IconButton
          onClick={() => {
            setRepresentative(representativeData[index])
            onOpen()
          }}
          aria-label='Editar'
          title='Editar'
          size={'sm'}
          colorScheme={'secondary_main'}
        >
          <EditDataIcon />
        </IconButton>
      </HStack>
    )
  }

  function addRepresentative () {
    try {
      if (!representative.id) {
        representative.uuid = uuidv4()
      }
      changeRepresentativeData([...representativeData, representative])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function editRepresentative () {
    try {
      const ref = representative.id || representative.uuid

      const index = representativeData.findIndex(
        (item) => item.id === ref || item.uuid === ref
      )

      if (index !== -1) {
        representativeData.splice(index, 1)
      }

      changeRepresentativeData([...representativeData, representative])
    } catch (error) {
    } finally {
      clearData()
    }
  }

  function clearData () {
    try {
      setRepresentative({})
    } catch (error) {}
  }

  function validForm () {
    const valid = validateFieldV2(
      representative,
      representative.person === 'FISICO'
        ? [
            'beneficiary_name',
            'document',
            'person',
            'status',
            'type',
            'address',
            'address_zipcode',
            'address_type',
            'address_number',
            'address_district',
            'address_city',
            'address_state',
            //'nationality',
            'name_mother',
            'name_father',
            'rg',
            'email'
          ]
        : [
            'beneficiary_name',
            'document',
            'person',
            'status',
            'type',
            'email',
            'address',
            'address_zipcode',
            'address_type',
            'address_number',
            'address_district',
            'address_city',
            'address_state'
          ]
    )

    if (representative.address_zipcode?.replace('-', '').length !== 8) {
        toast({
          title: 'CEP inválido',
          position: 'bottom',
          description: 'Por favor, verifique o CEP e tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return false
      }

    if (!valid) {
      toast({
        title: 'Existem campos sem preenchimento!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    return valid
  }

  function CompositionForm () {
    const statusReadOnly = !representative.id
    if (statusReadOnly) {
      representative.status = BeneficiaryStatusEnum.ACTIVE
    }
    if (!representative.person) {
      representative.person = BeneficiaryPersonEnum.FISICO
    }
    return (
      <>
        <Modal
          size={'8xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            clearData()
          }}
        >
          <ModalOverlay />
          <ModalContent ml={4} mr={4} rounded={'xl'}>
            <ModalHeader>Representante Legal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Col m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 3,
                      fields: [
                        {
                          type: 'select',
                          label: 'Tipo de Pessoa *',
                          name: 'person',
                          readOnly: readonly,
                          options: [
                            {
                              label: () => 'Pessoa Fisica (CPF)',
                              value: 'FISICO'
                            }
                            //{
                            //  label: () => 'Pessoa Juridica (CNPJ)',
                            //  value: 'JURIDICO'
                            //}
                          ]
                        },
                        {
                          type: 'select',
                          label: 'Tipo de Representante *',
                          name: 'type',
                          readOnly: readonly,
                          options: [
                            {
                              label: () => 'Procurador',
                              value: 'PROCURADOR'
                            },
                            {
                              label: () => 'Administrador',
                              value: 'ADMINISTRADOR'
                            }
                            //{
                            //  label: () => 'Diretor',
                            //  value: 'DIRETOR'
                            //}
                          ]
                        },
                        {
                          type: 'select',
                          label: 'Status Conta *',
                          readOnly: statusReadOnly,
                          name: 'status',
                          options: [
                            {
                              label: () => 'Ativo',
                              value: 'ACTIVE'
                            },
                            {
                              label: () => 'Inativo/Bloqueado',
                              value: 'INACTIVE'
                            }
                          ]
                        }
                      ]
                    },
                    {
                      columns: 4,
                      fields: [
                        {
                          type: 'checkbox',
                          label: `Beneficiário final`,
                          readOnly: readonly,
                          name: 'final_beneficiary'
                        },
                        {
                          type: 'checkbox',
                          label: `PeP`,
                          readOnly: readonly,
                          name: 'pep'
                        }
                      ]
                    },
                    {
                      columns: 0,
                      fields: [
                        {
                            type: 'text',
                            readOnly: readonly,
                            label: `E-mail *`,
                            name: 'email'
                          },
                        {
                          type: 'text',
                          readOnly: readonly,
                          label: `${
                            representative.person === 'FISICO'
                              ? 'Nome *'
                              : 'Razão Social *'
                          }`,
                          name: 'beneficiary_name'
                        },
                        {
                          type: 'text',
                          readOnly: readonly,
                          label: `${
                            representative.person === 'FISICO'
                              ? 'CPF *'
                              : 'CNPJ *'
                          }`,
                          name: 'document',
                          maxLength:
                            representative.person === 'FISICO' ? 14 : 18,
                          mask (data) {
                            return data.length > 14
                              ? formatCNPJ(String(data))
                              : formatCPF(String(data))
                          }
                        }
                      ]
                    },
                    {
                      columns: 0,
                      fields:
                        representative.person === 'FISICO'
                          ? [
                            {
                                type: 'text',
                                readOnly: readonly,
                                label: `RG ou RNE *`,
                                name: 'rg'
                              },
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: `Nome do Pai *`,
                                name: 'name_father'
                              },
                              {
                                type: 'text',
                                readOnly: readonly,
                                label: `Nome da Mãe *`,
                                name: 'name_mother'
                              },
                              {
                                type: 'currency',
                                readOnly: readonly,
                                label: `Renda Estimada *`,
                                name: 'estimated_income'
                              }
                            ]
                          : []
                    },
                    {
                      columns: 0,
                      fields: [
                        {
                          type: 'text',
                          label: 'CEP *',
                          name: 'address_zipcode',
                          mask (data) {
                            return formatCEP(String(data))
                          },
                          readOnly: readonly,
                          maxLength: 9
                        },
                        {
                          type: 'select',
                          label: 'Tipo de Lougradoro',
                          readOnly: readonly,
                          options: tiposLougradoro.map((tipo) => ({
                            label: () => tipo,
                            value: tipo
                          })),
                          name: 'address_type'
                        },
                        {
                          type: 'text',
                          label:
                            representative.person === 'FISICO'
                              ? `Endereço Residencial *`
                              : `Endereço da Empresa *`,
                          readOnly: readonly,
                          name: 'address'
                        },
                        {
                          type: 'text',
                          label: 'Número *',
                          name: 'address_number',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Complemento',
                          name: 'address_complement',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Bairro *',
                          name: 'address_district',
                          readOnly: readonly
                        },
                        {
                          type: 'text',
                          label: 'Cidade *',
                          name: 'address_city',
                          readOnly: readonly
                        },
                        {
                          type: 'select',
                          label: 'UF *',
                          name: 'address_state',
                          options: listUF,
                          readOnly: readonly
                        }
                      ]
                    }
                  ]}
                  value={representative}
                  onChange={(data) => {
                    data.alter = true
                    data.is_only_legal_representative = true
                    data.participation_percentage =
                      data.participation_percentage ?? '0'

                    if (data.type === 'ADMINISTRADOR') {
                        data.administrator = true
                        data.attorney = false
                    } else if (data.type === 'PROCURADOR') {
                        data.attorney = true
                        data.administrator = false
                    } else {
                        data.administrator = false
                        data.attorney = false
                    }

                    if (data.person !== representative.person) {
                      setRepresentative({
                        id: data.id,
                        uuid: data.uuid,
                        person: data.person,
                        alter: data.alter,
                        status: data.status
                      })
                    } else {
                      setRepresentative(data)
                    }
                  }}
                />
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={'ghost'}
                color={'red.400'}
                colorScheme={'red'}
                mr='20px'
                onClick={() => {
                  clearData()
                  onClose()
                }}
              >
                Cancelar
              </Button>

              <Button
                variant={'outline'}
                color={'green.400'}
                disabled={readonly}
                colorScheme={'green'}
                mr='20px'
                onClick={() => {
                  if (validForm()) {
                    !representative?.id && !representative?.uuid
                      ? addRepresentative()
                      : editRepresentative()
                    onClose()
                  }
                }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      {CompositionForm()}
      <Box m={4}>
        <Button
          onClick={onOpen}
          variant='outline'
          disabled={readonly}
          colorScheme={'primary'}
        >
          Adicionar
        </Button>
        <Col
          mt={1}
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
          minHeight={`250px`}
          backgroundColor={'white'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={representativeData}
            dense={true}
            striped={true}
            fixedHeader={true}
            fixedHeaderScrollHeight={`250px`}
          />
        </Col>
      </Box>
    </>
  )
}

export default LegalRepresentative
