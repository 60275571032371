import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { ChangeEvent, useMemo } from 'react'
import ButtonCopy from 'v2/components/shared/ButtonCopy'
import { Col } from 'v2/components/shared/Col'
import { Row } from 'v2/components/shared/Row'
import { FieldProps } from '../..'

interface NumberFieldProps extends FieldProps<'number', string, string> {}

const NumberField: React.FC<NumberFieldProps> = ({
  infos,
  value,
  onChange,
  isReadonly,
  maxLength
}) => {
  const isUndefined = value === undefined
  const isNull = value === null
  const fieldValue = useMemo(() => {
    if (isUndefined && isReadonly) {
      return 'Não definido'
    }

    if (isUndefined || isNull) {
      return ''
    }

    return value
  }, [value, isUndefined, isNull, isReadonly])

  return (
    <FormControl>
      <Col mb={1}>
        <FormLabel fontWeight={'bold'} mb={0} fontFamily={'heading'}>
          {infos.label}
        </FormLabel>
        <FormLabel color={'GrayText'} mb={0} fontSize={'xs'}>
          {infos.description}
        </FormLabel>
      </Col>
      <Row pos={'relative'} alignItems={'center'} role='group' mt={'auto'}>
        <Input
          rounded={'sm'}
          type={'text'}
          maxLength={maxLength}
          value={`${fieldValue}`}
          placeholder={infos.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value
            if (!/[0-9]/g.test(rawValue)) {
              if (rawValue === '') {
                onChange?.(rawValue)
              } else {
                e.preventDefault()
              }
            } else {
              onChange?.(rawValue)
            }
          }}
          readOnly={isReadonly}
          variant={'filled'}
          border={'1px solid #818c96'}
          backgroundColor={'white'}
          fontStyle={isUndefined ? 'italic' : 'normal'}
          color={isUndefined ? 'gray.500' : 'inherit'}
          _focus={
            isReadonly
              ? {}
              : {
                  bg: 'white'
                }
          }
        />
        <ButtonCopy value={value || ''} />
      </Row>
    </FormControl>
  )
}

export default NumberField
