import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import SimpleFormGenerator from '../SimpleFormGenerator'

interface Props {
  title: string
  msg: string
  isRequiredMsg: boolean
  isOpen: boolean
  onClose: () => void
  action: (data: string) => void
  custom?: JSX.Element
}

interface PropsMsg {
  msg: string
}

export function AlertDialogMsgComp ({
  title,
  msg,
  isRequiredMsg,
  isOpen,
  onClose,
  action,
  custom
}: Props) {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [text, setText] = useState<PropsMsg>({ msg: '' })
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {custom}
              {msg}
              <Box m={4}>
                <SimpleFormGenerator
                  rows={[
                    {
                      columns: 0,
                      fields: [
                        {
                          type: 'textarea',
                          label: 'Observação',
                          name: 'msg'
                        }
                      ]
                    }
                  ]}
                  value={text}
                  onChange={(v) => {
                    setText(v)
                  }}
                />
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme='red'
                onClick={() => {
                  if (isRequiredMsg) {
                    if (text.msg) {
                      onClose()
                      action(text.msg)
                    }
                  } else {
                    onClose()
                    action(text.msg)
                  }
                }}
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
