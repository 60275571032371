import GenericButton from 'components/generic/GenericButton'
import usePagination from 'hooks/usePagination'
import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { UserModel } from 'interfaces/user'
import { axiosAuth } from 'helpers/axiosInstances'
import { useDispatch } from 'react-redux'
import { isUnauthorized } from 'helpers/errors'
import { Redirect, useHistory } from 'react-router-dom'
import { setGlobalMessage, clearLoading, showLoading } from 'redux/actions'
import RegisterUser from 'components/restricted-area/registration-data/dascam/user/subComponents/RegisterUser'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { Permissions } from 'interfaces/web/permission'
import CardPage from 'v2/components/pages/CardPage'
import { AiOutlineEdit } from 'react-icons/ai'
import { Button, HStack, IconButton } from '@chakra-ui/react'
import { BiShow } from 'react-icons/bi'
import { Col } from 'v2/components/shared/Col'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'
import EditUser from 'components/restricted-area/registration-data/dascam/user/subComponents/EditUser'
import VisualUser from 'components/restricted-area/registration-data/dascam/user/subComponents/VisualUser'
import ActiveUser from 'components/restricted-area/registration-data/dascam/user/subComponents/ActiveUser'
import DisableUser from 'components/restricted-area/registration-data/dascam/user/subComponents/DisableUser'
import DataTable, { TableColumn } from 'react-data-table-component'
import AutoSizer from 'react-virtualized-auto-sizer'

const UsersDascam: React.FC = () => {
  const pageLimit = 10
  // const user = useSelector((state: GlobalState) => state.user);
  const { user, hasPermissions } = useGlobals()
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, pages, nextPage, prevPage, setTotal] =
    usePagination(pageLimit)
  const [registerUser, setRegisterUser] = useState<boolean>(false)
  const [users, setUsers] = useState<UserModel[]>([])
  const [editUser, setEditUser] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [activeUser, setActiveUser] = useState<boolean>(false)
  const [disableUser, setDisableUser] = useState<boolean>(false)
  const [idUserToAction, setIdUserToAction] = useState<number>(0)

  const [pTotal, setPTotal] = useState<number>()

  const columns: TableColumn<UserModel>[] = [
    {
      name: 'ID',
      selector: (row) => row.id ?? '',
      omit: true
    },
    {
      name: 'Nome',
      selector: (row) => row.user_name ?? '',
      width: '260px'
    },
    {
      name: 'E-mail',
      selector: (row) => row.email ?? '---',
      width: '260px'
    },
    {
      name: 'Login',
      selector: (row) => row.login ?? '---',
      width: '160px'
    },
    {
      name: 'Departamento',
      selector: (row) => row.dascam_type ?? '---'
    },
    {
      name: 'AÇÕES',
      cell: (item) => (
        <HStack m={1}>
          <IconButton
            aria-label='Visualizar'
            size={'sm'}
            colorScheme={'blue'}
            onClick={() => {
              setIdUserToAction(item?.id ?? 0)
              setShowUser(true)
            }}
          >
            <BiShow size={16} />
          </IconButton>
          <IconButton
            aria-label='Editar'
            size={'sm'}
            colorScheme={'blue'}
            onClick={() => {
              setIdUserToAction(item?.id ?? 0)
              setEditUser(true)
            }}
          >
            <AiOutlineEdit size={16} />
          </IconButton>
          <Col width={'0'}>
            <ButtonStatus
              isActive={item.status === 'ACTIVE' ? true : false}
              type='button'
              onClick={() => {
                setIdUserToAction(item?.id ?? 0)
                item.status === 'ACTIVE'
                  ? setDisableUser(!disableUser)
                  : setActiveUser(!activeUser)
              }}
            >
              {item.status === 'ACTIVE' ? <ActiveIcon /> : <InactiveIcon />}
            </ButtonStatus>
          </Col>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      width: '225px',
      button: true
    }
  ]
  const paginationComponentOptions = {
    rowsPerPageText: 'Usuários por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
  }

  const getUserData = useCallback(
    async (page?) => {
      try {
        dispatch(
          showLoading({
            message: 'Carregando',
            isLoading: true
          })
        )
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }

        const { data } = await axiosAuth.get(
          `/filter-users?limit=${pageLimit}&offset=${
            ((page ?? currentPage) - 1) * pageLimit
          }&cad_type=DASCAM`,
          config
        )

        setTotal(data.page.total)
        setPTotal(data.page.total)
        setUsers(data.users)
      } catch (error: any) {
        if (isUnauthorized(error)) {
          history.push('/unauthorized')
        } else {
          dispatch(
            setGlobalMessage({
              message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}.`,
              type: 'ERROR'
            })
          )
        }
      } finally {
        dispatch(clearLoading())
      }
    },
    [currentPage, dispatch, history, setTotal]
  )

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(
        `/filter-users?limit=${newPerPage ?? pageLimit}&offset=${
          (page - 1) * newPerPage ?? pageLimit
        }&cad_type=DASCAM`,
        config
      )

      setTotal(data.page.total)
      setUsers(data.users)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}.`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  const handlePageChange = (page: any) => {
    getUserData(page)
  }
  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <>
      {!hasPermissions([Permissions.USERS_VIEW]) && (
        <Redirect to='/unauthorized' />
      )}
      <CardPage
        title={'Usuários Dascam'}
        _headerRight={
          hasPermissions([Permissions.USERS_CREATE]) && (
            <Button
              variant='outline'
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => setRegisterUser(!registerUser)}
            >
              Incluir novo usuário
            </Button>
          )
        }
      >
        <Col px={2} overflow={'hidden'} flex={1}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <Col
                mt={3}
                rounded={'lg'}
                border={'1px solid transparent'}
                borderColor={'primary.600'}
                h={height - 25}
                w={width}
                backgroundColor={'white'}
              >
                {' '}
                <DataTable
                  noDataComponent=''
                  keyField='ID'
                  columns={columns}
                  data={users}
                  dense={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`${height}px`}
                  striped={true}
                  pagination={true}
                  paginationServer={true}
                  paginationTotalRows={pTotal}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </Col>
            )}
          </AutoSizer>
        </Col>
      </CardPage>
      <RegisterUser
        showModal={registerUser}
        handleModal={() => setRegisterUser(!registerUser)}
        getUserData={() => getUserData()}
      />
      <EditUser
        id={String(idUserToAction)}
        showModal={editUser}
        handleModal={() => setEditUser(!editUser)}
        getUserData={() => getUserData()}
      />
      <VisualUser
        id={String(idUserToAction)}
        showModal={showUser}
        handleModal={() => setShowUser(!showUser)}
      />
      <ActiveUser
        id={String(idUserToAction)}
        showModal={activeUser}
        handleModal={() => setActiveUser(!activeUser)}
        getUserData={() => getUserData()}
      />
      <DisableUser
        id={String(idUserToAction)}
        showModal={disableUser}
        handleModal={() => setDisableUser(!disableUser)}
        getUserData={() => getUserData()}
      />
    </>
  )
}

const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`

export default UsersDascam
