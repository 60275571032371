import styled, { keyframes } from 'styled-components'
import { devices } from 'helpers/devices'
import { useEffect, useState } from 'react'
import {
  formatPhone,
  formatCPF,
  formatCNPJ,
  formatCelPhone,
  formatCEP,
  formatPercentage,
  formatAccountBank,
  formatAgency,
  formatDI,
  capitalizeFirstLetter
} from 'helpers/formats'
import { cpf, cnpj } from 'cpf-cnpj-validator'
//img
import NoImg from 'assets/NoImg.png'
import { ReactComponent as CrossIcon } from 'assets/crossIcon.svg'
import { ReactComponent as CheckIcon } from 'assets/checkSvg.svg'
import { axiosAuth, axiosCep, axiosWebsite } from 'helpers/axiosInstances'
import { ReactComponent as IconEye } from 'assets/IconOpenEye.svg'
import { ReactComponent as IconEyeClosed } from 'assets/IconClosedEye.svg'
import { ReactComponent as LupaIcon } from 'assets/lupaIcon.svg'
import { useDispatch } from 'react-redux'
import { setGlobalMessage } from 'redux/actions'
import { valueConvert, valueReconvert } from 'helpers/moedas'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { CountryModel } from 'interfaces/country'
import { ActivityModel } from 'interfaces/activityMain'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react'
/* import { axiosCep, axiosGeo } from "helpers/axiosInstances"; */

const slide = keyframes`
  to {
    transform: rotate(1turn);
  }
`

export const InputRead = styled.input<{
  isBlock?: boolean
  maxWidth?: string
  isReadOnly?: boolean
}>`
  width: 100%;
  height: 40px;
  padding: 0.3rem;
  margin: 0.3rem;
  outline: none;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text1};
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 1px solid
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};
  border-radius: 2px;
  box-sizing: border-box;
  text-align: start;
  max-width: ${(props) => props.maxWidth};
`

const Container = styled.div<{
  isRow: boolean
  maxWidth?: string
  leafPormade?: boolean
  label?: boolean
}>`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  justify-content: flex-start;
  align-items: ${(props) => (props.isRow ? 'center' : 'flex-end')};
  padding: ${(props) => (props.isRow ? '.3rem .3rem' : '.3rem .3rem')};
  position: relative;
  width: 100%;
  flex: 1;
  max-width: ${(props) => props.maxWidth};
`

export const Label = styled.label<{
  isColumn?: boolean
  isBlock?: boolean
  maxWidth?: string
}>`
  padding-left: 0.2rem;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text1};
  font-size: ${(props) => (props.isColumn ? props.theme.font.small : '1rem')};
  font-family: Montserrat;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin-left: 0.3rem;
  white-space: nowrap;
  font-weight: bold;

  @media ${devices.phoneOnly} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    font-size: 0.8rem;
  }
`

const LabelCheck = styled.label<{
  isColumn?: boolean
  isBlock?: boolean
  maxWidth?: string
}>`
  padding-left: 0.2rem;
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.gray3};
  font-size: ${(props) => (props.isColumn ? props.theme.font.small : '1rem')};
  font-family: Montserrat;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin-left: 0.3rem;
  white-space: nowrap;

  @media ${devices.phoneOnly} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 1rem;
  }
`

const Input = styled.input<{
  isBlock?: boolean
  maxWidth?: string
  isReadOnly?: boolean
}>`
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text1};
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  height: 42px;
  outline: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 2px solid transparent;
  ${(props) =>
    props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};

  /* box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3}; */
  font-size: 1rem;
  background-color: #ebedf6;
  padding: 0.5rem;
  box-sizing: border-box;
  flex: 1;

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: transparent;
    transition: 9999s ease-in-out 0s;
  }

  &::placeholder {
    color: ${(props) =>
      props.isBlock ? props.theme.color.deny : props.theme.color.gray4};
  }

  @media ${devices.phoneOnly} {
    height: 42px;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    height: 42px;
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    height: 42px;
    font-size: 1rem;
  }
`

const InputCheck = styled.input<{
  isBlock?: boolean
  isReadOnly?: boolean
}>`
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text3};
  width: 16px;
  height: 16px;
  outline: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  border: 1px solid
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};
  border-radius: 6px;
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  padding: 0.5rem;
  margin-left: 0.3rem;
  box-sizing: border-box;

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: transparent;
    transition: 9999s ease-in-out 0s;
  }

  &::placeholder {
    color: ${(props) =>
      props.isBlock ? props.theme.color.deny : props.theme.color.gray4};
  }
`

const Lupa = styled(LupaIcon)`
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
`

const EyeButton = styled.button`
  position: absolute;
  bottom: 13px;
  right: 15px;
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${(props) => props.theme.color.primary_main};
  }
`

const Select = styled.select<{
  isBlock?: boolean
  maxWidth?: string
  isReadOnly?: boolean
}>`
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text1};
  font-size: ${(props) => props.theme.font.normal};
  padding: 0 0.5rem;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  min-height: 42px;
  outline: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  border-radius: 0px;
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 1px solid transparent
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};

  background-color: #ebedf6;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: transparent;
    transition: 9999s ease-in-out 0s;
  }

  &::placeholder {
    color: ${(props) =>
      props.isBlock ? props.theme.color.deny : props.theme.color.gray4};
  }

  @media ${devices.phoneOnly} {
    height: 42px;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    height: 42px;
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    height: 42px;
    font-size: 1rem;
  }
`

const TextArea = styled.textarea<{ isBlock?: boolean; isReadOnly?: boolean }>`
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text3};
  font-size: ${(props) => props.theme.font.normal};
  padding: 1rem;
  width: 100%;
  height: 150px;
  border: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  outline: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  border-radius: 2px;
  background: ${(props) =>
    props.isReadOnly ? props.theme.color.white3 : props.theme.color.white1};
  border: 1px solid
    ${(props) =>
      props.isReadOnly ? props.theme.color.gray3 : props.theme.color.gray4};
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};
  resize: none;

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: transparent;
    transition: 9999s ease-in-out 0s;
  }

  &::placeholder {
    color: ${(props) =>
      props.isBlock ? props.theme.color.deny : props.theme.color.gray4};
  }

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 1.1rem;
  }
`

const ImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
`

const RelativeDiv = styled.div`
  position: relative;
`

// const AddPhoto = styled(AddPhotoPlus)`
//   position: absolute;
//   cursor: pointer;
//   bottom: 0;
//   right: 0;
//   width: 60px;
//   height: 60px;
//   min-width: 60px;
//   max-width: 60px;
//   min-height: 60px;
//   max-height: 60px;

//   @media ${devices.phoneOnly} {
//     width: 50px;
//     height: 50px;
//     min-width: 50px;
//     max-width: 50px;
//     min-height: 50px;
//     max-height: 50px;
//   }

//   @media ${devices.tabletPortrait} {
//     width: 50px;
//     height: 50px;
//     min-width: 50px;
//     max-width: 50px;
//     min-height: 50px;
//     max-height: 50px;
//   }

//   @media ${devices.bigDesktop} {
//     width: 60px;
//     height: 60px;
//     min-width: 60px;
//     max-width: 60px;
//     min-height: 60px;
//     max-height: 60px;
//   }
// `;

const InsertImg = styled.div<{ readOnly: boolean }>`
  width: 150px;
  height: 150px;
  min-width: 150px;
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 1px ${(props) => props.theme.color.gray4};
  overflow: hidden;
  position: relative;
  background: ${(props) => props.theme.color.primary_main};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid ${(props) => props.theme.color.primary_main};
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};

  @media ${devices.phoneOnly} {
    width: 120px;
    height: 120px;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }

  @media ${devices.tabletPortrait} {
    width: 120px;
    height: 120px;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }

  @media ${devices.bigDesktop} {
    width: 150px;
    height: 150px;
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
  }
`

const InputImg = styled.input`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 98;
`

const BaseImg = styled.img`
  object-fit: fill;
  max-width: auto;
  max-height: 100%;
`

const SelectDDI = styled.select<{ isBlock?: boolean }>`
  color: ${(props) =>
    props.isBlock ? props.theme.color.deny : props.theme.color.text3};
  font-size: ${(props) => props.theme.font.normal};
  padding: 0 0.5rem;
  width: 80px;
  height: 40px;
  background: ${(props) => props.theme.color.white1};
  outline: ${(props) => (props.isBlock ? props.theme.color.deny : 'none')};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray3};
  box-shadow: 0px 1px 8px ${(props) => props.theme.color.white3};

  @media ${devices.phoneOnly} {
    height: 30px;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    height: 30px;
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    height: 40px;
    font-size: 1rem;
  }
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & p {
    padding-left: 0.5rem;
    color: ${(props) => props.theme.color.gray3};
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 20px;
    margin-right: 0.5rem;

    @media ${devices.phoneOnly} {
      font-size: 0.8rem;
    }

    @media ${devices.tabletPortrait} {
      font-size: 0.9rem;
    }

    @media ${devices.bigDesktop} {
      font-size: 1rem;
    }
  }
`

const EffectCircle = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px;
  border: 8px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: ${(props) => props.theme.color.primary_light};
  height: 10px;
  width: 10px;
  animation: ${slide} 1s infinite;
`

const Cross = styled(CrossIcon)`
  position: absolute;
  bottom: 15px;
  right: 10px;
  height: 20px;
  width: 20px;

  & line {
    stroke: ${(props) => props.theme.color.button_deny};
  }

  & path {
    stroke: ${(props) => props.theme.color.button_deny};
  }
`

const Check = styled(CheckIcon)`
  position: absolute;
  bottom: 15px;
  right: 10px;
  height: 20px;
  width: 20px;

  & path {
    stroke: ${(props) => props.theme.color.button_normal};
  }
`

interface FormProps {
  id?: string
  name?: string
  children: React.ReactNode
  label?: string
  leafPormade?: boolean
  value?: string
  maxWidth?: string
  display?: string
  isRow?: boolean
  isBlock?: boolean
}

interface Props {
  id?: string
  name?: string
  children?: React.ReactNode
  label?: string
  leafPormade?: boolean
  value?: string
  valueBoolean?: boolean
  valueTwo?: string
  placeholder?: string
  maxWidth?: string
  display?: string
  maxPercent?: number
  min?: string
  max?: string
  maxLength?: number
  maxValue?: string
  type?: any
  readOnly?: boolean
  required?: boolean
  isValid?: boolean
  isRow?: boolean
  plainPassword?: boolean
  defaultSelectValue?: string
  changeValue?: (value: string) => void
  changeValueBoolean?: (value: boolean) => void
  changeValueTwo?: (value: string) => void
  handlePlainPassword?: () => void
  handleSubmit?: () => void
  valueName?: (value: string) => void
  validBalance?: () => void
  avatar?: String | ArrayBuffer | null
}

interface PropsCEP {
  id?: string
  name?: string
  children?: React.ReactNode
  label?: string
  value?: string
  placeholder?: string
  readOnly?: boolean
  district?: string
  street?: string
  city?: string
  UF?: string
  required?: boolean
  isValid?: boolean
  isRow?: boolean
  maxWidth?: string
  changeValue?: (value: string) => void
  changeOtherValue?: (value: any) => void
}

const FormBase = ({
  isRow = false,
  isBlock = false,
  maxWidth,
  label,
  leafPormade,
  children
}: FormProps): JSX.Element => {
  let leafPosition = false
  if (label) {
    leafPosition = true
  } else {
    leafPosition = false
  }
  return (
    <Container
      isRow={isRow}
      maxWidth={maxWidth}
      leafPormade={leafPormade}
      label={leafPosition}
    >
      <Label isBlock={isBlock}>{label}</Label>
      {children}
    </Container>
  )
}

const InputText = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  type,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        type={type}
        isBlock={isInputBlock}
        title={value}
        value={value}
        placeholder={placeholder}
        minLength={1}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => changeValue!(event.target.value)}
        onKeyUp={() => validateField(value!)}
        onBlur={(event) => validateField(event.target.value)}
      />
    </FormBase>
  )
}

const InputCheckBox = ({
  id,
  name,
  label,
  valueBoolean,
  required,
  readOnly,
  isValid,
  placeholder,
  changeValueBoolean,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <div>
      <InputCheck
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={label}
        value={id}
        checked={valueBoolean}
        type='checkbox'
        placeholder={placeholder}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) =>
          changeValueBoolean!(event.target.checked ? true : false)
        }
        onBlur={(event) => validateField(event.target.value)}
      />
      <LabelCheck>{label}</LabelCheck>
    </div>
  )
}

const InputNumber = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={value}
        value={value ? String(value).replace(/\D/g, '') : value}
        placeholder={placeholder}
        minLength={1}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        pattern='([0-9])'
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) =>
          changeValue!(event.target.value.replace(/\D/g, ''))
        }
        onKeyUp={() => validateField(value!)}
        onBlur={(event) => validateField(event.target.value.replace(/\D/g, ''))}
      />
    </FormBase>
  )
}

const InputNumberDI = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  validBalance,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
        if (validBalance && value.length >= 12) {
          validBalance()
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={value}
        value={value ? formatDI(value) : value}
        placeholder={placeholder}
        minLength={1}
        maxLength={12}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        // pattern="([0-9])"
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) =>
          changeValue!(event.target.value.replace(/\D/g, ''))
        }
        onKeyUp={() => validateField(value!)}
        onBlur={(event) => validateField(event.target.value)}
      />
    </FormBase>
  )
}

const InputName = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } /*if (!/^[a-zA-Z\u00C0-\u00FF ]+$/i.test(value)) {
        setIsInputBlock(true)
      } else*/ else {
        setIsInputBlock(false)
      }
    }
  }

  const validateFieldBlur = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } /*if (!/^[a-zA-Z\u00C0-\u00FF ]+$/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o nome corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else */ else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={value}
        value={value}
        placeholder={placeholder}
        minLength={1}
        maxLength={maxLength}
        //pattern='[a-zA-Z\u00C0-\u00FF ]+'
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) =>
          changeValue!(event.target.value.replace(/^[0-9]+/g, ''))
        }
        onKeyUp={() => validateField(value!)}
        onBlur={(event) => validateFieldBlur(event.target.value)}
      />
    </FormBase>
  )
}

const InputPassword = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  plainPassword,
  changeValue,
  handlePlainPassword,
  handleSubmit,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type={plainPassword ? 'text' : 'password'}
        value={value}
        title={value}
        placeholder={placeholder}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onBlur={(event) => {
          validateField(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          //changeValue!(event.target.value.replace(/[^A-Za-z0-9]/g, ''))
          changeValue!(event.target.value)
        }}
        onKeyUp={(event) => {
          if (event.keyCode === 13 && value !== '') {
            if (handleSubmit) {
              handleSubmit()
              event.preventDefault()
            }
          }
        }}
      />
      <EyeButton
        title={plainPassword ? 'Esconder senha' : 'Mostrar senha'}
        type='button'
        onClick={() => (handlePlainPassword ? handlePlainPassword() : null)}
      >
        {plainPassword ? (
          <ViewOffIcon color={'primary.400'} />
        ) : (
          <ViewIcon color={'primary.400'} />
        )}
      </EyeButton>
    </FormBase>
  )
}

const InputSelect = ({
  id,
  name,
  children,
  label,
  value,
  maxWidth,
  required,
  placeholder,
  changeValue,
  readOnly,
  isValid,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      {readOnly !== true ? (
        <Select
          id={id}
          name={name}
          isBlock={isInputBlock}
          value={value}
          title={value}
          placeholder={placeholder}
          required={required}
          isReadOnly={readOnly}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value)
          }}
        >
          {children}
        </Select>
      ) : (
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={value}
          value={value}
          placeholder={placeholder}
          minLength={1}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => changeValue!(event.target.value)}
          onKeyUp={() => validateField(value!)}
          // onBlur={event => validateField(event.target.value)}
        />
      )}
    </FormBase>
  )
}

const InputTextArea = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  required,
  placeholder,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required}
      maxWidth={maxWidth}
    >
      <TextArea
        id={id}
        name={name}
        isBlock={isInputBlock}
        value={value}
        title={value}
        placeholder={placeholder}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onBlur={(event) => {
          validateField(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputTextFilter = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  handleSubmit,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        value={value}
        title={value}
        placeholder={placeholder}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onBlur={(event) => {
          validateField(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
      <Lupa onClick={() => (handleSubmit ? handleSubmit() : null)} />
    </FormBase>
  )
}

const InputDate = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  required,
  placeholder,
  changeValue,
  isRow,
  min,
  max,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validateFieldBlur = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/i.test(
          value
        )
      ) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha uma data valida!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='date'
        value={value ? value.substring(0, 10) : value}
        title={value}
        placeholder={placeholder}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        min={min}
        max={max}
        onBlur={(event) => {
          validateFieldBlur(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputDateNoWeekends = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  required,
  placeholder,
  changeValue,
  isRow,
  min,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validateFieldBlur = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/i.test(
          value
        )
      ) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha uma data valida!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const checkWeekends = (value: string) => {
    let day = new Date(value!).getUTCDay()
    if ([6, 0].includes(day)) {
      changeValue!('')
      dispatch(
        setGlobalMessage({
          message: 'Finais de semana não podem ser selecionados',
          type: 'WARN'
        })
      )
    }
  }

  // useEffect(() => {
  //   let day = new Date(data.currency_closure!).getUTCDay();
  //   if ([6, 0].includes(day)) {
  //     changeData({
  //       ...data,
  //       currency_closure: "",
  //     });
  //     dispatch(
  //       setGlobalMessage({
  //         message: "Finais de semana não podem ser selecionados",
  //         type: "WARN",
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.currency_closure]);

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='date'
        value={value ? value.substring(0, 10) : value}
        title={value}
        placeholder={placeholder}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        min={min}
        onBlur={(event) => {
          validateFieldBlur(event.target.value)
          checkWeekends(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputEmail = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  required,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const toast = useToast()

  const validateEmail = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
        // eslint-disable-next-line no-useless-escape
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validateEmailBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
        // eslint-disable-next-line no-useless-escape
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(value)) {
        setIsInputBlock(true)
        toast({
          title: 'E-mail Inválido!',
          position: 'bottom',
          description: 'Por favor, verifique os dados e tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        // dispatch(
        //   setGlobalMessage({
        //     message: "Preencha o E-mail corretamente!",
        //     type: "WARN",
        //   })
        // );
        //changeValue!("");
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='email'
        title={value}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={true}
        pattern='^[a-zA-Z0-9._+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$'
        onKeyUp={() => validateEmail(value!)}
        onBlur={(event) => {
          validateEmailBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputPhone = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validatePhone = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/(\d{4,})-(\d{4})$/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validatePhoneBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/(\d{4,})-(\d{4})$/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o numero de telefone corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='tel'
        title={value}
        maxLength={9}
        value={value ? formatPhone(value) : value}
        placeholder={placeholder}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='(\d{4,})-(\d{4})'
        onKeyUp={() => validatePhone(value!)}
        onBlur={(event) => {
          validatePhoneBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputDDDPhone = ({
  id,
  name,
  label,
  value,
  readOnly,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  return (
    <FormBase
      label={label}
      isRow={isRow}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        type='tel'
        title={value}
        maxLength={10}
        value={value ? formatCelPhone(value) : value}
        placeholder={placeholder}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='(\d{2,})'
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputCelPhone = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validatePhone = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/(\d{5,})-(\d{4})$/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validatePhoneBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/(\d{5,})-(\d{4})$/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o numero de celular corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='tel'
        title={value}
        maxLength={10}
        value={value ? formatCelPhone(value) : value}
        placeholder={placeholder}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='(\d{5,})-(\d{4})'
        onKeyUp={() => validatePhone(value!)}
        onBlur={(event) => {
          validatePhoneBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputDDIPhone = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validatePhone = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/\(\d{2,}\) \d{4,}-\d{4}$/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validatePhoneBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/\(\d{2,}\) \d{4,}-\d{4}$/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o numero de telefone corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }
  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <ItemRow>
        <SelectDDI>
          <option data-countryCode='DZ' value='213'>
            Algeria (+213)
          </option>
          <option data-countryCode='AD' value='376'>
            Andorra (+376)
          </option>
          <option data-countryCode='AO' value='244'>
            Angola (+244)
          </option>
          <option data-countryCode='AI' value='1264'>
            Anguilla (+1264)
          </option>
          <option data-countryCode='AG' value='1268'>
            Antigua &amp; Barbuda (+1268)
          </option>
          <option data-countryCode='AR' value='54'>
            Argentina (+54)
          </option>
          <option data-countryCode='AM' value='374'>
            Armenia (+374)
          </option>
          <option data-countryCode='AW' value='297'>
            Aruba (+297)
          </option>
          <option data-countryCode='AU' value='61'>
            Australia (+61)
          </option>
          <option data-countryCode='AT' value='43'>
            Austria (+43)
          </option>
          <option data-countryCode='AZ' value='994'>
            Azerbaijan (+994)
          </option>
          <option data-countryCode='BS' value='1242'>
            Bahamas (+1242)
          </option>
          <option data-countryCode='BH' value='973'>
            Bahrain (+973)
          </option>
          <option data-countryCode='BD' value='880'>
            Bangladesh (+880)
          </option>
          <option data-countryCode='BB' value='1246'>
            Barbados (+1246)
          </option>
          <option data-countryCode='BY' value='375'>
            Belarus (+375)
          </option>
          <option data-countryCode='BE' value='32'>
            Belgium (+32)
          </option>
          <option data-countryCode='BZ' value='501'>
            Belize (+501)
          </option>
          <option data-countryCode='BJ' value='229'>
            Benin (+229)
          </option>
          <option data-countryCode='BM' value='1441'>
            Bermuda (+1441)
          </option>
          <option data-countryCode='BT' value='975'>
            Bhutan (+975)
          </option>
          <option data-countryCode='BO' value='591'>
            Bolivia (+591)
          </option>
          <option data-countryCode='BA' value='387'>
            Bosnia Herzegovina (+387)
          </option>
          <option data-countryCode='BW' value='267'>
            Botswana (+267)
          </option>
          <option data-countryCode='BR' value='55' selected>
            Brasil (+55)
          </option>
          <option data-countryCode='BN' value='673'>
            Brunei (+673)
          </option>
          <option data-countryCode='BG' value='359'>
            Bulgaria (+359)
          </option>
          <option data-countryCode='BF' value='226'>
            Burkina Faso (+226)
          </option>
          <option data-countryCode='BI' value='257'>
            Burundi (+257)
          </option>
          <option data-countryCode='KH' value='855'>
            Cambodia (+855)
          </option>
          <option data-countryCode='CM' value='237'>
            Cameroon (+237)
          </option>
          <option data-countryCode='CA' value='1'>
            Canada (+1)
          </option>
          <option data-countryCode='CV' value='238'>
            Cape Verde Islands (+238)
          </option>
          <option data-countryCode='KY' value='1345'>
            Cayman Islands (+1345)
          </option>
          <option data-countryCode='CF' value='236'>
            Central African Republic (+236)
          </option>
          <option data-countryCode='CL' value='56'>
            Chile (+56)
          </option>
          <option data-countryCode='CN' value='86'>
            China (+86)
          </option>
          <option data-countryCode='CO' value='57'>
            Colombia (+57)
          </option>
          <option data-countryCode='KM' value='269'>
            Comoros (+269)
          </option>
          <option data-countryCode='CG' value='242'>
            Congo (+242)
          </option>
          <option data-countryCode='CK' value='682'>
            Cook Islands (+682)
          </option>
          <option data-countryCode='CR' value='506'>
            Costa Rica (+506)
          </option>
          <option data-countryCode='HR' value='385'>
            Croatia (+385)
          </option>
          <option data-countryCode='CU' value='53'>
            Cuba (+53)
          </option>
          <option data-countryCode='CY' value='90392'>
            Cyprus North (+90392)
          </option>
          <option data-countryCode='CY' value='357'>
            Cyprus South (+357)
          </option>
          <option data-countryCode='CZ' value='42'>
            Czech Republic (+42)
          </option>
          <option data-countryCode='DK' value='45'>
            Denmark (+45)
          </option>
          <option data-countryCode='DJ' value='253'>
            Djibouti (+253)
          </option>
          <option data-countryCode='DM' value='1809'>
            Dominica (+1809)
          </option>
          <option data-countryCode='DO' value='1809'>
            Dominican Republic (+1809)
          </option>
          <option data-countryCode='EC' value='593'>
            Ecuador (+593)
          </option>
          <option data-countryCode='EG' value='20'>
            Egypt (+20)
          </option>
          <option data-countryCode='SV' value='503'>
            El Salvador (+503)
          </option>
          <option data-countryCode='GQ' value='240'>
            Equatorial Guinea (+240)
          </option>
          <option data-countryCode='ER' value='291'>
            Eritrea (+291)
          </option>
          <option data-countryCode='EE' value='372'>
            Estonia (+372)
          </option>
          <option data-countryCode='ET' value='251'>
            Ethiopia (+251)
          </option>
          <option data-countryCode='FK' value='500'>
            Falkland Islands (+500)
          </option>
          <option data-countryCode='FO' value='298'>
            Faroe Islands (+298)
          </option>
          <option data-countryCode='FJ' value='679'>
            Fiji (+679)
          </option>
          <option data-countryCode='FI' value='358'>
            Finland (+358)
          </option>
          <option data-countryCode='FR' value='33'>
            France (+33)
          </option>
          <option data-countryCode='GF' value='594'>
            French Guiana (+594)
          </option>
          <option data-countryCode='PF' value='689'>
            French Polynesia (+689)
          </option>
          <option data-countryCode='GA' value='241'>
            Gabon (+241)
          </option>
          <option data-countryCode='GM' value='220'>
            Gambia (+220)
          </option>
          <option data-countryCode='GE' value='7880'>
            Georgia (+7880)
          </option>
          <option data-countryCode='DE' value='49'>
            Germany (+49)
          </option>
          <option data-countryCode='GH' value='233'>
            Ghana (+233)
          </option>
          <option data-countryCode='GI' value='350'>
            Gibraltar (+350)
          </option>
          <option data-countryCode='GR' value='30'>
            Greece (+30)
          </option>
          <option data-countryCode='GL' value='299'>
            Greenland (+299)
          </option>
          <option data-countryCode='GD' value='1473'>
            Grenada (+1473)
          </option>
          <option data-countryCode='GP' value='590'>
            Guadeloupe (+590)
          </option>
          <option data-countryCode='GU' value='671'>
            Guam (+671)
          </option>
          <option data-countryCode='GT' value='502'>
            Guatemala (+502)
          </option>
          <option data-countryCode='GN' value='224'>
            Guinea (+224)
          </option>
          <option data-countryCode='GW' value='245'>
            Guinea - Bissau (+245)
          </option>
          <option data-countryCode='GY' value='592'>
            Guyana (+592)
          </option>
          <option data-countryCode='HT' value='509'>
            Haiti (+509)
          </option>
          <option data-countryCode='HN' value='504'>
            Honduras (+504)
          </option>
          <option data-countryCode='HK' value='852'>
            Hong Kong (+852)
          </option>
          <option data-countryCode='HU' value='36'>
            Hungary (+36)
          </option>
          <option data-countryCode='IS' value='354'>
            Iceland (+354)
          </option>
          <option data-countryCode='IN' value='91'>
            India (+91)
          </option>
          <option data-countryCode='ID' value='62'>
            Indonesia (+62)
          </option>
          <option data-countryCode='IR' value='98'>
            Iran (+98)
          </option>
          <option data-countryCode='IQ' value='964'>
            Iraq (+964)
          </option>
          <option data-countryCode='IE' value='353'>
            Ireland (+353)
          </option>
          <option data-countryCode='IL' value='972'>
            Israel (+972)
          </option>
          <option data-countryCode='IT' value='39'>
            Italy (+39)
          </option>
          <option data-countryCode='JM' value='1876'>
            Jamaica (+1876)
          </option>
          <option data-countryCode='JP' value='81'>
            Japan (+81)
          </option>
          <option data-countryCode='JO' value='962'>
            Jordan (+962)
          </option>
          <option data-countryCode='KZ' value='7'>
            Kazakhstan (+7)
          </option>
          <option data-countryCode='KE' value='254'>
            Kenya (+254)
          </option>
          <option data-countryCode='KI' value='686'>
            Kiribati (+686)
          </option>
          <option data-countryCode='KP' value='850'>
            Korea North (+850)
          </option>
          <option data-countryCode='KR' value='82'>
            Korea South (+82)
          </option>
          <option data-countryCode='KW' value='965'>
            Kuwait (+965)
          </option>
          <option data-countryCode='KG' value='996'>
            Kyrgyzstan (+996)
          </option>
          <option data-countryCode='LA' value='856'>
            Laos (+856)
          </option>
          <option data-countryCode='LV' value='371'>
            Latvia (+371)
          </option>
          <option data-countryCode='LB' value='961'>
            Lebanon (+961)
          </option>
          <option data-countryCode='LS' value='266'>
            Lesotho (+266)
          </option>
          <option data-countryCode='LR' value='231'>
            Liberia (+231)
          </option>
          <option data-countryCode='LY' value='218'>
            Libya (+218)
          </option>
          <option data-countryCode='LI' value='417'>
            Liechtenstein (+417)
          </option>
          <option data-countryCode='LT' value='370'>
            Lithuania (+370)
          </option>
          <option data-countryCode='LU' value='352'>
            Luxembourg (+352)
          </option>
          <option data-countryCode='MO' value='853'>
            Macao (+853)
          </option>
          <option data-countryCode='MK' value='389'>
            Macedonia (+389)
          </option>
          <option data-countryCode='MG' value='261'>
            Madagascar (+261)
          </option>
          <option data-countryCode='MW' value='265'>
            Malawi (+265)
          </option>
          <option data-countryCode='MY' value='60'>
            Malaysia (+60)
          </option>
          <option data-countryCode='MV' value='960'>
            Maldives (+960)
          </option>
          <option data-countryCode='ML' value='223'>
            Mali (+223)
          </option>
          <option data-countryCode='MT' value='356'>
            Malta (+356)
          </option>
          <option data-countryCode='MH' value='692'>
            Marshall Islands (+692)
          </option>
          <option data-countryCode='MQ' value='596'>
            Martinique (+596)
          </option>
          <option data-countryCode='MR' value='222'>
            Mauritania (+222)
          </option>
          <option data-countryCode='YT' value='269'>
            Mayotte (+269)
          </option>
          <option data-countryCode='MX' value='52'>
            Mexico (+52)
          </option>
          <option data-countryCode='FM' value='691'>
            Micronesia (+691)
          </option>
          <option data-countryCode='MD' value='373'>
            Moldova (+373)
          </option>
          <option data-countryCode='MC' value='377'>
            Monaco (+377)
          </option>
          <option data-countryCode='MN' value='976'>
            Mongolia (+976)
          </option>
          <option data-countryCode='MS' value='1664'>
            Montserrat (+1664)
          </option>
          <option data-countryCode='MA' value='212'>
            Morocco (+212)
          </option>
          <option data-countryCode='MZ' value='258'>
            Mozambique (+258)
          </option>
          <option data-countryCode='MN' value='95'>
            Myanmar (+95)
          </option>
          <option data-countryCode='NA' value='264'>
            Namibia (+264)
          </option>
          <option data-countryCode='NR' value='674'>
            Nauru (+674)
          </option>
          <option data-countryCode='NP' value='977'>
            Nepal (+977)
          </option>
          <option data-countryCode='NL' value='31'>
            Netherlands (+31)
          </option>
          <option data-countryCode='NC' value='687'>
            New Caledonia (+687)
          </option>
          <option data-countryCode='NZ' value='64'>
            New Zealand (+64)
          </option>
          <option data-countryCode='NI' value='505'>
            Nicaragua (+505)
          </option>
          <option data-countryCode='NE' value='227'>
            Niger (+227)
          </option>
          <option data-countryCode='NG' value='234'>
            Nigeria (+234)
          </option>
          <option data-countryCode='NU' value='683'>
            Niue (+683)
          </option>
          <option data-countryCode='NF' value='672'>
            Norfolk Islands (+672)
          </option>
          <option data-countryCode='NP' value='670'>
            Northern Marianas (+670)
          </option>
          <option data-countryCode='NO' value='47'>
            Norway (+47)
          </option>
          <option data-countryCode='OM' value='968'>
            Oman (+968)
          </option>
          <option data-countryCode='PW' value='680'>
            Palau (+680)
          </option>
          <option data-countryCode='PA' value='507'>
            Panama (+507)
          </option>
          <option data-countryCode='PG' value='675'>
            Papua New Guinea (+675)
          </option>
          <option data-countryCode='PY' value='595'>
            Paraguay (+595)
          </option>
          <option data-countryCode='PE' value='51'>
            Peru (+51)
          </option>
          <option data-countryCode='PH' value='63'>
            Philippines (+63)
          </option>
          <option data-countryCode='PL' value='48'>
            Poland (+48)
          </option>
          <option data-countryCode='PT' value='351'>
            Portugal (+351)
          </option>
          <option data-countryCode='PR' value='1787'>
            Puerto Rico (+1787)
          </option>
          <option data-countryCode='QA' value='974'>
            Qatar (+974)
          </option>
          <option data-countryCode='RE' value='262'>
            Reunion (+262)
          </option>
          <option data-countryCode='RO' value='40'>
            Romania (+40)
          </option>
          <option data-countryCode='RU' value='7'>
            Russia (+7)
          </option>
          <option data-countryCode='RW' value='250'>
            Rwanda (+250)
          </option>
          <option data-countryCode='SM' value='378'>
            San Marino (+378)
          </option>
          <option data-countryCode='ST' value='239'>
            Sao Tome &amp; Principe (+239)
          </option>
          <option data-countryCode='SA' value='966'>
            Saudi Arabia (+966)
          </option>
          <option data-countryCode='SN' value='221'>
            Senegal (+221)
          </option>
          <option data-countryCode='CS' value='381'>
            Serbia (+381)
          </option>
          <option data-countryCode='SC' value='248'>
            Seychelles (+248)
          </option>
          <option data-countryCode='SL' value='232'>
            Sierra Leone (+232)
          </option>
          <option data-countryCode='SG' value='65'>
            Singapore (+65)
          </option>
          <option data-countryCode='SK' value='421'>
            Slovak Republic (+421)
          </option>
          <option data-countryCode='SI' value='386'>
            Slovenia (+386)
          </option>
          <option data-countryCode='SB' value='677'>
            Solomon Islands (+677)
          </option>
          <option data-countryCode='SO' value='252'>
            Somalia (+252)
          </option>
          <option data-countryCode='ZA' value='27'>
            South Africa (+27)
          </option>
          <option data-countryCode='ES' value='34'>
            Spain (+34)
          </option>
          <option data-countryCode='LK' value='94'>
            Sri Lanka (+94)
          </option>
          <option data-countryCode='SH' value='290'>
            St. Helena (+290)
          </option>
          <option data-countryCode='KN' value='1869'>
            St. Kitts (+1869)
          </option>
          <option data-countryCode='SC' value='1758'>
            St. Lucia (+1758)
          </option>
          <option data-countryCode='SD' value='249'>
            Sudan (+249)
          </option>
          <option data-countryCode='SR' value='597'>
            Suriname (+597)
          </option>
          <option data-countryCode='SZ' value='268'>
            Swaziland (+268)
          </option>
          <option data-countryCode='SE' value='46'>
            Sweden (+46)
          </option>
          <option data-countryCode='CH' value='41'>
            Switzerland (+41)
          </option>
          <option data-countryCode='SI' value='963'>
            Syria (+963)
          </option>
          <option data-countryCode='TW' value='886'>
            Taiwan (+886)
          </option>
          <option data-countryCode='TJ' value='7'>
            Tajikstan (+7)
          </option>
          <option data-countryCode='TH' value='66'>
            Thailand (+66)
          </option>
          <option data-countryCode='TG' value='228'>
            Togo (+228)
          </option>
          <option data-countryCode='TO' value='676'>
            Tonga (+676)
          </option>
          <option data-countryCode='TT' value='1868'>
            Trinidad &amp; Tobago (+1868)
          </option>
          <option data-countryCode='TN' value='216'>
            Tunisia (+216)
          </option>
          <option data-countryCode='TR' value='90'>
            Turkey (+90)
          </option>
          <option data-countryCode='TM' value='7'>
            Turkmenistan (+7)
          </option>
          <option data-countryCode='TM' value='993'>
            Turkmenistan (+993)
          </option>
          <option data-countryCode='TC' value='1649'>
            Turks &amp; Caicos Islands (+1649)
          </option>
          <option data-countryCode='TV' value='688'>
            Tuvalu (+688)
          </option>
          <option data-countryCode='UG' value='256'>
            Uganda (+256)
          </option>
          <option data-countryCode='GB' value='44'>
            UK (+44)
          </option>
          <option data-countryCode='UA' value='380'>
            Ukraine (+380)
          </option>
          <option data-countryCode='AE' value='971'>
            United Arab Emirates (+971)
          </option>
          <option data-countryCode='UY' value='598'>
            Uruguay (+598)
          </option>
          <option data-countryCode='US' value='1'>
            USA (+1)
          </option>
          <option data-countryCode='UZ' value='7'>
            Uzbekistan (+7)
          </option>
          <option data-countryCode='VU' value='678'>
            Vanuatu (+678)
          </option>
          <option data-countryCode='VA' value='379'>
            Vatican City (+379)
          </option>
          <option data-countryCode='VE' value='58'>
            Venezuela (+58)
          </option>
          <option data-countryCode='VN' value='84'>
            Vietnam (+84)
          </option>
          <option data-countryCode='VG' value='84'>
            Virgin Islands - British (+1284)
          </option>
          <option data-countryCode='VI' value='84'>
            Virgin Islands - US (+1340)
          </option>
          <option data-countryCode='WF' value='681'>
            Wallis &amp; Futuna (+681)
          </option>
          <option data-countryCode='YE' value='969'>
            Yemen (North)(+969)
          </option>
          <option data-countryCode='YE' value='967'>
            Yemen (South)(+967)
          </option>
          <option data-countryCode='ZM' value='260'>
            Zambia (+260)
          </option>
          <option data-countryCode='ZW' value='263'>
            Zimbabwe (+263)
          </option>
        </SelectDDI>
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          type='tel'
          title={value}
          maxLength={14}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          isReadOnly={readOnly}
          required={required ? true : false}
          pattern='\(\d{2,}\) \d{4,}-\d{4}$'
          onKeyUp={() => validatePhone(value!)}
          onBlur={(event) => {
            validatePhoneBlur(event.target.value)
          }}
          onFocus={() => {
            setIsInputBlock(false)
          }}
          onChange={(event) => {
            changeValue!(formatPhone(event.target.value))
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputDDICelPhone = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validatePhone = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/\(\d{2,}\) \d{5,}-\d{4}$/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validatePhoneBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/\(\d{2,}\) \d{5,}-\d{4}$/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o numero de celular corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <ItemRow>
        <SelectDDI>
          <option data-countryCode='DZ' value='213'>
            Algeria (+213)
          </option>
          <option data-countryCode='AD' value='376'>
            Andorra (+376)
          </option>
          <option data-countryCode='AO' value='244'>
            Angola (+244)
          </option>
          <option data-countryCode='AI' value='1264'>
            Anguilla (+1264)
          </option>
          <option data-countryCode='AG' value='1268'>
            Antigua &amp; Barbuda (+1268)
          </option>
          <option data-countryCode='AR' value='54'>
            Argentina (+54)
          </option>
          <option data-countryCode='AM' value='374'>
            Armenia (+374)
          </option>
          <option data-countryCode='AW' value='297'>
            Aruba (+297)
          </option>
          <option data-countryCode='AU' value='61'>
            Australia (+61)
          </option>
          <option data-countryCode='AT' value='43'>
            Austria (+43)
          </option>
          <option data-countryCode='AZ' value='994'>
            Azerbaijan (+994)
          </option>
          <option data-countryCode='BS' value='1242'>
            Bahamas (+1242)
          </option>
          <option data-countryCode='BH' value='973'>
            Bahrain (+973)
          </option>
          <option data-countryCode='BD' value='880'>
            Bangladesh (+880)
          </option>
          <option data-countryCode='BB' value='1246'>
            Barbados (+1246)
          </option>
          <option data-countryCode='BY' value='375'>
            Belarus (+375)
          </option>
          <option data-countryCode='BE' value='32'>
            Belgium (+32)
          </option>
          <option data-countryCode='BZ' value='501'>
            Belize (+501)
          </option>
          <option data-countryCode='BJ' value='229'>
            Benin (+229)
          </option>
          <option data-countryCode='BM' value='1441'>
            Bermuda (+1441)
          </option>
          <option data-countryCode='BT' value='975'>
            Bhutan (+975)
          </option>
          <option data-countryCode='BO' value='591'>
            Bolivia (+591)
          </option>
          <option data-countryCode='BA' value='387'>
            Bosnia Herzegovina (+387)
          </option>
          <option data-countryCode='BW' value='267'>
            Botswana (+267)
          </option>
          <option data-countryCode='BR' value='55' selected>
            Brasil (+55)
          </option>
          <option data-countryCode='BN' value='673'>
            Brunei (+673)
          </option>
          <option data-countryCode='BG' value='359'>
            Bulgaria (+359)
          </option>
          <option data-countryCode='BF' value='226'>
            Burkina Faso (+226)
          </option>
          <option data-countryCode='BI' value='257'>
            Burundi (+257)
          </option>
          <option data-countryCode='KH' value='855'>
            Cambodia (+855)
          </option>
          <option data-countryCode='CM' value='237'>
            Cameroon (+237)
          </option>
          <option data-countryCode='CA' value='1'>
            Canada (+1)
          </option>
          <option data-countryCode='CV' value='238'>
            Cape Verde Islands (+238)
          </option>
          <option data-countryCode='KY' value='1345'>
            Cayman Islands (+1345)
          </option>
          <option data-countryCode='CF' value='236'>
            Central African Republic (+236)
          </option>
          <option data-countryCode='CL' value='56'>
            Chile (+56)
          </option>
          <option data-countryCode='CN' value='86'>
            China (+86)
          </option>
          <option data-countryCode='CO' value='57'>
            Colombia (+57)
          </option>
          <option data-countryCode='KM' value='269'>
            Comoros (+269)
          </option>
          <option data-countryCode='CG' value='242'>
            Congo (+242)
          </option>
          <option data-countryCode='CK' value='682'>
            Cook Islands (+682)
          </option>
          <option data-countryCode='CR' value='506'>
            Costa Rica (+506)
          </option>
          <option data-countryCode='HR' value='385'>
            Croatia (+385)
          </option>
          <option data-countryCode='CU' value='53'>
            Cuba (+53)
          </option>
          <option data-countryCode='CY' value='90392'>
            Cyprus North (+90392)
          </option>
          <option data-countryCode='CY' value='357'>
            Cyprus South (+357)
          </option>
          <option data-countryCode='CZ' value='42'>
            Czech Republic (+42)
          </option>
          <option data-countryCode='DK' value='45'>
            Denmark (+45)
          </option>
          <option data-countryCode='DJ' value='253'>
            Djibouti (+253)
          </option>
          <option data-countryCode='DM' value='1809'>
            Dominica (+1809)
          </option>
          <option data-countryCode='DO' value='1809'>
            Dominican Republic (+1809)
          </option>
          <option data-countryCode='EC' value='593'>
            Ecuador (+593)
          </option>
          <option data-countryCode='EG' value='20'>
            Egypt (+20)
          </option>
          <option data-countryCode='SV' value='503'>
            El Salvador (+503)
          </option>
          <option data-countryCode='GQ' value='240'>
            Equatorial Guinea (+240)
          </option>
          <option data-countryCode='ER' value='291'>
            Eritrea (+291)
          </option>
          <option data-countryCode='EE' value='372'>
            Estonia (+372)
          </option>
          <option data-countryCode='ET' value='251'>
            Ethiopia (+251)
          </option>
          <option data-countryCode='FK' value='500'>
            Falkland Islands (+500)
          </option>
          <option data-countryCode='FO' value='298'>
            Faroe Islands (+298)
          </option>
          <option data-countryCode='FJ' value='679'>
            Fiji (+679)
          </option>
          <option data-countryCode='FI' value='358'>
            Finland (+358)
          </option>
          <option data-countryCode='FR' value='33'>
            France (+33)
          </option>
          <option data-countryCode='GF' value='594'>
            French Guiana (+594)
          </option>
          <option data-countryCode='PF' value='689'>
            French Polynesia (+689)
          </option>
          <option data-countryCode='GA' value='241'>
            Gabon (+241)
          </option>
          <option data-countryCode='GM' value='220'>
            Gambia (+220)
          </option>
          <option data-countryCode='GE' value='7880'>
            Georgia (+7880)
          </option>
          <option data-countryCode='DE' value='49'>
            Germany (+49)
          </option>
          <option data-countryCode='GH' value='233'>
            Ghana (+233)
          </option>
          <option data-countryCode='GI' value='350'>
            Gibraltar (+350)
          </option>
          <option data-countryCode='GR' value='30'>
            Greece (+30)
          </option>
          <option data-countryCode='GL' value='299'>
            Greenland (+299)
          </option>
          <option data-countryCode='GD' value='1473'>
            Grenada (+1473)
          </option>
          <option data-countryCode='GP' value='590'>
            Guadeloupe (+590)
          </option>
          <option data-countryCode='GU' value='671'>
            Guam (+671)
          </option>
          <option data-countryCode='GT' value='502'>
            Guatemala (+502)
          </option>
          <option data-countryCode='GN' value='224'>
            Guinea (+224)
          </option>
          <option data-countryCode='GW' value='245'>
            Guinea - Bissau (+245)
          </option>
          <option data-countryCode='GY' value='592'>
            Guyana (+592)
          </option>
          <option data-countryCode='HT' value='509'>
            Haiti (+509)
          </option>
          <option data-countryCode='HN' value='504'>
            Honduras (+504)
          </option>
          <option data-countryCode='HK' value='852'>
            Hong Kong (+852)
          </option>
          <option data-countryCode='HU' value='36'>
            Hungary (+36)
          </option>
          <option data-countryCode='IS' value='354'>
            Iceland (+354)
          </option>
          <option data-countryCode='IN' value='91'>
            India (+91)
          </option>
          <option data-countryCode='ID' value='62'>
            Indonesia (+62)
          </option>
          <option data-countryCode='IR' value='98'>
            Iran (+98)
          </option>
          <option data-countryCode='IQ' value='964'>
            Iraq (+964)
          </option>
          <option data-countryCode='IE' value='353'>
            Ireland (+353)
          </option>
          <option data-countryCode='IL' value='972'>
            Israel (+972)
          </option>
          <option data-countryCode='IT' value='39'>
            Italy (+39)
          </option>
          <option data-countryCode='JM' value='1876'>
            Jamaica (+1876)
          </option>
          <option data-countryCode='JP' value='81'>
            Japan (+81)
          </option>
          <option data-countryCode='JO' value='962'>
            Jordan (+962)
          </option>
          <option data-countryCode='KZ' value='7'>
            Kazakhstan (+7)
          </option>
          <option data-countryCode='KE' value='254'>
            Kenya (+254)
          </option>
          <option data-countryCode='KI' value='686'>
            Kiribati (+686)
          </option>
          <option data-countryCode='KP' value='850'>
            Korea North (+850)
          </option>
          <option data-countryCode='KR' value='82'>
            Korea South (+82)
          </option>
          <option data-countryCode='KW' value='965'>
            Kuwait (+965)
          </option>
          <option data-countryCode='KG' value='996'>
            Kyrgyzstan (+996)
          </option>
          <option data-countryCode='LA' value='856'>
            Laos (+856)
          </option>
          <option data-countryCode='LV' value='371'>
            Latvia (+371)
          </option>
          <option data-countryCode='LB' value='961'>
            Lebanon (+961)
          </option>
          <option data-countryCode='LS' value='266'>
            Lesotho (+266)
          </option>
          <option data-countryCode='LR' value='231'>
            Liberia (+231)
          </option>
          <option data-countryCode='LY' value='218'>
            Libya (+218)
          </option>
          <option data-countryCode='LI' value='417'>
            Liechtenstein (+417)
          </option>
          <option data-countryCode='LT' value='370'>
            Lithuania (+370)
          </option>
          <option data-countryCode='LU' value='352'>
            Luxembourg (+352)
          </option>
          <option data-countryCode='MO' value='853'>
            Macao (+853)
          </option>
          <option data-countryCode='MK' value='389'>
            Macedonia (+389)
          </option>
          <option data-countryCode='MG' value='261'>
            Madagascar (+261)
          </option>
          <option data-countryCode='MW' value='265'>
            Malawi (+265)
          </option>
          <option data-countryCode='MY' value='60'>
            Malaysia (+60)
          </option>
          <option data-countryCode='MV' value='960'>
            Maldives (+960)
          </option>
          <option data-countryCode='ML' value='223'>
            Mali (+223)
          </option>
          <option data-countryCode='MT' value='356'>
            Malta (+356)
          </option>
          <option data-countryCode='MH' value='692'>
            Marshall Islands (+692)
          </option>
          <option data-countryCode='MQ' value='596'>
            Martinique (+596)
          </option>
          <option data-countryCode='MR' value='222'>
            Mauritania (+222)
          </option>
          <option data-countryCode='YT' value='269'>
            Mayotte (+269)
          </option>
          <option data-countryCode='MX' value='52'>
            Mexico (+52)
          </option>
          <option data-countryCode='FM' value='691'>
            Micronesia (+691)
          </option>
          <option data-countryCode='MD' value='373'>
            Moldova (+373)
          </option>
          <option data-countryCode='MC' value='377'>
            Monaco (+377)
          </option>
          <option data-countryCode='MN' value='976'>
            Mongolia (+976)
          </option>
          <option data-countryCode='MS' value='1664'>
            Montserrat (+1664)
          </option>
          <option data-countryCode='MA' value='212'>
            Morocco (+212)
          </option>
          <option data-countryCode='MZ' value='258'>
            Mozambique (+258)
          </option>
          <option data-countryCode='MN' value='95'>
            Myanmar (+95)
          </option>
          <option data-countryCode='NA' value='264'>
            Namibia (+264)
          </option>
          <option data-countryCode='NR' value='674'>
            Nauru (+674)
          </option>
          <option data-countryCode='NP' value='977'>
            Nepal (+977)
          </option>
          <option data-countryCode='NL' value='31'>
            Netherlands (+31)
          </option>
          <option data-countryCode='NC' value='687'>
            New Caledonia (+687)
          </option>
          <option data-countryCode='NZ' value='64'>
            New Zealand (+64)
          </option>
          <option data-countryCode='NI' value='505'>
            Nicaragua (+505)
          </option>
          <option data-countryCode='NE' value='227'>
            Niger (+227)
          </option>
          <option data-countryCode='NG' value='234'>
            Nigeria (+234)
          </option>
          <option data-countryCode='NU' value='683'>
            Niue (+683)
          </option>
          <option data-countryCode='NF' value='672'>
            Norfolk Islands (+672)
          </option>
          <option data-countryCode='NP' value='670'>
            Northern Marianas (+670)
          </option>
          <option data-countryCode='NO' value='47'>
            Norway (+47)
          </option>
          <option data-countryCode='OM' value='968'>
            Oman (+968)
          </option>
          <option data-countryCode='PW' value='680'>
            Palau (+680)
          </option>
          <option data-countryCode='PA' value='507'>
            Panama (+507)
          </option>
          <option data-countryCode='PG' value='675'>
            Papua New Guinea (+675)
          </option>
          <option data-countryCode='PY' value='595'>
            Paraguay (+595)
          </option>
          <option data-countryCode='PE' value='51'>
            Peru (+51)
          </option>
          <option data-countryCode='PH' value='63'>
            Philippines (+63)
          </option>
          <option data-countryCode='PL' value='48'>
            Poland (+48)
          </option>
          <option data-countryCode='PT' value='351'>
            Portugal (+351)
          </option>
          <option data-countryCode='PR' value='1787'>
            Puerto Rico (+1787)
          </option>
          <option data-countryCode='QA' value='974'>
            Qatar (+974)
          </option>
          <option data-countryCode='RE' value='262'>
            Reunion (+262)
          </option>
          <option data-countryCode='RO' value='40'>
            Romania (+40)
          </option>
          <option data-countryCode='RU' value='7'>
            Russia (+7)
          </option>
          <option data-countryCode='RW' value='250'>
            Rwanda (+250)
          </option>
          <option data-countryCode='SM' value='378'>
            San Marino (+378)
          </option>
          <option data-countryCode='ST' value='239'>
            Sao Tome &amp; Principe (+239)
          </option>
          <option data-countryCode='SA' value='966'>
            Saudi Arabia (+966)
          </option>
          <option data-countryCode='SN' value='221'>
            Senegal (+221)
          </option>
          <option data-countryCode='CS' value='381'>
            Serbia (+381)
          </option>
          <option data-countryCode='SC' value='248'>
            Seychelles (+248)
          </option>
          <option data-countryCode='SL' value='232'>
            Sierra Leone (+232)
          </option>
          <option data-countryCode='SG' value='65'>
            Singapore (+65)
          </option>
          <option data-countryCode='SK' value='421'>
            Slovak Republic (+421)
          </option>
          <option data-countryCode='SI' value='386'>
            Slovenia (+386)
          </option>
          <option data-countryCode='SB' value='677'>
            Solomon Islands (+677)
          </option>
          <option data-countryCode='SO' value='252'>
            Somalia (+252)
          </option>
          <option data-countryCode='ZA' value='27'>
            South Africa (+27)
          </option>
          <option data-countryCode='ES' value='34'>
            Spain (+34)
          </option>
          <option data-countryCode='LK' value='94'>
            Sri Lanka (+94)
          </option>
          <option data-countryCode='SH' value='290'>
            St. Helena (+290)
          </option>
          <option data-countryCode='KN' value='1869'>
            St. Kitts (+1869)
          </option>
          <option data-countryCode='SC' value='1758'>
            St. Lucia (+1758)
          </option>
          <option data-countryCode='SD' value='249'>
            Sudan (+249)
          </option>
          <option data-countryCode='SR' value='597'>
            Suriname (+597)
          </option>
          <option data-countryCode='SZ' value='268'>
            Swaziland (+268)
          </option>
          <option data-countryCode='SE' value='46'>
            Sweden (+46)
          </option>
          <option data-countryCode='CH' value='41'>
            Switzerland (+41)
          </option>
          <option data-countryCode='SI' value='963'>
            Syria (+963)
          </option>
          <option data-countryCode='TW' value='886'>
            Taiwan (+886)
          </option>
          <option data-countryCode='TJ' value='7'>
            Tajikstan (+7)
          </option>
          <option data-countryCode='TH' value='66'>
            Thailand (+66)
          </option>
          <option data-countryCode='TG' value='228'>
            Togo (+228)
          </option>
          <option data-countryCode='TO' value='676'>
            Tonga (+676)
          </option>
          <option data-countryCode='TT' value='1868'>
            Trinidad &amp; Tobago (+1868)
          </option>
          <option data-countryCode='TN' value='216'>
            Tunisia (+216)
          </option>
          <option data-countryCode='TR' value='90'>
            Turkey (+90)
          </option>
          <option data-countryCode='TM' value='7'>
            Turkmenistan (+7)
          </option>
          <option data-countryCode='TM' value='993'>
            Turkmenistan (+993)
          </option>
          <option data-countryCode='TC' value='1649'>
            Turks &amp; Caicos Islands (+1649)
          </option>
          <option data-countryCode='TV' value='688'>
            Tuvalu (+688)
          </option>
          <option data-countryCode='UG' value='256'>
            Uganda (+256)
          </option>
          <option data-countryCode='GB' value='44'>
            UK (+44)
          </option>
          <option data-countryCode='UA' value='380'>
            Ukraine (+380)
          </option>
          <option data-countryCode='AE' value='971'>
            United Arab Emirates (+971)
          </option>
          <option data-countryCode='UY' value='598'>
            Uruguay (+598)
          </option>
          <option data-countryCode='US' value='1'>
            USA (+1)
          </option>
          <option data-countryCode='UZ' value='7'>
            Uzbekistan (+7)
          </option>
          <option data-countryCode='VU' value='678'>
            Vanuatu (+678)
          </option>
          <option data-countryCode='VA' value='379'>
            Vatican City (+379)
          </option>
          <option data-countryCode='VE' value='58'>
            Venezuela (+58)
          </option>
          <option data-countryCode='VN' value='84'>
            Vietnam (+84)
          </option>
          <option data-countryCode='VG' value='84'>
            Virgin Islands - British (+1284)
          </option>
          <option data-countryCode='VI' value='84'>
            Virgin Islands - US (+1340)
          </option>
          <option data-countryCode='WF' value='681'>
            Wallis &amp; Futuna (+681)
          </option>
          <option data-countryCode='YE' value='969'>
            Yemen (North)(+969)
          </option>
          <option data-countryCode='YE' value='967'>
            Yemen (South)(+967)
          </option>
          <option data-countryCode='ZM' value='260'>
            Zambia (+260)
          </option>
          <option data-countryCode='ZW' value='263'>
            Zimbabwe (+263)
          </option>
        </SelectDDI>
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          type='tel'
          title={value}
          maxLength={15}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          isReadOnly={readOnly}
          required={required ? true : false}
          pattern='\(\d{2,}\) \d{5,}-\d{4}$'
          onKeyUp={() => validatePhone(value!)}
          onBlur={(event) => {
            validatePhoneBlur(event.target.value)
          }}
          onFocus={() => {
            setIsInputBlock(false)
          }}
          onChange={(event) => {
            changeValue!(formatCelPhone(event.target.value))
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputCountry = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  placeholder,
  changeValue,
  readOnly,
  isValid,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [countries, setCountries] = useState<CountryModel[]>([])

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const getCountries = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosWebsite.get(`/paises`, config)
      // console.log(res.data);
      setCountries(res.data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      {readOnly !== true ? (
        <Select
          id={id}
          name={name}
          isBlock={isInputBlock}
          value={value}
          title={value}
          placeholder={placeholder}
          required={required}
          isReadOnly={readOnly}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value)
          }}
        >
          <>
            <option value='' selected disabled>
              {' '}
            </option>
            {countries.map((country) => {
              return (
                <option value={country.CodPaisIso3166}>
                  {country.Nome
                    ? capitalizeFirstLetter(String(country.Nome))
                    : country.Nome}
                </option>
              )
            })}
          </>
        </Select>
      ) : (
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={value}
          value={value}
          placeholder={placeholder}
          minLength={1}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => changeValue!(event.target.value)}
          onKeyUp={() => validateField(value!)}
          onBlur={(event) => validateField(event.target.value)}
        />
      )}
    </FormBase>
  )
}

const InputRG = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  required,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validateRG = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/([0-9])\w+/i.test(value)) {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const validateRGBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/([0-9])\w+/i.test(value)) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o documento corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={12}
        value={value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='([0-9])\w+'
        onKeyUp={() => validateRG(value!)}
        onBlur={(event) => {
          validateRGBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputCPF = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  required,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const toast = useToast()

  const validateCPF = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/i.test(formatCPF(value))) {
        setIsInputBlock(true)
      } else {
        let valid = cpf.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  const validateCPFBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/i.test(formatCPF(value))) {
        setIsInputBlock(true)
        toast({
          title: 'Documento Inválido!',
          position: 'bottom',
          description: 'Por favor, verifique os dados e tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        // dispatch(
        //   setGlobalMessage({
        //     message: "Preencha o documento corretamente!",
        //     type: "WARN",
        //   })
        // );
        //changeValue!("");
      } else {
        let valid = cpf.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
          toast({
            title: 'Documento Inválido!',
            position: 'bottom',
            description: 'Por favor, verifique os dados e tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
          // dispatch(
          //   setGlobalMessage({
          //     message: "Preencha o documento corretamente!",
          //     type: "WARN",
          //   })
          // );
          //changeValue!("");
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={14}
        value={value ? formatCPF(value) : value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='^\d{3}\.\d{3}\.\d{3}-\d{2}$'
        onKeyUp={() => validateCPF(value!)}
        onBlur={(event) => {
          validateCPFBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputCNPJ = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const toast = useToast()

  const validateCNPJ = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/i.test(formatCNPJ(value))
      ) {
        setIsInputBlock(true)
      } else {
        let valid = cnpj.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  const validateCNPJBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/i.test(formatCNPJ(value))
      ) {
        setIsInputBlock(true)
        toast({
          title: 'Documento Inválido!',
          position: 'bottom',
          description: 'Por favor, verifique os dados e tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        // dispatch(
        //   setGlobalMessage({
        //     message: "Preencha o documento corretamente!",
        //     type: "WARN",
        //   })
        // );
        //changeValue!("");
      } else {
        let valid = cnpj.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
          toast({
            title: 'Documento Inválido!',
            position: 'bottom',
            description: 'Por favor, verifique os dados e tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
          // dispatch(
          //   setGlobalMessage({
          //     message: "Preencha o documento corretamente!",
          //     type: "WARN",
          //   })
          // );
          //changeValue!("");
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={18}
        value={value ? formatCNPJ(value) : value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$'
        onKeyUp={() => validateCNPJ(value!)}
        onBlur={(event) => {
          validateCNPJBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputCNPJCPF = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  required,
  changeValue,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const validateCNPJ = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/i.test(formatCNPJ(value))
      ) {
        setIsInputBlock(true)
      } else {
        let valid = cnpj.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  const validateCNPJBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/i.test(formatCNPJ(value))
      ) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o documento corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        let valid = cnpj.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
          dispatch(
            setGlobalMessage({
              message: 'Preencha o documento corretamente!',
              type: 'WARN'
            })
          )
          changeValue!('')
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  const validateCPF = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/i.test(formatCPF(value))) {
        setIsInputBlock(true)
      } else {
        let valid = cpf.isValid(value)
        if (valid === false) {
          setIsInputBlock(true)
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  const validateCPFBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/i.test(formatCPF(value))) {
        // console.log("caiu");
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o documento corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      } else {
        let valid = cpf.isValid(value)
        // console.log(valid);
        if (valid === false) {
          setIsInputBlock(true)
          dispatch(
            setGlobalMessage({
              message: 'Preencha o documento corretamente!',
              type: 'WARN'
            })
          )
          changeValue!('')
        } else {
          setIsInputBlock(false)
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={18}
        value={
          String(value).length > 11
            ? formatCNPJ(String(value))
            : formatCPF(String(value))
        }
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        onKeyUp={() => {
          if (value !== undefined && value !== null) {
            if (value.replace(/\D/g, '').length > 11) {
              validateCNPJ(value!)
            } else {
              validateCPF(value!)
            }
          }
        }}
        onBlur={(event) => {
          if (event.target.value.replace(/\D/g, '').length > 11) {
            validateCNPJBlur(event.target.value)
          } else {
            validateCPFBlur(event.target.value)
          }
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value.replace(/\D/g, ''))
        }}
      />
    </FormBase>
  )
}

const InputCEP = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  required,
  placeholder,
  changeValue,
  changeOtherValue,
  isRow,
  maxWidth
}: PropsCEP): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const toast = useToast()

  const dispatch = useDispatch()

  const validateCep = async (value?: string) => {
    if (value === undefined) {
      setIsInputBlock(true)
    } else if (value === '') {
      setIsInputBlock(true)
    } else if (!/^\d{5}-\d{3}$/i.test(value)) {
      setIsInputBlock(true)
    } else {
      setIsInputBlock(false)
    }
  }

  const validateCepBlur = async (value?: string) => {
    if (value === undefined) {
      setIsInputBlock(true)
      toast({
        title: 'CEP Inválido!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      // dispatch(
      //   setGlobalMessage({
      //     message: "Preencha o cep corretamente!",
      //     type: "WARN",
      //   })
      // );
      // changeValue!("");
    } else if (value === '') {
      setIsInputBlock(true)
      toast({
        title: 'CEP Inválido!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      // dispatch(
      //   setGlobalMessage({
      //     message: "Preencha o cep corretamente!",
      //     type: "WARN",
      //   })
      // );
      // changeValue!("");
    } else if (!/^\d{5}-\d{3}$/i.test(value)) {
      setIsInputBlock(true)
      toast({
        title: 'CEP Inválido!',
        position: 'bottom',
        description: 'Por favor, verifique os dados e tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      // dispatch(
      //   setGlobalMessage({
      //     message: "Preencha o cep corretamente!",
      //     type: "WARN",
      //   })
      // );
      //changeValue!("");
    } else {
      try {
        const cep = value.replace('-', '')

        const { data } = await axiosCep.get(`${cep}/json/`)

        setIsInputBlock(false)
        if (changeOtherValue) {
          changeOtherValue!(data)
        }
        if (data.erro === true) {
          setIsInputBlock(true)
          dispatch(
            setGlobalMessage({
              message: `Preencha o cep corretamente!`,
              type: 'WARN'
            })
          )
          //changeValue!("");
        }
      } catch (error: any) {
        dispatch(
          setGlobalMessage({
            message: `"Tente novamente, Error: " ${error}`,
            type: 'ERROR'
          })
        )
        //changeValue!("");
        setIsInputBlock(true)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={9}
        value={value ? formatCEP(value) : value}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        pattern='^\d{5}-\d{3}$'
        onKeyUp={() => validateCep(value!)}
        onBlur={(event) => {
          validateCepBlur(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
      {/* <Lupa onClick={() => validateCep()} /> */}
    </FormBase>
  )
}
/* 
const InputNumberLocal = ({
          id,
        name,
    label,
    value,
    readOnly,
      isValid,
    street,
    city,
    UF,
    changeValue,

    changeLat,
    changeLon,
    isRow,
}: PropsCEP): JSX.Element => {
    const [isInputBlock, setIsInputBlock] = useState<boolean>(false);

    const validateField = (value: string) => {
        if (value === "") {
            setIsInputBlock(true);
        } else {
            axiosGeo
                .get(
                    `street=${value}+${street}&city=${city}&state=${UF}&format=json`
                )
                .then((response: AxiosResponse) => {
                    changeLat!(response.data[0].lat);
                    changeLon!(response.data[0].lon);
                })
                .catch((error: AxiosError) => {
                    alert("Tente novamente, Error: " + error);
                });
        }
    };

    return (
        <FormBase
            label={label}
            isRow={isRow}
            isBlock={isInputBlock}
            maxWidth={"100px"}
        >
            <Input
                    id={id}
        name={name}
                isBlock={isInputBlock}
                type="number"
                value={value}
                title={value}
                required={required}
                        onKeyPress={event => event.key === "Enter" && event.preventDefault()}
                readOnly={readOnly}
                isReadOnly={readOnly}
                onBlur={event => {
                    validateField(event.target.value);
                }}
                onFocus={() => setIsInputBlock(false)}
                onChange={event => {
                    changeValue!(event.target.value);
                }}
            />
        </FormBase>
    );
};
 */
const InputUFSelect = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  isRow,
  required
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (value === '') {
      setIsInputBlock(true)
    } else {
      setIsInputBlock(false)
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={'100px'}
    >
      {readOnly !== true ? (
        <Select
          id={id}
          name={name}
          isBlock={isInputBlock}
          value={value}
          title={value}
          placeholder={placeholder}
          required={required}
          isReadOnly={readOnly}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value)
          }}
        >
          <>
            <option value='' selected disabled>
              UF
            </option>
            <option value='AC'>AC</option>
            <option value='AL'>AL</option>
            <option value='AP'>AP</option>
            <option value='AM'>AM</option>
            <option value='BA'>BA</option>
            <option value='CE'>CE</option>
            <option value='DF'>DF</option>
            <option value='ES'>ES</option>
            <option value='GO'>GO</option>
            <option value='MA'>MA</option>
            <option value='MT'>MT</option>
            <option value='MS'>MS</option>
            <option value='MG'>MG</option>
            <option value='PA'>PA</option>
            <option value='PB'>PB</option>
            <option value='PR'>PR</option>
            <option value='PE'>PE</option>
            <option value='PI'>PI</option>
            <option value='RJ'>RJ</option>
            <option value='RN'>RN</option>
            <option value='RS'>RS</option>
            <option value='RO'>RO</option>
            <option value='RR'>RR</option>
            <option value='SC'>SC</option>
            <option value='SP'>SP</option>
            <option value='SE'>SE</option>
            <option value='TO'>TO</option>
          </>
        </Select>
      ) : (
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={value}
          value={value}
          placeholder={placeholder}
          minLength={1}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => changeValue!(event.target.value)}
          onKeyUp={() => validateField(value!)}
          onBlur={(event) => validateField(event.target.value)}
        />
      )}
    </FormBase>
  )
}

const InputUF = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  required,
  placeholder,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (value === '') {
      setIsInputBlock(true)
    } else {
      setIsInputBlock(false)
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={'80px'}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        placeholder={placeholder}
        type='text'
        value={value}
        title={value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onBlur={(event) => {
          validateField(event.target.value)
        }}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputSelectBank = ({
  id,
  name,
  label,
  value,
  maxWidth,
  readOnly,
  required,
  isValid,
  placeholder,
  changeValue,
  valueName,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      {readOnly !== true ? (
        <Select
          id={id}
          name={name}
          isBlock={isInputBlock}
          value={value}
          title={value}
          placeholder={placeholder}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          onBlur={(event) => {
            validateField(event.target.value)
            valueName!(
              event.currentTarget.options[
                event.target.selectedIndex
              ].text.slice(6)
            )
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value)
          }}
        >
          <option value='' selected disabled>
            Selecione seu Banco
          </option>
          <option value='001'>001 - Banco do Brasil</option>
          <option value='003'>003 - Banco da Amazônia</option>
          <option value='004'>004 - Banco do Nordeste</option>
          <option value='021'>021 - Banestes</option>
          <option value='025'>025 - Banco Alfa</option>
          <option value='027'>027 - Besc</option>
          <option value='029'>029 - Banerj</option>
          <option value='031'>031 - Banco Beg</option>
          <option value='033'>033 - Banco Santander Banespa</option>
          <option value='036'>036 - Banco Bem</option>
          <option value='037'>037 - Banpará</option>
          <option value='038'>038 - Banestado</option>
          <option value='039'>039 - BEP</option>
          <option value='040'>040 - Banco Cargill</option>
          <option value='041'>041 - Banrisul</option>
          <option value='044'>044 - BVA</option>
          <option value='045'>045 - Banco Opportunity</option>
          <option value='047'>047 - Banese</option>
          <option value='062'>062 - Hipercard</option>
          <option value='063'>063 - Ibibank</option>
          <option value='065'>065 - Lemon Bank</option>
          <option value='066'>066 - Banco Morgan Stanley Dean Witter</option>
          <option value='069'>069 - BPN Brasil</option>
          <option value='070'>070 - Banco de Brasília – BRB</option>
          <option value='072'>072 - Banco Rural</option>
          <option value='073'>073 - Banco Popular</option>
          <option value='074'>074 - Banco J. Safra</option>
          <option value='075'>075 - Banco CR2</option>
          <option value='076'>076 - Banco KDB</option>
          <option value='096'>096 - Banco BMF</option>
          <option value='104'>104 - Caixa Econômica Federal</option>
          <option value='107'>107 - Banco BBM</option>
          <option value='116'>116 - Banco Único</option>
          <option value='151'>151 - Nossa Caixa</option>
          <option value='175'>175 - Banco Finasa</option>
          <option value='184'>184 - Banco Itaú BBA</option>
          <option value='204'>204 - American Express Bank</option>
          <option value='208'>208 - Banco Pactual</option>
          <option value='212'>212 - Banco Matone</option>
          <option value='213'>213 - Banco Arbi</option>
          <option value='214'>214 - Banco Dibens</option>
          <option value='217'>217 - Banco Joh Deere</option>
          <option value='218'>218 - Banco Bonsucesso</option>
          <option value='222'>222 - Banco Calyon Brasil</option>
          <option value='224'>224 - Banco Fibra</option>
          <option value='225'>225 - Banco Brascan</option>
          <option value='229'>229 - Banco Cruzeiro</option>
          <option value='230'>230 - Unicard</option>
          <option value='233'>233 - Banco GE Capital</option>
          <option value='237'>237 - Bradesco</option>
          <option value='241'>241 - Banco Clássico</option>
          <option value='243'>243 - Banco Stock Máxima</option>
          <option value='246'>246 - Banco ABC Brasil</option>
          <option value='248'>248 - Banco Boavista Interatlântico</option>
          <option value='249'>249 - Investcred Unibanco</option>
          <option value='250'>250 - Banco Schahin</option>
          <option value='252'>252 - Fininvest</option>
          <option value='254'>254 - Paraná Banco</option>
          <option value='263'>263 - Banco Cacique</option>
          <option value='265'>265 - Banco Fator</option>
          <option value='266'>266 - Banco Cédula</option>
          <option value='300'>300 - Banco de la Nación Argentina</option>
          <option value='318'>318 - Banco BMG</option>
          <option value='320'>320 - Banco Industrial e Comercial</option>
          <option value='356'>356 - ABN Amro Real</option>
          <option value='341'>341 - Itau</option>
          <option value='347'>347 - Sudameris</option>
          <option value='351'>351 - Banco Santander</option>
          <option value='353'>353 - Banco Santander Brasil</option>
          <option value='366'>366 - Banco Societe Generale Brasil</option>
          <option value='370'>370 - Banco WestLB</option>
          <option value='376'>376 - JP Morgan</option>
          <option value='389'>389 - Banco Mercantil do Brasil</option>
          <option value='394'>394 - Banco Mercantil de Crédito</option>
          <option value='399'>399 - HSBC</option>
          <option value='409'>409 - Unibanco</option>
          <option value='412'>412 - Banco Capital</option>
          <option value='422'>422 - Banco Safra</option>
          <option value='453'>453 - Banco Rural</option>
          <option value='456'>456 - Banco Tokyo Mitsubishi UFJ</option>
          <option value='464'>464 - Banco Sumitomo Mitsui Brasileiro</option>
          <option value='477'>477 - Citibank</option>
          <option value='479'>479 - Itaubank (antigo Bank Boston)</option>
          <option value='487'>487 - Deutsche Bank</option>
          <option value='488'>488 - Banco Morgan Guaranty</option>
          <option value='492'>492 - Banco NMB Postbank</option>
          <option value='494'>
            494 - Banco la República Oriental del Uruguay
          </option>
          <option value='495'>495 - Banco La Provincia de Buenos Aires</option>
          <option value='505'>505 - Banco Credit Suisse</option>
          <option value='600'>600 - Banco Luso Brasileiro</option>
          <option value='604'>604 - Banco Industrial</option>
          <option value='610'>610 - Banco VR</option>
          <option value='611'>611 - Banco Paulista</option>
          <option value='612'>612 - Banco Guanabara</option>
          <option value='613'>613 - Banco Pecunia</option>
          <option value='623'>623 - Banco Panamericano</option>
          <option value='626'>626 - Banco Ficsa</option>
          <option value='630'>630 - Banco Intercap</option>
          <option value='633'>633 - Banco Rendimento</option>
          <option value='634'>634 - Banco Triângulo</option>
          <option value='637'>637 - Banco Sofisa</option>
          <option value='638'>638 - Banco Prosper</option>
          <option value='643'>643 - Banco Pine</option>
          <option value='652'>652 - Itaú Holding Financeira</option>
          <option value='653'>653 - Banco Indusval</option>
          <option value='654'>654 - Banco A.J. Renner</option>
          <option value='655'>655 - Banco Votorantim</option>
          <option value='707'>707 - Banco Daycoval</option>
          <option value='719'>719 - Banif</option>
          <option value='721'>721 - Banco Credibel</option>
          <option value='734'>734 - Banco Gerdau</option>
          <option value='735'>735 - Banco Pottencial</option>
          <option value='738'>738 - Banco Morada</option>
          <option value='739'>739 - Banco Galvão de Negócios</option>
          <option value='740'>740 - Banco Barclays</option>
          <option value='741'>741 - BRP</option>
          <option value='743'>743 - Banco Semear</option>
          <option value='745'>745 - Banco Citibank</option>
          <option value='746'>746 - Banco Modal</option>
          <option value='747'>747 - Banco Rabobank International</option>
          <option value='748'>748 - Banco Cooperativo Sicredi</option>
          <option value='749'>749 - Banco Simples</option>
          <option value='751'>751 - Dresdner Bank</option>
          <option value='752'>752 - BNP Paribas</option>
          <option value='753'>753 - Banco Comercial Uruguai</option>
          <option value='755'>755 - Banco Merrill Lynch</option>
          <option value='756'>756 - Banco Cooperativo do Brasil</option>
          <option value='757'>757 - KEB</option>
        </Select>
      ) : (
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={value}
          value={value}
          placeholder={placeholder}
          minLength={1}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => changeValue!(event.target.value)}
          onKeyUp={() => validateField(value!)}
          onBlur={(event) => validateField(event.target.value)}
        />
      )}
    </FormBase>
  )
}

const InputCurrency = ({
  id,
  name,
  label,
  value,
  maxWidth,
  maxValue,
  maxLength,
  required,
  placeholder,
  readOnly,
  isValid,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const [falseValue, setFalseValue] = useState<string>('')

  const calculationTotalValue = (value: string, maxValue: string) => {
    let regex =
      /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)(\.[0-9]{1,2})?$/
    let currentPrice = 0
    let totalPrice = 0

    if (regex.test(String(value))) {
      // console.log("TIPO: Numerico? " + value);
      currentPrice += Number(value)
    } else {
      // console.log("TIPO: String? " + value);
      currentPrice += Number(valueReconvert(String(value), 'ANY'))
    }
    if (regex.test(String(maxValue))) {
      // console.log("TIPO: Numerico? " + maxValue);
      totalPrice += Number(maxValue)
    } else {
      // console.log("TIPO: String? " + maxValue);
      totalPrice += Number(valueReconvert(String(maxValue), 'ANY'))
    }
    if (Number(currentPrice) > Number(totalPrice)) {
      setFalseValue!(valueConvert(Number(totalPrice), 'ANY'))
      changeValue!(String(totalPrice))
    } else {
      setFalseValue!(valueConvert(Number(currentPrice), 'ANY'))
      changeValue!(String(currentPrice))
    }
  }

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
        if (!readOnly) {
          if (maxValue) {
            calculationTotalValue(value, maxValue)
          } else {
            setFalseValue!(valueConvert(Number(value), 'ANY'))
          }
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  useEffect(() => {
    if (value) {
      if (!readOnly) {
        setFalseValue(value)
      } else {
        setFalseValue!(valueConvert(Number(value), 'ANY'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      <ItemRow>
        <p>$</p>
        <Input
          id={id}
          name={name}
          type='text'
          isBlock={isInputBlock}
          value={falseValue}
          title={falseValue}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={(event) => {
            setIsInputBlock(false)
            if (!readOnly) {
              setFalseValue!(valueReconvert(String(falseValue), 'ANY'))
            }
          }}
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onChange={(event) => {
            changeValue!(event.target.value.replace(/[^0-9.]/g, ''))
            setFalseValue!(event.target.value.replace(/[^0-9.]/g, ''))
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputAgency = ({
  id,
  name,
  label,
  value,
  maxWidth,
  maxLength,
  required,
  placeholder,
  readOnly,
  isValid,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const [isMask, setIsMask] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      <ItemRow>
        <Input
          id={id}
          name={name}
          type='text'
          isBlock={isInputBlock}
          value={
            // isMask ? formatAgency(String(value).replace(/\D/g, '')) :
            String(value).replace(/\D/g, '')
          }
          title={formatAgency(String(value).replace(/\D/g, ''))}
          placeholder={placeholder}
          maxLength={4}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onBlur={(event) => {
            validateField(event.target.value)
            if (!readOnly) {
              setIsMask(true)
            }
          }}
          onFocus={() => {
            if (!readOnly) {
              setIsMask(false)
            }
            setIsInputBlock(false)
          }}
          onChange={(event) => {
            changeValue!(event.target.value.replace(/\D/g, ''))
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputAccount = ({
  id,
  name,
  label,
  value,
  maxWidth,
  maxLength,
  required,
  placeholder,
  readOnly,
  isValid,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const [isMask, setIsMask] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      <ItemRow>
        <Input
          id={id}
          name={name}
          type='text'
          isBlock={isInputBlock}
          value={
            //isMask ? formatAccountBank(String(value).replace(/\D/g, '')):
            String(value).replace(/\D/g, '')
          }
          title={formatAccountBank(String(value).replace(/\D/g, ''))}
          placeholder={placeholder}
          maxLength={11}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onBlur={(event) => {
            validateField(event.target.value)
            if (!readOnly) {
              setIsMask(true)
            }
          }}
          onFocus={() => {
            if (!readOnly) {
              setIsMask(false)
            }
            setIsInputBlock(false)
          }}
          onChange={(event) => {
            changeValue!(event.target.value.replace(/\D/g, ''))
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputPercentage = ({
  id,
  name,
  label,
  value,
  maxWidth,
  maxLength,
  maxPercent,
  required,
  placeholder,
  readOnly,
  isValid,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (value && changeValue) {
      if (Number(value) > Number(maxPercent)) {
        changeValue(String(maxPercent))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      <ItemRow>
        <Input
          id={id}
          name={name}
          type='text'
          isBlock={isInputBlock}
          value={value ? formatPercentage(value) : value}
          title={value}
          placeholder={placeholder}
          maxLength={3}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value.replace(/\D/g, ''))
          }}
        />

        <p>%</p>
      </ItemRow>
    </FormBase>
  )
}

const InputBSB = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  required,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const formatBSB = (bsb: string) => {
    bsb = bsb.replace(/[^0-9a-zA-Z]+/g, '')
    bsb = bsb.replace(/([0-9a-zA-Z]{3})([0-9a-zA-Z]{1,3})$/, '$1-$2')
    return String(bsb)
  }

  const validateBSB = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^[0-9a-zA-Z]{3}\\-[0-9a-zA-Z]{3}$/i.test(formatBSB(value))) {
        setIsInputBlock(true)
      }
    }
  }

  const validateBSBBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (!/^[0-9a-zA-Z]{3}\\-[0-9a-zA-Z]{3}$/i.test(formatBSB(value))) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o documento corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={7}
        value={value ? formatBSB(value) : value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='^[0-9a-zA-Z]{3}\\-[0-9a-zA-Z]{3}$'
        onKeyUp={() => validateBSB(value!)}
        onBlur={(event) => {
          validateBSBBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputSORTCODE = ({
  id,
  name,
  label,
  value,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  required,
  isRow,
  maxWidth
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const dispatch = useDispatch()

  const formatSORT = (sort: string) => {
    sort = sort.replace(/[^0-9a-zA-Z]+/g, '')
    sort = sort.replace(/([0-9a-zA-Z]{2})([0-9a-zA-Z])/, '$1-$2')
    sort = sort.replace(/([0-9a-zA-Z]{2})([0-9a-zA-Z]{1,2})$/, '$1-$2')
    return String(sort)
  }

  const validateSORT = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}$/i.test(
          formatSORT(value)
        )
      ) {
        setIsInputBlock(true)
      }
    }
  }

  const validateSORTBlur = (value: string) => {
    if (required !== undefined) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (
        !/^[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}$/i.test(
          formatSORT(value)
        )
      ) {
        setIsInputBlock(true)
        dispatch(
          setGlobalMessage({
            message: 'Preencha o documento corretamente!',
            type: 'WARN'
          })
        )
        changeValue!('')
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      leafPormade={required ? true : false}
      maxWidth={maxWidth}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        type='text'
        placeholder={placeholder}
        title={value}
        maxLength={8}
        value={value ? formatSORT(value) : value}
        readOnly={readOnly}
        isReadOnly={readOnly}
        required={required ? true : false}
        pattern='^[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}\\-[0-9a-zA-Z]{2}$'
        onKeyUp={() => validateSORT(value!)}
        onBlur={(event) => {
          validateSORTBlur(event.target.value)
        }}
        onFocus={() => {
          setIsInputBlock(false)
        }}
        onChange={(event) => {
          changeValue!(event.target.value)
        }}
      />
    </FormBase>
  )
}

const InputImgBase64 = ({
  id,
  name,
  changeValue,
  valueName,
  avatar,
  label,
  display,
  readOnly,
  isValid,
  isRow
}: Props): JSX.Element => {
  const [imgBase64String, setImgBase64String] = useState<
    String | ArrayBuffer | null
  >('')

  const encodeImgBase64 = (file: File | null) => {
    let reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      reader.onload = () => {
        let Base64 = reader.result
        setImgBase64String(
          (Base64 as string)
            .replace('data:image/jpeg;base64,', '')
            .replace('data:image/png;base64,', '')
        )
        changeValue!(
          (Base64 as string)
            .replace('data:image/jpeg;base64,', '')
            .replace('data:image/png;base64,', '')
        )
        // console.log(Base64 as string);
      }
      reader.onerror = (error) => {
        console.log('Teste de erro: ', error, imgBase64String)
      }
    }
  }

  const checkImage = (file: File | null) => {
    if (file) {
      let reg = /image\/(png|jpeg)/
      if (reg.test(file.type)) {
        encodeImgBase64(file)
        if (valueName) {
          valueName(file.name)
        }
      } else {
        setImgBase64String('')
        changeValue!('')
        if (valueName) {
          valueName('')
        }
      }
    } else {
      setImgBase64String('')
      changeValue!('')
      if (valueName) {
        valueName('')
      }
    }
  }

  return (
    <FormBase label={label} isRow={isRow} display={display}>
      <ImgDiv>
        <RelativeDiv>
          <InsertImg
            readOnly={Boolean(readOnly)}
            onClick={() =>
              !readOnly
                ? document.getElementById(id ? id : 'selectedFileImg')!.click()
                : null
            }
          >
            {readOnly === true ? null : (
              <InputImg
                type='file'
                id={id ? id : 'selectedFileImg'}
                name={name}
                accept='image/png, image/jpeg'
                onChange={(event) => {
                  checkImage(event.target.files![0])
                }}
              />
            )}
            <BaseImg
              id='avatar'
              src={
                avatar === '' || avatar === null || avatar === undefined
                  ? NoImg
                  : 'data:image/jpeg;base64,' + avatar
              }
            />
          </InsertImg>
          {/* {readOnly === true ? null : (
            <AddPhoto
              onClick={() => document.getElementById("selectedFileImg")!.click()}
            />
          )} */}
        </RelativeDiv>
      </ImgDiv>
    </FormBase>
  )
}

const InputDaysSettlement = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  isRow,
  defaultSelectValue
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const [read, setRead] = useState<boolean>(false)
  const [isBlur, setIsBlur] = useState<boolean>(true)
  const [valueInputOne, setValueInputOne] = useState<string>('0')
  const [valueInputTwo, setValueInputTwo] = useState<string>('')

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  useEffect(() => {
    if (changeValue) {
      if (valueInputOne === 'Any') {
        setRead(false)
        changeValue(valueInputTwo)
      } else {
        setRead(true)
        changeValue(valueInputOne)
        setValueInputTwo('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueInputOne, valueInputTwo])

  useEffect(() => {
    // if (changeValue) {
    //   if (value === "" && valueInputTwo !== "") {
    //     setValueInputOne("");
    //     setValueInputTwo("");
    //   }
    // }
    // console.log(value);
    if (isBlur === true) {
      if (Number(value) === 1) {
        setValueInputOne(String(value))
        setValueInputTwo('')
      } else if (Number(value) === 2) {
        setValueInputOne(String(value))
        setValueInputTwo('')
      } else if (Number(value) > 2) {
        setValueInputOne('Any')
        setValueInputTwo(String(value).replace(/\D/g, ''))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlur, value])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <ItemRow>
        {readOnly !== true ? (
          <Select
            id={id}
            name={name}
            isBlock={isInputBlock}
            value={valueInputOne}
            title={valueInputOne}
            placeholder={placeholder}
            required={required}
            isReadOnly={readOnly}
            onKeyPress={(event) =>
              event.key === 'Enter' && event.preventDefault()
            }
            maxWidth='80px'
            onBlur={(event) => validateField(event.target.value)}
            onFocus={() => {
              setIsBlur(false)
              setIsInputBlock(false)
            }}
            onChange={(event) => {
              setValueInputOne!(event.target.value)
            }}
          >
            <option value='' disabled selected>
              {' '}
            </option>
            <option value='0'>D0</option>
            <option value='1'>D1</option>
            <option value='2'>D2</option>
            <option value='Any'>D+</option>
          </Select>
        ) : (
          <Input
            id={id}
            name={name}
            isBlock={isInputBlock}
            title={valueInputOne}
            value={valueInputOne}
            placeholder={placeholder}
            minLength={1}
            maxWidth='80px'
            required={required}
            onKeyPress={(event) =>
              event.key === 'Enter' && event.preventDefault()
            }
            readOnly={readOnly}
            isReadOnly={readOnly}
            onFocus={() => setIsInputBlock(false)}
            onChange={(event) => changeValue!(event.target.value)}
            onKeyUp={() => validateField(value!)}
            onBlur={(event) => validateField(event.target.value)}
          />
        )}
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={valueInputTwo}
          value={valueInputTwo.replace(/\D/g, '')}
          placeholder={placeholder}
          minLength={1}
          maxLength={2}
          required={valueInputOne === 'Any' ? required : false}
          readOnly={read || readOnly}
          isReadOnly={readOnly}
          onFocus={() => {
            setIsBlur(false)
            setIsInputBlock(false)
          }}
          onChange={(event) =>
            setValueInputTwo!(event.target.value.replace(/\D/g, ''))
          }
          onKeyUp={() => validateField(valueInputTwo.replace(/\D/g, ''))}
          onBlur={(event) => {
            if (valueInputOne === 'Any') {
              validateField(event.target.value.replace(/\D/g, ''))
            }
            setIsBlur(true)
          }}
        />
      </ItemRow>
    </FormBase>
  )
}

const InputNumberDocument = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  validBalance,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
        if (validBalance && value.length >= 3) {
          validBalance()
        }
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={value}
        value={value}
        placeholder={placeholder}
        minLength={1}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onFocus={() => setIsInputBlock(false)}
        onChange={(event) => changeValue!(event.target.value)}
        onBlur={(event) => validateField(event.target.value)}
        // onKeyUp={() => validateField(value!)}
      />
    </FormBase>
  )
}

const InputLogin = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  maxLength,
  readOnly,
  isValid,
  placeholder,
  changeValue,
  isRow
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const [isInputEnable, setIsInputEnable] = useState<boolean>(false)
  const [isInputLoading, setIsInputLoading] = useState<boolean>(false)

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else if (value.length < 6) {
        setIsInputBlock(true)
      } else {
        checkClienteSys()
        setIsInputBlock(false)
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  const checkClienteSys = async () => {
    try {
      setIsInputEnable(false)
      setIsInputBlock(false)
      setIsInputLoading(true)
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(`/check/login/${value}`, config)
      if (data) {
        setIsInputEnable(true)
        setIsInputBlock(false)
        setIsInputLoading(false)
      }
    } catch (error: any) {
      setIsInputBlock(true)
      setIsInputEnable(false)
      setIsInputLoading(false)
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `${error.response!.data.error.message}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      console.log('Teste')
    }
  }

  return (
    <FormBase
      label={label}
      isRow={isRow}
      isBlock={isInputBlock}
      maxWidth={maxWidth}
      leafPormade={required}
    >
      <Input
        id={id}
        name={name}
        isBlock={isInputBlock}
        title={value}
        value={value ? value.replace(/[^A-Za-z0-9]/g, '') : value}
        placeholder={placeholder}
        minLength={6}
        maxLength={maxLength}
        required={required}
        onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
        readOnly={readOnly}
        isReadOnly={readOnly}
        onFocus={() => {
          setIsInputBlock(false)
          setIsInputEnable(false)
          setIsInputLoading(false)
        }}
        onChange={(event) =>
          changeValue!(event.target.value.replace(/[^A-Za-z0-9]/g, ''))
        }
        onBlur={(event) => validateField(event.target.value)}
      />
      {isInputLoading ? <EffectCircle /> : null}
      {isInputBlock ? <Cross /> : null}
      {isInputEnable ? <Check /> : null}
    </FormBase>
  )
}

const InputActivityMain = ({
  id,
  name,
  label,
  value,
  maxWidth,
  required,
  placeholder,
  changeValue,
  readOnly,
  isValid,
  isRow
}: Props): JSX.Element => {
  const [isInputBlock, setIsInputBlock] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [activities, setActivities] = useState<ActivityModel[]>([])

  const validateField = (value: string) => {
    if (required === true) {
      if (value === '') {
        setIsInputBlock(true)
      } else {
        setIsInputBlock(false)
      }
    }
  }

  const getActivities = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const res = await axiosWebsite.get(`/ramo`, config)
      // console.log(res.data);
      setActivities(res.data)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    }
  }

  useEffect(() => {
    if (required === true) {
      if (isValid) {
        setIsInputBlock(isValid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  useEffect(() => {
    getActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormBase
      label={label}
      isRow={isRow}
      maxWidth={maxWidth}
      isBlock={isInputBlock}
      leafPormade={required}
    >
      {readOnly !== true ? (
        <Select
          id={id}
          name={name}
          isBlock={isInputBlock}
          value={value}
          title={value}
          placeholder={placeholder}
          required={required}
          isReadOnly={readOnly}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          onBlur={(event) => {
            validateField(event.target.value)
          }}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => {
            changeValue!(event.target.value)
          }}
        >
          <>
            <option value='' selected disabled>
              {' '}
            </option>
            {activities.map((activity) => {
              return (
                <option value={activity.Descricao}>
                  {activity.Descricao
                    ? capitalizeFirstLetter(String(activity.Descricao))
                    : activity.Descricao}
                </option>
              )
            })}
          </>
        </Select>
      ) : (
        <Input
          id={id}
          name={name}
          isBlock={isInputBlock}
          title={value}
          value={value}
          placeholder={placeholder}
          minLength={1}
          required={required}
          onKeyPress={(event) =>
            event.key === 'Enter' && event.preventDefault()
          }
          readOnly={readOnly}
          isReadOnly={readOnly}
          onFocus={() => setIsInputBlock(false)}
          onChange={(event) => changeValue!(event.target.value)}
          onKeyUp={() => validateField(value!)}
          onBlur={(event) => validateField(event.target.value)}
        />
      )}
    </FormBase>
  )
}

export {
  InputText,
  InputCheckBox,
  InputNumber,
  InputNumberDI,
  InputSelect,
  InputTextArea,
  InputName,
  InputEmail,
  InputPassword,
  InputTextFilter,
  InputDate,
  InputDateNoWeekends,
  InputPhone,
  InputCelPhone,
  InputDDDPhone,
  InputDDIPhone,
  InputDDICelPhone,
  InputCountry,
  InputRG,
  InputCPF,
  InputCNPJ,
  InputCNPJCPF,
  InputCEP,
  InputUF,
  InputUFSelect,
  InputSelectBank,
  InputCurrency,
  InputAgency,
  InputAccount,
  InputPercentage,
  InputBSB,
  InputSORTCODE,
  InputImgBase64,
  InputDaysSettlement,
  InputActivityMain,
  InputLogin,
  InputNumberDocument
}
