export function formatRate(minimumFractionDigits?: number) {
    return {
        toNumber: (value: string) => {
            const floatValue = Number(value.replace(/[.,\s]/gm, "")) / (minimumFractionDigits === 2 ? 100 : 1000);
            return floatValue;
        },
        toString: (value: number) => {
            return value.toLocaleString('pt-br', {
                minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : 3
            })
            //return nextValue;
        },
    };
}
